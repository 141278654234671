<template lang="pug">
.inner
	.content(v-if="parseText") {{ parseText }}
	.content(v-if="parseHtml && !parseText", v-html="parseHtml")
	.button-group
		button.btn-cancel.inv(@click="cancelPopup") {{ parseLeftBtn }}
		button.btn-confirm.acc(@click="confirmPopup") {{ parseRightBtn }}
</template>

<script>
import popupMixin from "./popupMixin.js";
import { mapActions } from "vuex";

export default {
	mixins: [popupMixin],
	props: {
		popupData: Object,
		popupEvents: Object,
	},
	emits: ["close-popup"],
	methods: {
		...mapActions({
			fireConfirmPopupEvent: "modulePopup/fireConfirmPopupEvent",
		}),
		cancelPopup() {
			this.fireConfirmPopupEvent(false);
			this.$emit("close-popup");
		},
		confirmPopup() {
			this.fireConfirmPopupEvent(true);
			this.$emit("close-popup");
		},
	},
};
</script>
