const mixins = {
	computed: {
		parseText() {
			return this.popupData.text;
		},
		parseHtml() {
			if (this.popupData.vhtml) {
				return this.popupData.vhtml;
			}
			return "";
		},
		parseLeftBtn() {
			if (this.popupData.leftBtn) {
				return this.popupData.leftBtn;
			}
			return this.$t("button.buttonCancel");
		},
		parseRightBtn() {
			if (this.popupData.rightBtn) {
				return this.popupData.rightBtn;
			}
			return this.$t("button.buttonConfirm");
		},
		parseOkBtn() {
			if (this.popupData.okBtn) {
				return this.popupData.okBtn;
			}
			return this.$t("button.ok");
		},
		errorMsg() {
			return this.popupData.errorMsg || "";
		},
	},
};

export default mixins;
