<template lang="pug">
#dirty-modal
	h4.title
		span(v-for="(change, index) in changes", :key="index")
			span.other-text Do you want to save the changes made to the
			span.file-name {{ change.file.name }}
			span.other-text file?
	.actions
		button.inv(@click="onCancelModal") {{ $t("button.cancel") }}
		button(@click="fileDontSave") {{ $t("button.dontSave") }}
		button(@click="fileSave") {{ $t("button.save") }}
</template>

<script>
import editorMixin from "@/modules/editor/editorMixin.js";
import { mapActions } from "vuex";
export default {
	mixins: [editorMixin],
	props: {
		options: {
			type: Object,
			required: false,
		},
	},
	computed: {
		changes() {
			return (
				this.options && Array.isArray(this.options.files) && this.options.files
			);
		},
	},
	methods: {
		...mapActions({
			removeTabFileID: "moduleEditor/removeTabFileID",
		}),
		onCancelModal() {
			this.closeModernModalCompat();
		},
		invokeFunctionAfterAction() {
			if (
				this.options.funcToInvokeOnClose &&
				typeof this.options.funcToInvokeOnClose === "function"
			) {
				this.options.funcToInvokeOnClose(this.options.funcParam);
				// setTimeout(() => {
				//   eventBus.$emit('file-dirty-closed')
				// }, 1000);
			}
		},
		fileDontSave() {
			for (const tab of this.changes) {
				this.removeTabFileID(tab.id);
			}
			this.invokeFunctionAfterAction();
			this.onCancelModal();
		},
		async fileSave() {
			for (const tab of this.changes) {
				await this.saveFileFromActivePath(tab.path);
				this.removeTabFileID(tab.id);
			}
			this.invokeFunctionAfterAction();
			this.onCancelModal();
		},
	},
};
</script>

<style lang="scss" scoped>
#dirty-modal {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding: 20px;

	.title {
		display: flex;
		flex-direction: column;

		.other-text {
			color: gray;
		}

		.file-name {
			margin: 0 5px;
			font-weight: bold;
		}
	}

	.actions {
		align-self: flex-end;

		button {
			margin: 7px;
		}
	}
}
</style>
