<script setup lang="ts">
import { passwordFieldPolyfill } from "@/assets/js/helpers";
import store from "@/store";

const props = defineProps<{
	options: {
		rowData: string;
		siteName: string;
	};
}>();

const generatedPassword = ref("");
const form = reactive({ userName: "", password: "", confirmPassword: "" });

const isEditingForm = computed(() => Boolean(props.options.rowData));
const siteName = computed(() => props.options.siteName);
const canSaveChanges = computed(() => {
	return isEditingForm.value
		? form.password && isPasswordValidConfirmed.value
		: form.userName && isPasswordValidConfirmed.value;
});

const isPasswordValidConfirmed = computed(() => {
	if (form.password) {
		return form.password === form.confirmPassword;
	}
	return true;
});

function closeModernModalCompat() {
	store.dispatch("closeModernModalCompat");
}

async function addUser() {
	const id = store.getters["moduleProject/getSiteData"].sit_id;
	const params: Record<string, string> = {
		id,
		dbuser: form.userName,
	};

	if (form.password) {
		params.dbpass = form.password;
	}

	try {
		const res = await store.dispatch("postDbUsers", params);
		generatedPassword.value = res.data.dbpass;

		const toastParams = {
			data: {
				type: "success",
				text: res.data.message,
			},
		};
		store.dispatch("moduleToast/openToastStorage", toastParams);
	} catch (err: any) {
		console.warn(err.message);
		const toastParams = {
			data: {
				type: "error",
				text: err?.data?.message || "Error adding user",
			},
		};
		store.dispatch("moduleToast/openToastStorage", toastParams);
	}
}

async function editUser() {
	const id = store.getters["moduleProject/getSiteData"].sit_id;
	const params = {
		id,
		dbuser: props.options.rowData,
		dbpass: form.password,
	};

	try {
		const res = await store.dispatch("putDbUsers", params);
		generatedPassword.value = res.data.dbpass;

		const toastParams = {
			data: {
				type: "success",
				text: res.data.message,
			},
		};
		store.dispatch("moduleToast/openToastStorage", toastParams);
		closeModernModalCompat();
	} catch (err: any) {
		console.warn(err.message);
		const toastParams = {
			data: {
				type: "error",
				text: err?.data?.message || "Error editing user",
			},
		};
		store.dispatch("moduleToast/openToastStorage", toastParams);
	}
}

function getName(row: string) {
	return `${row}@${siteName.value}`;
}

function onSaveChanges() {
	if (isEditingForm.value) {
		editUser();
	} else {
		addUser();
	}
}
</script>

<template lang="pug">
.modal-wrapper
	.create-form(v-if="!isEditingForm")
		input.input.rounded(
			v-model="form.userName",
			:disabled="Boolean(generatedPassword)",
			placeholder="Name",
			name="namee",
			autocomplete="off"
		)
		template(v-if="!generatedPassword")
			label.password-field
				span Set password
				input.input.rounded(
					v-bind="passwordFieldPolyfill()",
					v-model="form.password",
					:required="false",
					placeholder="Password"
				)
			label.password-field
				span Confirm password
				input.input.rounded(
					v-bind="passwordFieldPolyfill()",
					v-model="form.confirmPassword",
					:required="false",
					placeholder="Confirm password"
				)
	.edit-form(v-if="isEditingForm")
		div
			//- span Edit password for:&nbsp;
			strong {{ getName(options.rowData) }}
		label.password-field
			span Set password
			input.input.rounded(
				v-bind="passwordFieldPolyfill()",
				v-model="form.password",
				:required="false",
				placeholder="Password"
			)
		label.password-field
			span Confirm password
			input.input.rounded(
				v-bind="passwordFieldPolyfill()",
				v-model="form.confirmPassword",
				:required="false",
				placeholder="Confirm password"
			)
	.pass(v-if="generatedPassword && !isEditingForm")
		span Your password is:&nbsp;
		strong {{ generatedPassword }}
		div(style="margin-top: 15px") Once the dialog is closed, you will not be able to view the password again.
	.actions
		template(v-if="!generatedPassword")
			button.btn.cancel.trans(@click="closeModernModalCompat") Cancel
			button.btn.apply(:disabled="!canSaveChanges", @click="onSaveChanges")
				span Save
				//- span {{ isEditingForm ? "Edit" : "Save" }}
		button.btn.apply(v-else, @click="closeModernModalCompat") Close dialog
</template>

<style lang="scss" scoped>
@import "@/assets/css/adminModals";

.modal-wrapper {
	padding: 10px 30px 30px;
	display: flex;
	flex-direction: column;
	gap: 20px;

	.create-form,
	.edit-form {
		display: flex;
		flex-direction: column;
		gap: 10px;

		label {
			display: flex;
			flex-direction: column;
			gap: 5px;
		}

		// .password-field {
		// 	input {
		// 		-webkit-text-security: disc;
		// 	}
		// }
	}

	.add-to-all {
		display: flex;
		align-items: center;
		gap: 10px;
		color: $main-accent-color;
		cursor: pointer;
	}

	.pass {
		text-align: center;
	}

	.actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 20px;
		margin-top: 10px;

		button {
			height: 40px;
			min-width: 100px;
		}
	}
}
</style>
