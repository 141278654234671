<template lang="pug">
#terms-service
	span Terms and services...
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#terms-service {
	display: flex;
}
</style>
