import CommanderFactory from "./commander/CommanderFactory";
import DefaultParsersFactory from "./parsers/DefaultParsersFactory";
import SimpleSynchronizer from "./synchronizer/Synchronizer";

// ------- EXPORT ---------------
const DDLGenerator = {
	synchronize(oldDb, newDb) {
		console.log(oldDb, newDb);
		const commanderFactory = new CommanderFactory();
		const commander = commanderFactory.newPrioritiesCommander();

		const parsersFactory = new DefaultParsersFactory();
		const parser = parsersFactory.initialParser();

		let processableSchemas = parser.parse({
			oldSchema: oldDb,
			newSchema: newDb,
		});

		const data = {
			commander,
			oldSchema: processableSchemas.oldProcessableSchema,
			newSchema: processableSchemas.newProcessableSchema,
		};
		let synchronizer = new SimpleSynchronizer(data);
		return synchronizer.synchronize();
	},
};

export default DDLGenerator;
