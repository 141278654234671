class AbstractCommand {
	constructor(priority = "Command") {
		this.commentsBefore = [];
		this.commentsAfter = [];
		this.priority = priority;
	}

	addCommentBefore(comment) {
		this.commentsBefore.push(comment);
	}

	addCommentAfter(comment) {
		this.commentsAfter.push(comment);
	}

	execute() {
		return "-- This is default command";
	}

	createStatement() {
		let res = "";
		for (const comment of this.commentsBefore)
			res += "-- " + comment.replace("\n", " ") + "\n";
		let help = this.execute();
		if (typeof help === "object" && help !== null) {
			res = help;
		} else {
			res += help + ";\n";
		}
		// res += (help + ";\n")
		for (const comment of this.commentsAfter)
			res += "-- " + comment.replace("\n", " ") + "\n";
		return res;
	}
}

export default AbstractCommand;
