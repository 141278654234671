<template>
	<div ref="wrap" class="input-wrap recovery-multiselect">
		<span>Name</span>
		<div class="row">
			<input
				type="text"
				class="input name"
				placeholder="Name"
				:value="name"
				@input="handleNameInput"
			/>
		</div>
		<span>Date</span>
		<div class="row">
			<input
				ref="day"
				type="text"
				class="input"
				placeholder="Day"
				:value="selectedDate.day"
				@input="handleInput($event, 'day')"
			/>
			<div>
				<multiselect-form
					:options="months"
					:model-value="multiselectProps.value"
					:placeholder="multiselectProps.placeholder"
					:searchable="true"
					@select="handleMonthSelect"
				/>
			</div>
			<input
				type="text"
				class="input"
				placeholder="Year"
				:value="selectedDate.year"
				@input="handleInput($event, 'year')"
			/>
		</div>
		<span>Time</span>
		<div class="row">
			<input
				type="text"
				class="input"
				placeholder="Hour"
				:value="selectedDate.hour"
				@input="handleInput($event, 'hour')"
			/>
			<input
				type="text"
				class="input"
				placeholder="Minute"
				:value="selectedDate.minute"
				@input="handleInput($event, 'minute')"
			/>
			<input
				type="text"
				class="input"
				placeholder="Second"
				:value="selectedDate.second"
				@input="handleInput($event, 'second')"
			/>
		</div>
		<span>Description</span>
		<div class="row textarea">
			<textarea
				maxlength="255"
				:value="description"
				@input="handleDescription"
			></textarea>
			<span class="limiter">{{ 255 - description.length }}</span>
		</div>
		<div class="button-wrap">
			<div v-if="buttonActive" class="recovery-button" @click="createDatapoint">
				{{ editMode ? "Edit Datapoint" : "Create Datapoint" }}
			</div>
			<div
				v-else
				v-tooltip="
					'Please fill out name, date and time to ' +
					(editMode ? 'edit' : 'create') +
					' datapoint.'
				"
				class="recovery-button inactive"
			>
				{{ editMode ? "Edit Datapoint" : "Create Datapoint" }}
			</div>
		</div>
	</div>
</template>

<script>
import api from "@/api";
import { DateInstance } from "@/assets/js/dateHelperClass.ts";
const months = DateInstance.getDateLocaleData().monthsShort();
export default {
	data() {
		return {
			selectedDate: {
				day: "",
				month: "",
				year: "",
				hour: "",
				minute: "",
				second: "",
			},
			description: "",
			name: "",
			months,
			rcp_id: null,
		};
	},
	computed: {
		multiselectProps() {
			return {
				options: this.months,
				placeholder: "Month",
				value: this.selectedDate.month
					? this.months[this.selectedDate.month - 1]
					: "",
			};
		},
		editMode() {
			return this.rcp_id !== null;
		},
		buttonActive() {
			return (
				Object.values(this.selectedDate).filter((el) => !el).length === 0 &&
				this.name.length > 0
			);
		},
		datePart() {
			if (
				!this.selectedDate.year ||
				!this.selectedDate.month ||
				!this.selectedDate.day
			) {
				return "";
			}
			let day = this.selectedDate.day;
			if (day < 10 && (day + "").length === 1) {
				day = "0" + day;
			}
			let month = this.selectedDate.month;
			if (month < 10 && (month + "").length === 1) {
				month = "0" + month;
			}
			return `${this.selectedDate.year}-${month}-${day}`;
		},
		timePart() {
			if (
				!this.selectedDate.hour ||
				!this.selectedDate.minute ||
				!this.selectedDate.second
			) {
				return "";
			}
			let hour = this.selectedDate.hour;
			if (hour < 10 && hour.length === 1) {
				hour = "0" + hour;
			}
			let minute = this.selectedDate.minute;
			if (minute < 10 && minute.length === 1) {
				minute = "0" + minute;
			}
			let second = this.selectedDate.second;
			if (second < 10 && second.length === 1) {
				second = "0" + second;
			}
			return `${hour}:${minute}:${second}`;
		},
	},
	watch: {
		initialDate(val) {
			if (this.rcp_id) {
				this.selectedDate = Object.assign({}, val);
			}
		},
		initialDescription(val) {
			if (this.rcp_id) {
				this.description = val;
			}
		},
		initialName(val) {
			if (this.rcp_id) {
				this.name = val;
			}
		},
	},
	mounted() {
		if (this.$attrs.options && this.$attrs.options.rcp_id !== null) {
			this.rcp_id = this.$attrs.options.rcp_id;
			this.name = this.$attrs.options.initialName;
			this.description = this.$attrs.options.initialDescription;
			this.selectedDate = Object.assign({}, this.$attrs.options.initialDate);
		}
	},
	methods: {
		handleMonthSelect(evt) {
			const val = evt.value;
			const value =
				DateInstance.getDateLocaleData().monthsShort().indexOf(val) + 1;
			this.selectedDate.month = value;
			this.handleDayValue();
		},
		handleInput(e, type) {
			switch (type) {
				case "day": {
					this.handleDayValue();
					return;
				}
				case "year": {
					if (!/^([12]\d{0,3})?$/.test(e.target.value)) {
						e.target.value = this.selectedDate.year;
						return;
					}
					this.handleDayValue();
					break;
				}
				case "hour": {
					if (!/^(([01]\d?)|(2[0-4]?))?$/.test(e.target.value)) {
						e.target.value = this.selectedDate[type];
						return;
					}
					break;
				}
				case "minute":
				case "second": {
					if (!/^([0-5]\d?)?$/.test(e.target.value)) {
						e.target.value = this.selectedDate[type];
						return;
					}
					break;
				}
			}
			this.selectedDate[type] = e.target.value;
		},
		handleDayValue() {
			let value = this.$refs.day.value;
			if (!/^((0[1-9]?)|([12]\d?)|(3[01]?))?$/.test(value)) {
				this.$refs.day.value = this.selectedDate.day;
				return;
			}
			if (this.selectedDate.month) {
				const max_date = new Date(
					this.selectedDate.year,
					this.selectedDate.month,
					0,
				).getDate();
				if (Number(value) > max_date) {
					value = max_date;
				}
			}
			this.selectedDate.day = value;
		},
		// overflowFix () {
		//   this.$refs.wrap.parentElement.style = "overflow: visible;";
		// },
		handleDescription(e) {
			if (
				(this.description.length === 255 && e.data !== null) ||
				/[\n\r\t]/.test(e.target.value)
			) {
				e.target.value = this.description;
				return;
			}
			this.description = e.target.value;
		},
		handleNameInput(e) {
			if (this.name.length === 35 && e.data !== null) {
				e.target.value = this.name;
				return;
			}
			this.name = e.target.value;
		},
		async createDatapoint() {
			if (
				!this.buttonActive ||
				this.description.length > 255 ||
				this.name.length > 35
			) {
				return;
			}
			if (this.editMode) {
				this.editDatapoint();
				return;
			}
			try {
				await api.createDatapoint({
					name: this.name,
					desc: this.description,
					recoverytime: this.datePart + " " + this.timePart,
				});
				this.closeModernModalCompat();
				this.openToastStorage({
					data: {
						type: "success",
						text: "Datapoint created.",
						duration: 1500,
					},
				});
			} catch (error) {
				console.log(error);
				this.openToastStorage({
					data: {
						type: "error",
						text: "Error creating datapoint",
						duration: 5000,
					},
				});
			}
		},
		async editDatapoint() {
			const data = {
				rcp_id: this.rcp_id,
				name: this.name,
				desc: this.description,
				recoverytime: this.datePart + " " + this.timePart,
			};
			try {
				await api.editDatapoint(data);
				this.closeModernModalCompat();
				this.openToastStorage({
					data: {
						type: "success",
						text: "Datapoint edited.",
						duration: 1500,
					},
				});
			} catch (error) {
				console.log(error);
				this.openToastStorage({
					data: {
						type: "error",
						text: "Error creating datapoint",
						duration: 5000,
					},
				});
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import "../recoveryVariables";
.row {
	display: flex;
	gap: 12.5px;
	.input {
		background-color: $input-background;
		border-radius: 0;
		width: 100px;
		height: 44px;
		font-size: 16px;
		font-weight: 600;
		&.name {
			width: 100%;
		}
	}
	textarea {
		background-color: $input-background;
		border-radius: 0;
		width: 100%;
		min-width: 325px;
		min-height: 200px;
		font-size: 16px;
		font-weight: 600;
		max-width: 50vw;
		max-height: 40vh;
	}
}
.textarea {
	position: relative;
}

.input-wrap {
	// height: 700px;
	padding: 20px 50px;
	span {
		font-size: 18px;
		font-weight: 700;
		display: block;
		margin: 15px 0;
		&.limiter {
			position: absolute;
			bottom: 10px;
			right: 10px;
			font-size: 15px;
			margin: 0;
		}
	}
}

.button-wrap {
	margin-top: 30px;
}
.recovery-button {
	background: #1e75fe;
	width: 100%;
	height: 55px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	font-size: 16px;
	cursor: pointer;
	transition: background-color 0.5s ease;
}

.inactive {
	background: #4d4d4d;
	cursor: not-allowed;
}
</style>
