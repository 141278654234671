import { MarsMetaOptions } from "./marsMetaOptions";
import type { IMarsTreeItemResponse } from "./marsResponse";

/**
 * Future global mars file for in use for editor
 */

interface IMarsGlobalFile {
	meta: MarsMetaOptions;
	data: any;
	children: MarsGlobalFile[];
	history: IMarsGlobalFile[];
	fileOptions: {
		isPathExpanded: boolean;
		isFileDownloaded: boolean;
		isFileLockedByMe: boolean;
		isFileLockedByOthers: boolean;
	};
}

export class MarsGlobalFile implements IMarsGlobalFile {
	#data: any;
	#meta = {} as IMarsGlobalFile["meta"];
	#children = [] as IMarsGlobalFile["children"];
	#history = [] as IMarsGlobalFile["history"];
	#fileOptions = {
		isPathExpanded: false,
		isFileDownloaded: false,
		isFileLockedByMe: false,
		isFileLockedByOthers: false,
	};

	constructor(metaPayload: IMarsTreeItemResponse, pathPrefix: string) {
		this.meta = new MarsMetaOptions(metaPayload, pathPrefix);

		// Setup children
		if (metaPayload.children?.length) {
			for (const child of metaPayload.children) {
				this.children.push(new MarsGlobalFile(child, this.meta.fullPath));
			}
		}
	}

	get data() {
		return this.#data;
	}
	set data(value: IMarsGlobalFile["data"]) {
		this.#data = value;
	}

	get meta() {
		return this.#meta;
	}
	set meta(value: IMarsGlobalFile["meta"]) {
		this.#meta = value;
	}

	get children() {
		return this.#children;
	}
	set children(value: IMarsGlobalFile["children"]) {
		this.#children = value;
	}

	/**
	 * Options used in app, but not stored in DB
	 */
	get fileOptions() {
		return this.#fileOptions;
	}
	set fileOptions(value: IMarsGlobalFile["fileOptions"]) {
		this.#fileOptions = value;
	}

	// Future use [recovery maybe]
	get history() {
		return this.#history;
	}
	set history(value: IMarsGlobalFile["history"]) {
		this.#history = value;
	}
}
