import api from "@/api";

const actions = {
	getSiteConfig(store, data) {
		return api.getSiteConfig(data);
	},
	getSiteInitScripts(store) {
		return api.getSiteInitScripts();
	},
	addConfig(store, data) {
		return api.addConfig(data);
	},
	addScript(store, data) {
		return api.addScript(data);
	},
	getFileSystemFiles(store) {
		return api.getFileSystemFiles();
	},
	deleteFileSystemFiles(store, data) {
		return api.deleteFileSystemFiles(data);
	},
	getDomains(store, data) {
		return api.getDomains(data);
	},
	deleteDomain(store, data) {
		return api.deleteDomain(data);
	},
	getUsers(store, data) {
		return api.getUsers(data);
	},
	getSiteEvents(store, data) {
		return api.getSiteEvents(data);
	},
	getPowerUps: (store, data) => api.getPowerUps(data),
	postPowerUpEmail: (store, data) => api.postPowerUps(data),
};

const mutations = {
	SET_COMPONENT(state, payload) {
		state.settingsComponent = payload;
		// let settings = JSON.parse(sessionStorage.settings)
		// settings.activeComponent = payload
		// sessionStorage.settings = JSON.stringify(settings)
	},
};

const state = {
	settingsComponent: "SettingsSoftware",
};
export default { namespaced: true, actions, mutations, state };
