// import eventBus from '@/mixins/eventBus.js'
import { infoConsole } from "@/assets/js/helpers";
import eventBus from "@/mixins/eventBus.js";
import axios from "axios";
import { mapActions } from "vuex";

function parseParamErrorMsg(err) {
	const paramMsg = err.response?.data?.paramMessage;
	const genericMsg = err.response?.data?.message;
	if (paramMsg) {
		return paramMsg;
	} else if (genericMsg) {
		return genericMsg;
	}
	return null;
}

const mixins = {
	data() {
		return {
			cancelToken: null,
		};
	},
	methods: {
		...mapActions({
			saveFileActivePath: "moduleEditor/saveFileActivePath",
		}),
		async saveFileFromActivePath(path) {
			if (this.cancelToken !== null) {
				this.cancelToken.cancel();
				this.cancelToken = null;
			}

			const CancelToken = axios.CancelToken;
			const source = CancelToken.source();

			// Remember the token for cancellation
			this.cancelToken = source;

			const payload = {
				path,
				cancelToken: source.token,
			};
			// try {
			const res = await this.saveFileActivePath(payload);
			eventBus.$emit("cancel-editor-debounce");
			if (res?.data) {
				this.handleSuccessSaveFunc();
			} else if (res !== false) {
				this.handleFailSaveFunc(res);
			}
			return res;
			// } catch (err) {
			//   if (axios.isCancel(err)) {
			//     console.log("Request canceled", err.message);
			//   } else {
			//     // handle error
			//     this.handleCatchSaveErrorFunc(err);
			//   }
			// }
			// If it fails
			// return false;
		},
		async saveAllFilesEditor() {
			const allDirtyFilePaths = Array.from(this.getAllDirtyFilePaths);
			if (!(allDirtyFilePaths && allDirtyFilePaths.length !== 0)) {
				infoConsole("Multiple File save canceled");
				return;
			}

			if (this.cancelToken !== null) {
				this.cancelToken.cancel();
				this.cancelToken = null;
			}

			const CancelToken = axios.CancelToken;
			const source = CancelToken.source();

			// Remember the token for cancellation
			this.cancelToken = source;

			let co = 0;
			let lastErrorMsg = "";
			for (const fileDirtyPath of allDirtyFilePaths) {
				const payload = {
					path: fileDirtyPath,
					cancelToken: source.token,
				};
				try {
					const res = await this.saveFileActivePath(payload);
					if (res?.data) {
						co++;
					}
				} catch (err) {
					infoConsole(err.message, "warn");
					const paramMessage = err.response?.data?.paramMessage;
					lastErrorMsg = paramMessage || err.response?.data?.message;
				}
			}
			if (co === allDirtyFilePaths.length) {
				this.openToastSuccessMsg(this.$i18n.t("file.fileSaveSuccessMulti"));
			} else if (co === 0) {
				const errMsg = lastErrorMsg || this.$i18n.t("file.fileSaveErrorMulti");
				this.openToastErrorMsg(errMsg);
			} else {
				const errMsg =
					lastErrorMsg || this.$i18n.t("file.fileSaveErrorSomeMulti");
				this.openToastErrorMsg(errMsg);
			}
		},
		handleSuccessSaveFunc() {
			// eventBus.$emit('trigger-undo-stack-update-save')
			// >> Only file ids stay outdated, but they should't be used in the tree
			// this.fetchEditorTree()
			eventBus.$emit("editor-file-saved"); // Unused currently
			this.openToastSuccessMsg(this.$i18n.t("file.fileSaveSuccess"));
		},
		handleFailSaveFunc(res) {
			infoConsole("Save FAILED", "warn", res);
			this.openToastErrorMsg(this.$i18n.t("file.fileSaveError"));
		},
		handleCatchSaveErrorFunc(err) {
			const lastErrorMsg = parseParamErrorMsg(err);
			infoConsole(`[Handle catch error] ${err?.message}`, "warn");

			const errMsg = lastErrorMsg || this.$i18n.t("file.fileSaveError");
			this.openToastErrorMsg(errMsg);
		},
	},
};

export default mixins;
