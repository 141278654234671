import { InfoConsole } from "@/assets/js/helpers";
import type { TValidFileActions } from "../core/file-manager/fileManagerTS";
import type { IMarsTreeItemResponse } from "./marsResponse";

/**
 * Used when searching file tree [legacy payload]
 */
type TLegacyPayload = {
	pth_id: number;
	pth_path: string;
	pth_type: string;
	pth_created: string;
	pth_options: string;
};

export interface IMarsMetaOptions {
	id: number;
	oldId: number;
	name: string;
	type: string;
	options: {
		language: string;
		methods?: Partial<Record<TValidFileActions, any>>;
	};
	errors: number;
	created: string;
	fullPath: string;
	// children: MarsMetaOptions[]; // This is done in parent
}

export class MarsMetaOptions implements IMarsMetaOptions {
	id: number;
	oldId: number;
	name: string;
	type: string;
	options: IMarsMetaOptions["options"];
	errors: number = 0;
	created: string = "";
	fullPath: string = "";
	// children: MarsMetaOptions[] = [];

	constructor(payload: IMarsTreeItemResponse, pathPrefix: string) {
		this.id = payload.id;
		this.oldId = payload.id;
		this.name = payload.name;
		this.type = payload.type || "TXT";
		this.options = this.parseStringOptions(payload.options);
		"errors" in payload && (this.errors = payload.errors);
		"created" in payload && (this.created = payload.created);
		// payload.children && (this.children = payload.children);

		// Setup path after "name" has been set
		this.fullPath = this.setupFullPath(pathPrefix);
	}

	setupFullPath(pathPrefix: string) {
		return pathPrefix ? `${pathPrefix}/${this.name}` : this.name;
	}
	get getParentPath() {
		if (this.fullPath.includes("/")) {
			return this.fullPath.split("/").slice(0, -1).join("/");
		}
		return "";
	}
	get getFileExtension() {
		const splitted = this.name.split(".");
		if (splitted.length > 1) {
			return splitted[splitted.length - 1];
		}
		return "";
	}

	parseStringOptions(
		value?: string | Record<string, any>,
		showMessage = true,
	): IMarsMetaOptions["options"] {
		if (value && typeof value === "string") {
			try {
				return JSON.parse(value);
			} catch (err: any) {
				console.warn("Error parsing", err.message);
			}
		} else if (value) {
			showMessage && console.log("Options already parsed", value);

			return value as IMarsMetaOptions["options"];
		}
		return {
			language: "",
		};
	}

	setValidOptionsLanguage() {
		if (this.type && this.type !== "TXT" && !this.options.language) {
			InfoConsole.w(`🟦 Setting default language for: ${this.type}`);
			this.options.language = "JavaScript";
		}
	}

	get isMarsFolder() {
		return this.id === 0 || this.type === "FLD";
	}

	/**
	 * Update the file with new options
	 */
	updateMetaOptions(payload: Partial<IMarsMetaOptions>) {
		"id" in payload && payload.id && (this.id = payload.id);
		"oldId" in payload && payload.oldId && (this.oldId = payload.oldId);
		"name" in payload && payload.name && (this.name = payload.name);
		"type" in payload && payload.type && (this.type = payload.type);
		"options" in payload &&
			payload.options &&
			(this.options = this.parseStringOptions(payload.options, false));
		"errors" in payload && payload.errors && (this.errors = payload.errors);
		"created" in payload && payload.created && (this.created = payload.created);
	}

	/**
	 * Output for Database
	 */
	get getMetaForDb() {
		return {
			id: this.id,
			name: this.name,
			type: this.type,
			options: JSON.stringify(this.options),
			errors: this.errors,
			created: this.created,
		};
	}
}

export class LegacyMarsFileMeta extends MarsMetaOptions {
	constructor(legacyFileObj: TLegacyPayload) {
		const fullPath = legacyFileObj.pth_path;
		const pathWithoutName = fullPath.split("/").slice(0, -1).join("/") || "";

		const params = {
			children: [], // API doesn't send
			errors: 0, // API doesn't send
			id: legacyFileObj.pth_id,
			name: fullPath.split("/").at(-1) || fullPath,
			type: legacyFileObj.pth_type,
			created: legacyFileObj.pth_created,
			options: legacyFileObj.pth_options,
		};

		super(params, pathWithoutName);
	}
}
