import eventBus from "@/mixins/eventBus.js";
import filemanager from "@/modules/core/file-manager/filemanager.js";
import api from "../../api";
import { FileSubKeysTS } from "../core/file-manager/fileManagerTS";

const Vue = {
	// Simulating vue2 set & delete
	set: (obj, prop, value) => {
		if (obj) {
			obj[prop] = value;
		} else {
			console.warn("Object not specified");
		}
	},
	delete: (obj, prop) => {
		if (obj) {
			delete obj[prop];
		} else {
			console.warn("Object not specified");
		}
	},
};

function getTabActiveObj(state, defVal) {
	if (state.activeTabID) {
		const foundObj = state.tabs.find((el) => el.id === state.activeTabID);
		if (foundObj) {
			return foundObj;
		}
	}
	return defVal || {};
}

function checkAndCreateObj(obj, key, isOnlyCheck) {
	// Create if doesn't exist
	if (!Object.prototype.hasOwnProperty.call(obj, key)) {
		if (!isOnlyCheck) {
			Vue.set(obj, key, {});
		}
		return true;
	}
	return false;
}

function getObjPathValue(obj, path) {
	return path.split("/").reduce((prev, curr) => {
		return prev ? prev[curr] : null;
	}, obj || self);
}

function getFileDirtyState(fileObj) {
	return getObjPathValue(fileObj, "options/dirty");
}

function getFileAllInfoTabID(state, tabId) {
	const fIndex = state.tabs.findIndex((tab) => tab.id === tabId);
	if (fIndex !== -1) {
		return state.tabs[fIndex];
	}
	return {};
}

function getFileLang(state, tabId) {
	let file = "";
	let opt = "";
	if (tabId) {
		file = getFileAllInfoTabID(state, tabId).file || {};
		opt = getFileAllInfoTabID(state, tabId).options;
	} else {
		file = getTabActiveObj(state).file || {};
		opt = getTabActiveObj(state).options;
	}

	const formatFileForLang = (fileObjArr) => {
		for (const fileObj of fileObjArr) {
			// Fallback to default lang from file info
			const parseOptions = (options) => {
				if (options && typeof options === "string") {
					try {
						return JSON.parse(options);
					} catch {
						// ignored
					}
				}
				return options;
			};

			if (fileObj?.type) {
				return {
					name: fileObj.name,
					type: fileObj.type,
					options: parseOptions(fileObj.options),
				};
			}
		}

		return {};
	};

	const filePending = opt?.pending;
	const optFileAddedName = {
		name: file.name,
		...filePending,
	};

	return formatFileForLang([optFileAddedName, file]);
}

function formatFileDataResponse(payload, type) {
	const apiKeys = FileSubKeysTS.api;
	const wsKeys = FileSubKeysTS.wss;
	const sharedKeys = FileSubKeysTS.shared;
	const defaultKeys = FileSubKeysTS.default;

	const validKeys = new Set(
		apiKeys.concat(wsKeys).concat(sharedKeys).concat(defaultKeys),
	);
	let parsedType = type;
	let payloadData = payload;

	if (typeof payloadData === "object" && payload.dt_data) {
		// Legacy code
		if (payload.pth_type) {
			parsedType = payload.pth_type;
		}
		if (payload.dt_data) {
			payloadData = payload.dt_data;
		}
		// Legacy code end
	}
	if (!["BIN"].includes(parsedType)) {
		// Ignore BINs [Shows them as-is]
		const parseJsonData = (rawData) => {
			const isTypeWithSubTabs = ["EAP", "API", "WSS"].includes(parsedType);
			if (rawData && typeof rawData === "object" && isTypeWithSubTabs) {
				return rawData;
			} else if (rawData && typeof rawData === "object") {
				return {
					__default: JSON.stringify(rawData, null, 2),
				};
			} else if (typeof rawData === "string") {
				// This must return object with subKeys

				try {
					if (isTypeWithSubTabs) {
						return JSON.parse(rawData);
					}
					return {
						__default: rawData,
					};
				} catch (err) {
					if (type === "API") {
						console.log("[Old Api Str] err j-parse |", err.message);

						// [Legacy] Old API that is string
						const oldAPI = {
							GET: rawData,
							POST: rawData,
						};
						return oldAPI;
					} else {
						console.log("[Defaulting data]", rawData);
						return {
							__default: rawData,
						};
					}
				}
			}
			console.error("Inv data:", typeof rawData);

			return {};
		};

		if (payloadData && payloadData !== '""') {
			const dataObj = parseJsonData(payloadData);
			const keyObject = {};
			for (const key of Object.keys(dataObj)) {
				const splitted = key.split(",");
				for (const miniKey of splitted) {
					if (validKeys.has(key)) {
						// Filter out invalid keys
						keyObject[miniKey] = dataObj[key];
					}
				}
			}

			return { pth_type: parsedType, dt_data: keyObject };
		}
	}

	return {
		pth_type: parsedType,
		dt_data: payloadData,
	};
}

const formatWebSocketData = (dataObj) => {
	const keys = {
		HEAD: "onconnect",
		GET: "onmessage",
		DELETE: "ondisconnect",
	};
	const ogData = dataObj.dt_data;

	const eapToWsData = () => {
		const tempObj = {};
		for (const [key, val] of Object.entries(keys)) {
			tempObj[val] = ogData[key];
		}
		return tempObj;
	};

	const dataWs = eapToWsData();

	const wsFormattedData = {
		dt_data: dataWs,
		type: "WSS", // Maybe should change from 'type' to 'pth_type'
	};

	return wsFormattedData;
};

const formatLegacyApiData = (dataObj) => {
	let data = null;
	const dataRes = dataObj?.dt_data;
	if (dataRes && typeof dataRes === "object") {
		// If has any one the methods
		if (dataRes.GET) {
			data = dataRes.GET;
		} else if (dataRes.POST) {
			data = dataRes.POST;
		}
	} else if (dataRes) {
		// If is multiple keys?
		data = dataRes;
	}

	const dataEap = {
		GET: data,
		POST: data,
	};

	const apiFormattedData = {
		dt_data: dataEap,
		pth_type: "EAP", // Changed from 'type' to 'pth_type'
	};
	return apiFormattedData;
};

const packPayloadPostFile = (dataStr, file, cancelToken) => {
	const formatFileDataForSave = (dataStr, file) => {
		const fileInst = new filemanager.MarsFileMeta(file);
		const apiObj = {
			filedata: dataStr,
			...fileInst.getDbFile(),
		};

		return apiObj;
	};
	const formattedFile = formatFileDataForSave(dataStr, file);

	const params = {
		data: {
			fileArray: [formattedFile],
		},
		cancelToken,
	};

	return api.postFile(params);
	// return true;
};

const createFileHelper = async (dataStr, file, cancelToken, dispatch) => {
	// File tree
	const res = await packPayloadPostFile(dataStr, file, cancelToken);
	const newId = res.data?.changedFiles?.[0]?.newId;
	if (newId) {
		const newOptions = res.data.changedFiles[0]?.options || file.options;
		const splitPath = file.fullPath.split("/");

		const fileParams = {
			children: [], // Default
			errors: 0, // Default
			fullPath: file.fullPath,
			id: newId,
			name: splitPath[splitPath.length - 1],
			options: newOptions,
			type: file.type,
		};
		const fileInst = new filemanager.MarsFileMeta(fileParams);

		const formatFile = {
			__file_downloaded: true, // So the editor doesn't get into locked state
			file: fileInst,
		};

		dispatch("mergeFileStore", formatFile);
	}
	return res;
};

const modifyFile = async ({
	edData,
	marsFileMeta,
	tabId,
	store,
	cancelToken,
}) => {
	let stringedData = edData;
	if (typeof edData !== "string") {
		stringedData = JSON.stringify(edData);
	}

	const blob = new Blob([stringedData], { type: "text/plain" });
	let pathId = null;
	let firstResponse = null;

	await new Promise((resolve, reject) => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		parseFileForChunks(
			blob,
			async (fileChunkStr) => {
				if (pathId) {
					// Append
					const fd = new FormData();
					fd.append("file", fileChunkStr);
					fd.append("pathId", pathId);
					fd.append("isText", "true");
					fd.append("isLast", "false");

					await api.postImportAppend(fd);
				} else {
					// Create
					let res = null;
					try {
						const file = marsFileMeta.getFile();

						res = await packPayloadPostFile(fileChunkStr, file, cancelToken);
						pathId = res.data?.changedFiles?.[0]?.newId ?? null;
					} catch (err) {
						console.log(`[WARN - F] ${err.message}`, marsFileMeta);
						reject(err);
					}
					firstResponse = res;
				}
			},
			resolve,
		);
	});

	// Update store data with new values
	if (pathId) {
		// await dispatch('mergeFileStore', fileObj)
		store.commit("CHANGE_DIRTY_STATE_BY_FILE_PATH", {
			fullPath: marsFileMeta.fullPath,
			isDirty: false,
		});
		await store.dispatch("syncCurrentFileSavedToCurrent", tabId);
	}

	eventBus.$emit("check-last-file-error");

	// Before code was here to reset editor dirty state, >>
	// and to fetch a new file here after updating

	return firstResponse;
};

async function parseFileForChunks(file, callback, resolve) {
	const fileSize = file.size;
	const chunkSize = 64 * 1024; // bytes
	let offset = 0;
	let chunkReaderBlock = null;

	chunkReaderBlock = async function (_offset, length, _file) {
		const blob = _file.slice(_offset, length + _offset);

		try {
			const blobResponse = await blob.text();
			offset += chunkSize;
			await callback(blobResponse); // callback for handling read chunk

			if (offset >= fileSize) {
				// console.log('Done reading file');
				resolve();
				return;
			}

			// Next block chunk
			await chunkReaderBlock(offset, chunkSize, file);
		} catch (err) {
			console.log("Read error: " + err.message);
		}
	};

	// First block
	await chunkReaderBlock(offset, chunkSize, file);
}

export default {
	getTabActiveObj,
	getFileLang,
	getFileAllInfoTabID,
	formatWebSocketData,
	formatLegacyApiData,
	checkAndCreateObj,
	getObjPathValue,
	getFileDirtyState,
	formatFileDataResponse,
	createFileHelper,
	modifyFile,
};
