import Command from "../AbstractCommand";

/*
    parameters: {
      index: {
        name: String,
        type: String
      },
      tableName: String
    }
 */

class DropIndexCommand extends Command {
	constructor(table, index) {
		super("DropIndex");
		this.index = index;
		this.tableName = table.name;
	}

	execute() {
		let ddl = "ALTER TABLE `" + this.tableName + "`\n";
		ddl +=
			this.index.type === "PRIMARY"
				? "DROP PRIMARY KEY"
				: "DROP INDEX `" + this.index.name + "`";
		return ddl;
	}
}

export default DropIndexCommand;
