<template lang="pug">
.popup-wrap(
	:class="{ black: parseIfFullBack }",
	@mousedown.self="addPendClose",
	@mouseup.self="checkPendClose"
)
	popup-comp(
		:class="{ 'block-close': triggerBlockClose }",
		:popupData="popupData",
		:popupEvents="popupEvents",
		@close-popup="closePopup"
	)
</template>

<script>
// UPDATED: Aug 2021 ---
// <<< HOW TO USE >>>
// Popup Options example
// this.openPopupStorage(props)
// props:
// {
//    data:{
//      type: 'confirm' // Default > 'alert'
//      text: "Hello world",
//      okBtn: 'ok' // Text for alert btn [ALERT popup]
//      leftBtn: 'Cancel' // Text for cancel btn [CONFIRM,PROMPT]
//      rightBtn: 'Confirm' // Text for confirm btn [CONFIRM,PROMPT]
//      value: '' // Value for input to have on start [PROMPT]
//      placeholder: '' // placeholder for input [PROMPT]
//      allowWrapClose: false // Default > true [ Allows popup to be closed when clicking behind it ]
//    },
//    events: {
//      onconfirm: () => {} // callback when yes
//      oncancel: () => {} // callback when no
//      onclose: () => {} // callback function to invoke on popup close
//    }
// }
import PopupComp from "./PopupComp.vue";
import { focusFirstElement as hFocusFirstElement } from "@/assets/js/helpers";
import { mapGetters } from "vuex";
export default {
	components: {
		PopupComp,
	},
	data() {
		return {
			triggerBlockClose: false,
			isPendingWrapClose: false,
		};
	},
	computed: {
		...mapGetters({
			popupData: "modulePopup/popupData",
			popupEvents: "modulePopup/popupEvents",
		}),
		parseIfFullBack() {
			return this.popupData && this.popupData.isFullBlack === true;
		},
	},
	mounted() {
		setTimeout(() => {
			// Delay because of DOM render
			hFocusFirstElement(this.$el);
		}, 10);
	},
	methods: {
		closePopup(elName) {
			const wrapDenyClose =
				elName === "wrap" && this.popupData.allowWrapClose === false;

			if (!elName || !wrapDenyClose) {
				this.closePopupStorage();
			}
		},
		addPendClose() {
			this.isPendingWrapClose = true;
			setTimeout(() => {
				this.isPendingWrapClose = false;
			}, 2000);
		},
		checkPendClose() {
			const wrapDenyClose = this.popupData.allowWrapClose === false;
			if (this.isPendingWrapClose) {
				if (wrapDenyClose) {
					if (!this.triggerBlockClose) {
						this.triggerBlockClose = true;
						setTimeout(() => {
							this.triggerBlockClose = false;
						}, 500);
					}
				} else {
					this.closePopup("wrap");
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.popup-wrap {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: 201;

	&.black {
		background: rgba(0, 0, 0, 1);
	}

	.popup {
		margin-top: 100px;
		max-width: 640px;

		&.block-close {
			animation: scaleAnim 0.3s linear 0.01s;
		}
	}
}

@include fadeTransition;
@include scaleFadeTransition;

// Used when modal is blocked from closing
@keyframes scaleAnim {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.04);
	}

	100% {
		transform: scale(1);
	}
}
</style>
