<script setup lang="ts">
// Updated 23-11
import { debounce } from "@/assets/js/helpers";
import router from "@/router";
import { useResizeObserver, type UseResizeObserverReturn } from "@vueuse/core";
import { type RouteParamsRaw, type LocationQueryRaw } from "vue-router";

export interface IUniTabProp {
	id?: number | string;
	name: string;
	label: string;
	icon?: string;
	routeName?: string;
	routeParams?: RouteParamsRaw;
	routeQuery?: LocationQueryRaw;
	isDisabled?: boolean;
	isInactive?: boolean;
	isLocked?: boolean;
	isDirty?: boolean;
	isError?: boolean;
	isClosable?: boolean;
	isPinned?: boolean;
	canShowDirty?: boolean;
}

const props = withDefaults(
	defineProps<{
		tabs: IUniTabProp[];
		activeTab?: IUniTabProp | null;
		modernUi?: boolean;
		canCloseTabs?: boolean;
		canDisplayVerticalLine?: boolean;
		canShowDirty?: boolean;
		canInteractTabs?: boolean;
		isTopLine?: boolean;
	}>(),
	{
		activeTab: null,
		canCloseTabs: true,
		canInteractTabs: true,
		isTopLine: false,
	},
);
const emit = defineEmits<{
	(
		e: "tab-clicked",
		value: { evt: Event; index: number; tab: IUniTabProp },
	): void;
	(e: "close-tab", value: IUniTabProp): void;
	(
		e: "open-context-menu",
		value: { evt: Event; index: number; tab: IUniTabProp },
	): void;
}>();

const dynamicLineStyle = ref({});
const tabsWrapper = ref<HTMLElement | null>(null);
const uniTabsRef = ref<HTMLElement | null>(null);
let resizeObserver: UseResizeObserverReturn | null = null;

const currentRoute = computed(() => router.currentRoute.value);
const activeTabComputed = computed(() => {
	// Custom
	const identifiers = ["id", "name", "label"];
	if (props.activeTab) {
		for (const idKey of identifiers) {
			if (idKey in props.activeTab) {
				const tabId = props.activeTab[idKey as keyof typeof props.activeTab];
				return props.tabs.find(
					(tab) => tab[idKey as keyof typeof tab] === tabId,
				);
			}
		}
	}

	// Router native
	return props.tabs.find((tab) => tab.routeName === currentRoute.value.name);
});
const hasCustomActive = computed(() => {
	return props.activeTab && Boolean(Object.keys(props.activeTab).length);
});

watch(activeTabComputed, () => {
	setTimeout(() => {
		// Timeout to wait for router links to be updated
		// Checking for resizeObs because comp on deactivated shouldn't update DOM element
		if (props.modernUi && resizeObserver) {
			// if(currentRoute.value.matched)
			setActiveModernLine();
		}
	}, 50);
});

function hasExtraOptions(tab: IUniTabProp) {
	const isLocked = tab.isLocked;
	const isDirty = tab.isDirty && tab.canShowDirty;
	const isError = tab.isError;
	const isClosable = isTabClosable(tab);
	return isLocked || isDirty || isError || isClosable;
}
function getRouteParams(tab: IUniTabProp) {
	const params = {
		...currentRoute.value.params,
		...tab.routeParams,
	};
	return params;
}
function getRouteQuery(tab: IUniTabProp) {
	const query = {
		...currentRoute.value.query,
		...tab.routeQuery,
	};
	return query;
}
function isActiveTab(tab: IUniTabProp): boolean {
	if (!activeTabComputed.value) return false;

	const identifiers = ["id", "name", "label"]; // Fallback for invalid payload
	for (const idKey of identifiers) {
		if (idKey in activeTabComputed.value) {
			const activeTabId =
				activeTabComputed.value[idKey as keyof typeof activeTabComputed.value];
			const tabId = tab[idKey as keyof typeof tab];
			return activeTabId === tabId;
		}
	}

	// if (hasCustomActive && props.activeTab) {
	// 	return (
	// 		tab.name === props.activeTab.name ||
	// 		tab.label === props.activeTab.label ||
	// 		JSON.stringify(tab) === JSON.stringify(props.activeTab)
	// 	);
	// }
	return false;
}
function isTabInactive(tab: IUniTabProp) {
	return tab.isInactive;
}
function onClickTab(evt: Event, tab: IUniTabProp, index: number) {
	emit("tab-clicked", {
		evt,
		index,
		tab,
	});
}
function setActiveModernLine() {
	if (props.modernUi) {
		const parentOffsetLeft = uniTabsRef.value?.offsetLeft || 0;
		const activeEl = tabsWrapper.value?.querySelector(
			".tab-active",
		) as HTMLElement;

		if (activeEl) {
			const unit = "px";
			dynamicLineStyle.value = {
				width: `${activeEl.offsetWidth}${unit}`,
				left: `${parentOffsetLeft + activeEl.offsetLeft}${unit}`,
			};
		} else {
			// Remove if in case of no tabs
			dynamicLineStyle.value = {};
		}
		// else {
		// 	// Not needed because sometimes a active el can be something not from tab list
		// 	console.warn(
		// 		"Tab Element not found!",
		// 		parentOffsetLeft,
		// 		currentRoute.value.path,
		// 		uniTabs.value?.querySelectorAll("*"),
		// 	);
		// }
	}
}
function setResizeObserver() {
	if (props.modernUi) {
		const divElem = uniTabsRef.value;
		if (divElem) {
			resizeObserver = useResizeObserver(divElem, () => {
				setActiveModernLine();
			});
		}

		window.addEventListener("resize", debounce(onWindowResizeLogic, 500, true));
	}
}
function onWindowResizeLogic() {
	setActiveModernLine();
}

function isTabClosable(tab: IUniTabProp): boolean {
	return props.canCloseTabs && tab.isClosable !== false;
}
function onCloseTab(tab: IUniTabProp) {
	if (isTabClosable(tab)) {
		emit("close-tab", tab);
	}
}

// const imageStyles = computed(() => {
//   return {
//     lock: { background: `no-repeat url('🔒') 0% 0% / 100% 100%` },
//     dirty: {
//       background: `no-repeat url('🔴') 0% 0% / 100% 100%`,
//     },
//     error: {
//       background: `no-repeat url('⚠') 0% 0% / 100% 100%`,
//     },
//   };
// });

const preventMiddleClick = (evt: MouseEvent) => {
	const isMiddleClick = evt.button === 1;
	if (isMiddleClick) {
		// Middle click prevent scroll
		evt.preventDefault();
	}
};
const mouseUpHandler = (evt: MouseEvent, tab: IUniTabProp, index: number) => {
	evt.preventDefault();
	if (tab.isDisabled) return false;

	const isMiddleClick = evt.button === 1;
	const isLeftClick = evt.button === 0;
	if (isMiddleClick) {
		// Middle click close tab
		if (isTabClosable(tab)) {
			onCloseTab(tab);
		}
	} else if (isLeftClick) {
		onClickTab(evt, tab, index);
	}
	// NOTE: Context menu here fires on windows | when no timeout
};

function onContextMenu(evt: MouseEvent, tab: IUniTabProp, index: number) {
	emit("open-context-menu", {
		evt,
		index,
		tab,
	});
}

const tabClasses = (tab: IUniTabProp) => {
	return [
		tab.routeName ? "route-tab" : "plain-tab",
		{
			"tab-active": isActiveTab(tab),
			"native-active": !hasCustomActive.value,
			inactive: isTabInactive(tab),
			pinned: tab.isPinned,
			interactable: props.canInteractTabs,
		},
	];
};

const generateRouterTo = (tab: IUniTabProp) => {
	if (tab.routeName) {
		return {
			name: tab.routeName,
			params: getRouteParams(tab),
			query: getRouteQuery(tab),
		};
	}
	return null;
};

function canDisplayVerticalLineLogic(tab: IUniTabProp, index: number) {
	if (!props.canDisplayVerticalLine) return false;

	const activeTabIndex = props.tabs.findIndex((tab) => isActiveTab(tab));
	if (index !== activeTabIndex && index + 1 !== activeTabIndex) {
		return true;
	}

	return false;
}
function setupInit() {
	// Don't use timeout because of popping animation for line
	if (props.modernUi && !resizeObserver) {
		setResizeObserver();
	}
}
function clearResObs() {
	resizeObserver?.stop();
	resizeObserver = null;
}
function removeEvListener() {
	window.removeEventListener(
		"resize",
		debounce(onWindowResizeLogic, 500, true),
	);
}

onMounted(() => {
	setupInit();
});
onActivated(() => {
	setupInit();
});

onUnmounted(() => {
	clearResObs();
	removeEvListener();
});
onDeactivated(() => {
	clearResObs();
	removeEvListener();
});
</script>

<template lang="pug">
.uni-tabs-wrap(ref="tabsWrapper")
	.line-wrapper.top(v-if="modernUi && isTopLine")
		.line(:style="dynamicLineStyle")
	.uni-tabs(ref="uniTabsRef")
		template(v-for="(tab, index) in tabs", :key="index")
			component.tab(
				:is="tab.routeName ? 'router-link' : 'div'",
				:class="tabClasses(tab)",
				:to="generateRouterTo(tab)",
				:disabled="tab.isDisabled",
				@mousedown="preventMiddleClick",
				@mouseup="mouseUpHandler($event, tab, index)",
				@contextmenu.prevent="onContextMenu($event, tab, index)"
			)
				.row
					.icon(v-if="tab.icon", :src="tab.icon")
					transition(name="cut-slide")
						.content-part(v-if="!tab.isPinned")
							.label.name {{ tab.label || tab.name }}
							.other(v-if="hasExtraOptions(tab)")
								span.locked(v-if="tab.isLocked")
									span 🔒
									//- svg(:style="imageStyles.lock")
								span.dirty(v-if="tab.isDirty && canShowDirty")
									span 🔴
									//- svg(:style="imageStyles.dirty")
								span.error(v-if="tab.isError")
									span ⚠
									//- svg(:style="imageStyles.error")
								span.close-wrap(
									v-show="isTabClosable(tab)",
									tabindex="0",
									@mouseup.left.stop="onCloseTab(tab)"
								)
									i-fa-xmark.del
									//- span &#10006;
			.vertical-line-wrap(v-if="canDisplayVerticalLineLogic(tab, index)")
				.vertical-line
	.line-wrapper(v-if="modernUi && !isTopLine")
		.line(:style="dynamicLineStyle")
</template>

<style lang="scss" scoped>
$gray-color: #7c7d86;
$tab-color: $main-accent-color;
// $dark-gray: $gray-color;
$grad-color: #2d2f30;
$background-color: transparent;

// $grad-color: #28292a; // Maybe this
// $grad-color: #25282a;

.uni-tabs-wrap {
	// --tab-background: var(--canvas-background);
	$tab-text-color: #b0b4b9;
	$active-tab-text-color: white;

	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	padding: 5px 0 0;
	overflow-x: auto;
	overflow-y: hidden;

	@mixin color-tab {
		color: $tab-color !important;

		.line {
			background-color: $tab-color !important;
		}
	}

	.line-wrapper {
		display: flex;
		height: 3px;
		width: 100%;

		&.top {
			margin-bottom: 5px;
		}

		&:not(&.top) {
			margin-top: 5px;
		}

		.line {
			position: relative;
			height: 3px;
			transition: all 0.3s ease;
			background-color: $tab-color !important;
		}
	}

	.uni-tabs {
		user-select: none;
		align-items: stretch;
		font-size: 1rem;
		justify-content: space-between;

		// overflow: hidden;
		// overflow-x: auto;
		white-space: nowrap;
		position: relative;
		display: inline-flex;
		height: 100%;

		.tab {
			$icon-size: 13px;

			// min-width: 120px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 10px;
			outline: none;
			white-space: nowrap;
			position: relative;
			font-size: 1.05rem;
			color: $tab-text-color;
			height: 100%;
			width: 100%;
			gap: 5px;
			text-decoration: none;
			cursor: pointer;

			&:not(.interactable) {
				pointer-events: none;
			}

			// &:not(:last-child) {
			//   border-right: 1px solid $medium-gray;
			// }

			.line {
				height: 3px;
				width: 100%;
				background-color: $gray-color !important;
			}

			.row {
				display: flex;
				justify-content: space-between;
				gap: 5px;
				align-items: center;
				height: 100%;

				.icon {
					display: flex;
					align-items: center;
					justify-content: center;

					.node-image {
						margin: auto 7px;
						min-width: 12px;
						max-width: 12px;
						height: 12px;
						z-index: 1;
					}
				}

				.content-part {
					display: flex;
					align-items: center;
					gap: 5px;

					.name,
					.label {
						min-width: 30px;
						max-width: 300px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						user-select: none;
						font-weight: 600;
					}

					.other {
						display: flex;
						align-items: center;
						gap: 5px;

						svg {
							max-width: $icon-size;
							max-height: $icon-size;
							width: 100%;
							height: 100%;
						}

						.dirty,
						.locked,
						.error {
							color: var(--app-accent-color);
							display: flex;
							max-width: $icon-size;

							// max-height: $icon-size;
						}

						.locked {
							display: flex;
							justify-content: center;
							align-items: center;

							// svg {
							//   filter: invert(0.8);
							// }
						}

						.close-wrap {
							display: flex;
							transition: opacity 0.2s ease;
							opacity: 0.3;

							> * {
								pointer-events: none;
							}

							@media screen and (width >= 768px) {
								opacity: 0;
							}

							@media (hover: none) and (pointer: coarse) {
								opacity: 0.3;
							}
						}

						// .faico {
						// 	&:hover {
						// 		transition: color 0.3s ease;
						// 		color: var(--app-accent-color);
						// 	}
						// }
					}
				}
			}

			&.pinned {
				padding: 0 7px 1px;
			}

			&.inactive {
				opacity: 0.4;
			}

			&.tab-active {
				// background-color: var(--tab-background);

				background-color: transparent;

				// background-color: rgba($background-color, 0.95);
				// backdrop-filter: blur(2px);

				// box-shadow: inset 0 10px 15px 0 rgba($background-color, 0.4);

				.row {
					.name,
					.label {
						// mix-blend-mode: exclusion;
						color: $active-tab-text-color;
					}
				}

				&::before {
					content: "";
					position: absolute;
					pointer-events: none;

					// background: linear-gradient(
					// 	180deg,
					// 	#26272880 42.57%,
					// 	#26272866 50%,
					// 	#0000 100%
					// );
					// background: linear-gradient(
					// 	0deg,
					// 	rgba(214, 214, 214, 0.17) 0%,
					// 	$grad-color 72.4%
					// );
					// background: linear-gradient(
					// 	180deg,
					// 	rgba($background-color, 0.4) 22.57%,
					// 	transparent 100%
					// );
					inset: 0;
				}

				&::after {
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					height: 4px;
					background-color: var(--app-accent-color);
				}

				&:hover {
					background-color: lighten($background-color, 2);
					font-weight: 800;
				}
			}

			// &:not(.tab-active) {
			// 	background-color: rgba($background-color, 0.95);
			// 	backdrop-filter: blur(10px);
			// }

			&:hover {
				transition: all 0.2s ease;
				background-color: lighten($background-color, 2);

				.row {
					.content-part {
						.close-wrap {
							opacity: 1;
						}
					}
				}
			}
		}

		.vertical-line-wrap {
			display: flex;
			height: 100%;
			background-color: rgba($background-color, 0.95);
			backdrop-filter: blur(10px);
		}

		&::-webkit-scrollbar {
			height: 0.7em;
		}
	}

	.cut-slide-enter-active,
	.cut-slide-leave-active {
		transition: all 0.1s;
	}

	.cut-slide-enter-from {
		opacity: 0;
		transform: translateX(-40px);
	}

	.cut-slide-leave-to {
		opacity: 0;
		transform: translateX(-30px);
	}
}
</style>
