import TestPage from "@/modules/test/TestPage.vue";
import { createRouter, createWebHistory } from "vue-router";
import AuthRoutes from "./modules/auth/authRoutes.js";
import AppRoutes from "./modules/main/appRoutes.js";
import SandboxRoutes from "./modules/sandbox/routes.js";
import DefaultRoutes from "./routes/defaultRoutes.js";
import BlankPage from "./routes/BlankPage.vue";

// When out of memory, use this > BASH: export NODE_OPTIONS=--max_old_space_size=4096

const routes = [];
// Import new routes here
const routeComps = [AppRoutes, AuthRoutes, SandboxRoutes, DefaultRoutes];
iterateRoutes();

const DEBUG_ROUTES = {
	namespace: "debug",
	routes: [
		{
			path: "/test-hd",
			name: "TestPage",
			component: TestPage,
		},
	],
};
const MISC_ROUTES = {
	namespace: "x",
	routes: [
		{
			path: "x",
			name: "BlankPage",
			component: BlankPage,
		},
	],
};

if (!import.meta.env.PROD) {
	addRoutes(DEBUG_ROUTES, routes);
}
addRoutes(MISC_ROUTES, routes);

function addRoutes(newRoutes, globalRoutes) {
	for (const route of newRoutes.routes) {
		let routePath = route.path;
		if (newRoutes.namespace) {
			routePath = `/${newRoutes.namespace}${routePath}`;
			route.path = routePath;
		}
		globalRoutes.push(route);
	}
}

function iterateRoutes() {
	for (const comp of routeComps) {
		addRoutes(comp, routes);
	}
}

const router = new createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.needsSid)) {
		if (sessionStorage.sid?.length) {
			next();
		} else {
			// If session is invalidated
			next({
				name: "LoginComp",
			});
		}
	} else {
		next();
	}
});

export default router;
