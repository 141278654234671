import { createGlobalState } from "@vueuse/core";
import { MarsGlobalFile } from "../editor/marsGlobalFile";
import type { IMarsTreeItemResponse } from "../editor/marsResponse";
import type { MarsMetaOptions } from "../editor/marsMetaOptions";

export const useFileStore = createGlobalState(() => {
	const treeData = ref<MarsGlobalFile[]>([]);
	const flatTree = ref<MarsMetaOptions[]>([]);
	const isLoadingTree = ref(false);
	const treeOpenPaths = ref(new Set<string>([]));
	const lastUsedLang = ref("");

	watch(treeData, () => {
		console.warn("Hello tree", treeData.value);
	});
	// watch(flatTree, () => {
	// 	console.warn("Hello flat tree", flatTree.value);
	// });
	const getFlatTreeView = computed(() => {
		return flatTree.value.filter((item) => {
			if (item.fullPath.includes("/")) {
				const isOpened = isPathExpanded(item.getParentPath);
				if (isOpened) return true;
			} else {
				// Always return root elements
				return true;
			}
			return false;
		});
	});
	function getFileFromTree(path: string): MarsMetaOptions | null {
		if (!path) {
			console.warn("Path not provided");
			return null;
		}
		if (flatTree.value.length === 0) {
			console.warn("File tree is empty");
			return null;
		}

		return flatTree.value.find((file) => file.fullPath === path) || null;
	}

	function createTreeDataInstances(arr: IMarsTreeItemResponse[] = []) {
		const treeDataInstances = arr.map((item) => new MarsGlobalFile(item, ""));
		treeData.value = treeDataInstances;
		flatTree.value = flatDeep(treeDataInstances, 100);
	}

	function flatDeep(arr: MarsGlobalFile[] = [], d = 2): MarsMetaOptions[] {
		if (d > 0) {
			return arr.flatMap((val) => {
				if (Array.isArray(val.children) && val.children.length) {
					const fdArr = flatDeep(val.children, d - 1);
					return [val.meta, ...fdArr];
				} else {
					return val.meta;
				}
			});
		}
		return arr.map((item) => item.meta);
	}
	function clearTree() {
		treeData.value = [];
		flatTree.value = [];
	}

	function manageOpenPath(path: string, shouldForce = false) {
		if (!path) {
			console.warn("No path provided", path, treeOpenPaths.value);
		}

		if (shouldForce) {
			treeOpenPaths.value.add(path);
		} else {
			if (treeOpenPaths.value.has(path)) {
				treeOpenPaths.value.delete(path);
			} else {
				treeOpenPaths.value.add(path);
			}
		}
	}
	function isPathExpanded(path: string) {
		return path ? treeOpenPaths.value.has(path) : false;
	}
	function clearTreePaths() {
		treeOpenPaths.value.clear();
	}

	return {
		treeData,
		flatTree,
		treeOpenPaths,
		isLoadingTree,
		lastUsedLang,
		getFlatTreeView,
		getFileFromTree,
		clearTree,
		createTreeDataInstances,
		manageOpenPath,
		isPathExpanded,
		clearTreePaths,
	};
});
