export default {
	namespaced: true,
	state: {
		contextOptions: {
			evt: null,
			data: null,
		},
	},
	mutations: {
		OPEN_CONTEXT_MENU(state, payload = {}) {
			state.contextOptions.data = payload.data;
			state.contextOptions.evt = payload.evt;
		},
		CLOSE_CONTEXT_MENU(state, payload) {
			state.contextOptions = {
				evt: null,
				data: null,
			};
		},
	},
	actions: {
		openContextMenuStorage({ commit }, payload) {
			commit("OPEN_CONTEXT_MENU", payload);
		},
		closeContextMenuStorage({ commit }, payload) {
			commit("CLOSE_CONTEXT_MENU", payload);
		},
	},
};
