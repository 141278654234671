import eventBus from "@/mixins/eventBus.js";
import eh from "@/modules/editor/editorHelper.js";
import merge from "lodash.merge";
import * as monacoEditor from "monaco-editor/esm/vs/editor/editor.api";
import api from "../../api";
import filemanager from "../core/file-manager/filemanager";
import { eventList, methodList } from "./fileActionList";
import { mergeFileTree } from "./editorState";
import { infoConsole } from "@/assets/js/helpers";
import { SubKeysPerType } from "../core/file-manager/fileManagerTS";

const Vue = {
	// Simulating vue2 set & delete
	set: (obj, prop, value) => {
		if (obj) {
			obj[prop] = value;
		} else {
			infoConsole("Object not specified", "warn");
		}
	},
	delete: (obj, prop) => {
		if (obj) {
			delete obj[prop];
		} else {
			infoConsole("Object not specified", "warn");
		}
	},
};

function generateEditorInstanceView(newDataObj = {}, newEditorVersion = {}) {
	// Payload [new data]
	const lang = newDataObj.lang;
	let data = newDataObj.dt_data;
	if (
		newDataObj.pth_type === "BIN" && // Convert [BIN] data to readable (string) format
		typeof data !== "string"
	) {
		data = JSON.stringify(newDataObj.dt_data);
	}

	const generateEditorObjModel = (val = "") => {
		const model = monacoEditor.editor.createModel(val, lang);
		return {
			monacoModel: () => model,
			state: null,
			editorVersion: {
				min: 1,
				max: 1,
				current: 1,
				saved: 1,
				...newEditorVersion,
			},
		};
	};

	// Creates all models for a data
	if (data && typeof data === "object") {
		// Subs obj
		const modelsObj = {};
		for (const [key, val] of Object.entries(data)) {
			const valMod = generateEditorObjModel(val);
			modelsObj[key] = valMod;
		}
		return modelsObj;
	} else if (data && typeof data === "string") {
		// Simple strings [ This will probably be for BINs ]
		return {
			__default: generateEditorObjModel(data),
		};
	} else if (!data && lang) {
		console.log("[Warn]>> Created model from empty data", lang, data);
		// Handle all cases default subKeys [FILE TYPES]
		const instance = generateEditorObjModel(data);
		switch (newDataObj.pth_type) {
			case "EAP":
			case "API": {
				return { GET: instance };
			}
			case "WSS": {
				return { onconnect: instance };
			}
			default: {
				return { __default: instance };
			}
		}
	}
	// Invalid / Or empty data {Test}
	console.warn("Invalid data", data, lang);
	return {
		__default: generateEditorObjModel(""),
	};
}

function generateEmptyEditorInstanceView(payload, lang) {
	// ** [get]: { value, lang }
	const model = monacoEditor.editor.createModel(payload.value, lang);
	return {
		monacoModel: () => model,
		state: null,
		editorVersion: {
			min: 1,
			max: 1,
			current: 1,
			saved: 1,
		},
	};
}

function updateEditorOneInstanceModel(
	editorInstance = {},
	instancesValue,
	subActStr = "",
) {
	const updateModel = (model, value) => {
		model?.pushEditOperations(
			[],
			[{ range: model.getFullModelRange(), text: value }],
			() => null,
		);
	};

	if (!editorInstance.monacoModel) {
		console.warn("Editor model not provided");
		return;
	}

	// Update in-place
	if (instancesValue && typeof instancesValue === "object") {
		// Subs
		for (const [key, val] of Object.entries(instancesValue)) {
			if (subActStr && key === subActStr) {
				// Skip [update is done in editor comp]
			} else {
				updateModel(editorInstance[key].monacoModel?.(), val);
			}
		}
	} else if (instancesValue) {
		updateModel(editorInstance.monacoModel?.(), instancesValue);
	} else {
		console.warn("No update model data");
	}
}

export default {
	namespaced: true,
	state: {
		// TABS Template
		// {
		//    id, // Tab id that is used in tab component
		//    __file_downloaded: false // Is file downloaded from server
		//    __editor: {} // Editor model, state, undo versions, outline...
		//    isPreloadOnly: false // Used so the tabs component doesn't render the current file in the editor view
		//    path: '',
		//    file: file info data,
		//    options: {api,ws,dirty|[plain files]},
		//    fileProps: {fileVersion},
		// }
		tabs: [],
		tabCo: 1,
		activeTabID: null, // Don't watch for this key,
		isEditorPreview: false,
		isMultiPreview: true,
		editorFileApiSidebarTab: "",
		tabPathHistoryStack: [],
		autosaveFilepaths: {},
		editorLinterState: false,
	},
	getters: {
		editorLinterState: (state) => state.editorLinterState,
		getCorrectedTabs: (state, getters, rootState, rootGetters) => {
			// Returns tabs but with proper file object
			return state.tabs.map((tab) => {
				// Note: This is solved by updating "file" state when required
				// Note2: This caused slowdown of the file tree and editor in the past
				// const getFile = rootGetters["moduleFileTree/getFileFromTree"](tab.path);
				return tab;
				// NOTE: Losing reactivity when spreading tabdata
				// return {
				//   ...tab,
				//   // file: getFile,
				//   // properFile: getFile, // This is used for migration key
				// };
			});
		},
		getActiveTabData: (state, getters) => {
			return getters.getCorrectedTabs.find(
				(tab) => tab.id === state.activeTabID,
			);
		},
		getCurrentOtherTab: (state) => (tabClassName) => {
			return state.editorFileApiSidebarTab === tabClassName;
		},

		getCurrentFileStore: (state, getters) => {
			// Mostly used for template
			const emptyObj = {
				path: "",
				file: {},
				data: {},
				options: {},
				fileProps: {
					fileVersion: 1,
				},
			};
			return getters.getActiveTabData || emptyObj;
		},
		getCurrentFileInfo: (state, getters) => {
			return getters.getActiveTabData?.file || {};
		},
		getCurrentFileLang: (state, getters) => {
			return eh.getFileLang(state) || {};
		},

		getCurrentFileOptionsPending: (state, getters) => {
			const pendingOptions =
				getters.getActiveTabData?.options?.pending?.options;
			return pendingOptions || null;
		},
		getCurrentFileOptionsParsed: (state, getters) => {
			let currentOptions = getters.getCurrentFileInfo?.options;
			if (currentOptions && typeof currentOptions === "string") {
				try {
					currentOptions = JSON.parse(currentOptions);
					delete currentOptions.language;
				} catch (err) {
					console.warn(err.message, currentOptions);
				}
			}

			if (getters.getCurrentFileOptionsPending) {
				const merged = merge(
					{},
					currentOptions,
					getters.getCurrentFileOptionsPending,
				);
				return merged;
			}

			// const merged = merge({}, currentOptions, pendingOptions);
			return currentOptions;
		},
		getCurrentFileMethodOptions: (state, getters) => {
			const pendingMethods = getters.getCurrentFileOptionsPending?.methods;
			const currentMethods = getters.getCurrentFileOptionsParsed?.methods;

			const getMethod = (methods) => {
				const path = getters.getActiveTabData?.path;
				const subObjAction = getters.getFileApiLabelTypeFromPath(path);
				if (subObjAction?.name) {
					return methods[subObjAction.name];
				}
			};

			if (pendingMethods) {
				return getMethod(pendingMethods);
			} else if (currentMethods) {
				return getMethod(currentMethods);
			}
			return null;
		},

		getTabInfoFromId: (state, getters) => (id) => {
			return getters.getCorrectedTabs.find((tab) => tab.id === id);
		},
		getCurrentEditorUndoAvailable: (state, getters) => {
			const edVersionId =
				getters.getEditorViewPathAndSub()?.editorVersion?.current || 1;
			return (
				edVersionId > getters.getEditorViewPathAndSub()?.editorVersion?.min
			);
		},
		getCurrentEditorRedoAvailable: (state, getters) => {
			const edVersionId =
				getters.getEditorViewPathAndSub()?.editorVersion?.current || 1;
			return (
				edVersionId < getters.getEditorViewPathAndSub()?.editorVersion?.max
			);
		},
		// Path getters
		getFileInfoFromPath: (state, getters) => (path) => {
			return getters.getTabInfoFromPath(path).file || {};
		},
		getFileApiLabelTypeFromPath: (state, getters) => (path) => {
			const tab = getters.getTabInfoFromPath(path);
			const type = eh.getFileLang(state, tab.id)?.type;
			const lang = eh.getFileLang(state, tab.id)?.options;

			const name = tab.options?.instaSelection?.[type] || "__default";
			return {
				name,
				type,
				lang,
			};
		},
		getTabIndexFromFilePath: (state, getters) => (path) => {
			const fIndex = getters.getCorrectedTabs.findIndex(
				(tab) => tab.path === path,
			);
			if (fIndex !== -1) {
				return fIndex;
			}
			return null;
		},
		getTabInfoFromPath: (state, getters) => (path) => {
			const fIndex = getters.getTabIndexFromFilePath(path);
			return fIndex === null ? {} : getters.getCorrectedTabs[fIndex];
		},
		getEnabledFileSubKeys: (state, getters) => (path) => {
			const edInstances = getters.getTabInfoFromPath(path)?.__editor;
			if (edInstances) {
				return Object.entries(edInstances).reduce(
					(prev, [currKey, currVal]) => {
						if (currVal?.monacoModel?.().getValueLength()) {
							prev.push(currKey);
						}
						return prev;
					},
					[],
				);
			}
			return [];
		},
		isFileDownloadedPath: (state, getters) => (path) => {
			const foundTab = getters.getCorrectedTabs.find(
				(tab) => tab.path === path,
			);
			return Boolean(foundTab?.__file_downloaded);
		},
		isFilePathDirty: (state, getters) => (path) => {
			const tab = getters.getTabInfoFromPath(path);
			return eh.getFileDirtyState(tab) || false;
		},

		// Oct 2021
		// getFileDirtyStates: (state, getters) => {
		//   return getters.getCorrectedTabs.map(tab => {
		//     const isWholeFileDirty =

		//     return {
		//       path: tab.path,
		//       isWholeFileDirty,
		//       sub: {

		//       }
		//     }
		//   })
		// },

		getAllDirtyFilePaths: (state, getters) => {
			return getters.getCorrectedTabs.reduce((prev, tabObj) => {
				const isObjDirty = eh.getFileDirtyState(tabObj);
				if (isObjDirty) {
					prev.push(tabObj.path);
				}
				return prev;
			}, []);
		},
		getDirtySubActionsByPath: (state, getters) => (path) => {
			// Works with model subs and when manually set
			const tab = getters.getTabInfoFromPath(path);

			const checkDirtySubsManual = () => {
				const tabOpt = tab.options;
				return tabOpt?.dirtySubcategoriesManual || [];
			};
			const checkDirtyModels = () => {
				const tabOpt = tab.__editor;
				try {
					return Object.entries(tabOpt)
						.filter(
							([, eapiV]) =>
								eapiV.editorVersion.current !== eapiV.editorVersion.saved,
						)
						.map(([eapiK]) => eapiK);
				} catch {
					// ignored
				}
				return [];
			};

			const manualSubs = checkDirtySubsManual();
			const modelSubs = checkDirtyModels();
			return Array.from(new Set([...manualSubs, ...modelSubs]));
		},

		// #region Editor getters
		getFullFileDataWithVersionForPath: (state, getters) => (path) => {
			// ** Alternative: Should be a string {complete file}
			const fileTab =
				Array.isArray(state.tabs) &&
				state.tabs.find((tab) => tab.path === path);
			if (fileTab?.__editor) {
				const allSubModels = Object.entries(fileTab.__editor).map(
					([key, val]) => [
						key,
						{
							isCheckpoint:
								val.editorVersion.current === val.editorVersion.saved,
							editorVersion: val.editorVersion,
							value: val.monacoModel?.()?.getValue(),
						},
					],
				);
				return Object.fromEntries(allSubModels);
			} else {
				// [Ignored] File doesn't exist [Probably not downloaded | loaded]
				// console.log("File doesn't exist", path, fileTab);
			}
			return null;
		},

		getMonacoFileDataFromID: (state, getters) => (tabId, isSubPage) => {
			const getWholeFileEditorViewByID = (tabId) => {
				const tabUsedId = tabId || state.activeTabID;
				return getters.getTabInfoFromId(tabUsedId)?.__editor;
			};

			// File data
			let cmv = null;
			if (tabId) {
				cmv = getWholeFileEditorViewByID(tabId);
			} else {
				console.warn("Can't get ed-model");
				return null;
			}

			if (cmv && typeof cmv === "object") {
				if (isSubPage) {
					console.log("Unused [NI]");
					// return cmv?.monacoModel?.()?.getValue();
				}

				const monView = getters.getTabInfoFromId(tabId)?.__editor;

				// If file is string, that means it shouldn't copy the current data
				const tempSubObj = { ...monView };

				for (const [key, view] of Object.entries(cmv)) {
					const value = view?.monacoModel?.()?.getValue();
					if (value) {
						tempSubObj[key] = value;
					} else {
						// Remove from sending file API
						delete tempSubObj[key];
					}
				}

				// console.warn('Saving', tempSubObj);
				return tempSubObj;
			}

			return cmv?.monacoModel?.()?.getValue();
		},

		getEditorViewPathAndSub: (state, getters) => (path, subKey) => {
			// Gets editor view {...,model:{}} with support for subkeys
			const getEditorView = (tab, subKey) => {
				const editorModels = tab.__editor;
				const view = subKey ? editorModels?.[subKey] || {} : editorModels;
				return view;
			};

			if (path) {
				const tab = getters.getTabInfoFromPath(path);
				return getEditorView(tab, subKey);
			} else {
				const tabUsedId = state.activeTabID;
				const tab = getters.getTabInfoFromId(tabUsedId);
				if (tab?.path) {
					const genSubKey =
						getters.getFileApiLabelTypeFromPath(tab.path)?.name || subKey;
					return getEditorView(tab, genSubKey);
				}
				return null;
			}
		},
		getEditorViewIterable: (state, getters) => (path) => {
			const editorView = getters.getEditorViewPathAndSub(path);
			if (editorView?.monacoModel) {
				return [editorView];
			} else if (Object.keys(editorView).length) {
				return Object.values(editorView);
			}
			return null;
		},
		// #endregion Editor getters

		methodList: () => {
			return methodList;
		},
		eventList: () => {
			return eventList;
		},
	},
	mutations: {
		TOGGLE_EDITOR_LINTER(state) {
			state.editorLinterState = !state.editorLinterState;
		},
		CLEAR_EDITOR_DATA(state, payload) {
			state.tabs = [];
			state.tabCo = 1;
			state.activeTabID = null;
			state.isEditorPreview = false;
			state.isMultiPreview = true;
			state.editorFileApiSidebarTab = "";
			state.tabPathHistoryStack = [];
		},
		SET_AUTOSAVE_INTERVAL(state, path) {
			if (path) {
				if (state.autosaveFilepaths[path]) {
					Vue.delete(state.autosaveFilepaths, path);
				} else {
					Vue.set(state.autosaveFilepaths, path, true);
				}
			} else {
				state.autosaveFilepaths = {};
			}
		},
		SET_TABS_TO_STORE(state, tabs) {
			state.tabs = tabs;
		},
		ADD_TABS_TO_STORE(state, tabs) {
			for (const tab of tabs) {
				const tabWithId = { ...tab, id: state.tabCo };
				state.tabs.push(tabWithId);
				state.tabCo++;
			}
		},
		CREATE_SUBS_MODEL_DATA(state, payload) {
			const payloadForMonFunc = {
				lang: payload.lang,
				pth_type: payload.type,
				dt_data: {
					[payload.subPage]: payload.data,
				},
			};
			Vue.set(payload.tab, "__editor", {
				...payload.tab.__editor,
				...generateEditorInstanceView(payloadForMonFunc, payload.editorVersion),
			});
			Vue.set(payload.tab, "__file_downloaded", true); // This is a fail-safe
		},
		UPDATE_SAVED_FROM_CURRENT(state, payload) {
			const allSubs = Object.entries(payload);
			for (const [subName, subVal] of allSubs) {
				Vue.set(subVal.editorVersion, "saved", subVal.editorVersion.current);
			}
		},
		UPDATE_SUBS_MODEL_DATA(state, payload) {
			Vue.set(payload.tab, "__editor", payload.data);
			Vue.set(payload.tab, "__file_downloaded", true); // This is a fail-safe
		},
		UPDATE_FILE_FROM_ID(state, payload) {
			const { id, item } = payload;
			const openFileIndex = state.tabs.findIndex((el) => el.id === id);
			if (openFileIndex === -1) {
				console.error("[Update tab file] TAB NOT FOUND", id);
			} else {
				// Existing item
				const openFileInTab = state.tabs[openFileIndex];
				const mergedObj = { ...openFileInTab.file, ...item };
				Vue.set(state.tabs[openFileIndex], "file", mergedObj);
				Vue.set(state.tabs[openFileIndex], "path", item.fullPath);
			}
		},
		SWITCH_TO_TAB_FROM_PATH(state, filePath) {
			const openFileIndex = state.tabs.findIndex(
				(tab) => tab.path === filePath,
			);
			const openFileInTab = state.tabs[openFileIndex];
			if (openFileInTab) {
				state.activeTabID = openFileInTab.id;
			}
		},
		MANAGE_MONACO_STATE(state, { tab, subAct, payload }) {
			if (tab?.__editor) {
				if (subAct) {
					Vue.set(tab.__editor, subAct, payload);
				} else {
					Vue.set(tab.__editor, "__default", payload);
				}
			}
		},
		UPDATE_MONACO_SAVED_STATE_VER(state, tabEditor) {
			// Finalizes the save action and makes file clean (not dirty)
			const checkAndUpdateModelVersion = (edView) => {
				const currentVer = edView.editorVersion?.current || 1;
				const maxVer = Math.max(edView.editorVersion.max, currentVer) || 1;

				// Vue.set(edView.editorVersion, "current", currentVer);
				Vue.set(edView.editorVersion, "max", maxVer);
				Vue.set(edView.editorVersion, "saved", currentVer);
			};

			const monApiObj = tabEditor;
			for (const subActionObj of Object.values(monApiObj)) {
				checkAndUpdateModelVersion(subActionObj);
			}
		},

		UPDATE_ACTIVE_TAB_ID(state, idOrNull) {
			state.activeTabID = idOrNull;
		},
		UPDATE_TABS_FROM_REMOVE_TAB(state, updatedTabsArr) {
			// Used to update tabs when tab is closed
			state.tabs = updatedTabsArr;
		},
		UPDATE_TABS_FROM_REMOVE_OTHER(state, updatedTabsArr) {
			// Similar mutation - Used for tracking changes
			state.tabs = updatedTabsArr;
		},
		REMOVE_FILES_RIGHT(state, fIndex) {
			state.tabs = state.tabs.slice(0, fIndex + 1);
		},
		RESET_ALL_TAB_FILES(state) {
			state.activeTabID = null;
			state.tabs = [];
		},

		SET_ACTIVE_TAB(state, id) {
			const foundTab = state.tabs.some((tab) => tab.id === id);
			if (foundTab) {
				state.activeTabID = id;
			} else {
				console.warn("Tab ID not found, aborted", id);
			}
		},
		SET_ACTIVE_TAB_FROM_PATH(state, id) {
			state.activeTabID = id;
		},
		UPDATE_TAB_ARRAY(state, payload) {
			state.tabs = payload;
		},
		UPDATE_TAB_PINNED_STATUS(state, payload = []) {
			for (const tab of payload) {
				const index = state.tabs.findIndex(
					(storeTab) => storeTab.id === tab.id,
				);
				Vue.set(state.tabs[index], "__isPinned", tab.isPinned);
			}
		},

		CHANGE_DIRTY_STATE_FILE(state, { tabOptions, isDirty, isForce }) {
			// Manually trigger dirty state [Works for any file path]
			if (isForce) {
				// For when file language changes
				Vue.set(tabOptions, "dirtyFileManual", isDirty);
			}
			Vue.set(tabOptions, "dirty", isDirty);
		},
		UPDATE_DIRTY_STATE(state, payload) {
			const { tabOpt, method } = payload;
			if (method) {
				if (
					Array.isArray(tabOpt.dirtySubcategoriesManual) &&
					tabOpt.dirtySubcategoriesManual
				) {
					tabOpt.dirtySubcategoriesManual.push(method);
				} else {
					Vue.set(tabOpt, "dirtySubcategoriesManual", [method]);
				}
			}

			Vue.set(tabOpt, "dirty", true);
		},
		REMOVE_DIRTY_STATE(state, payload) {
			const { tabOpt, method } = payload;

			if (method) {
				const newDirtySubs = tabOpt.dirtySubcategoriesManual?.filter(
					(sub) => sub !== method,
				);
				Vue.set(tabOpt, "dirtySubcategoriesManual", newDirtySubs || []);
			} else {
				Vue.delete(tabOpt, "dirtySubcategoriesManual");
			}

			const hasSetVals = Boolean(tabOpt.dirtySubcategoriesManual.length);
			Vue.set(tabOpt, "dirty", hasSetVals);
		},
		CHANGE_DIRTY_STATE_BY_FILE_PATH(state, payload) {
			const { fullPath, isDirty } = payload;

			const getTabObjectFromFullPath = (path) => {
				return state.tabs.find((tab) => tab.path === fullPath) || {};
			};
			const tabOptions = getTabObjectFromFullPath(fullPath).options;
			if (tabOptions) {
				Vue.set(tabOptions, "dirty", isDirty);
				if (isDirty === false) {
					Vue.delete(tabOptions, "dirtySubcategoriesManual");
					Vue.delete(tabOptions, "dirtyFileManual");
				}
			} else {
				console.warn("No tab options found", fullPath);
			}
		},
		CHANGE_FILE_LANG(state, payload) {
			const { prevLangType, newLangType } = payload;
			// Have to change editor instance keys [For saving and for displaying instances properly]
			// This is used when saving a new file type [merging tab file object]
			const tabOptions = eh.getTabActiveObj(state).options;

			if (prevLangType.type !== "BIN") {
				const previousSelectedInstanceKey =
					tabOptions.instaSelection?.[prevLangType.type];
				const newSelectedInstanceKey =
					tabOptions.instaSelection?.[newLangType.type];
				const editorInstances = eh.getTabActiveObj(state).__editor;
				const defaultSub = {
					EAP: "GET",
					API: "GET",
					WSS: "onconnect",
					TXT: "__default",
				};
				const newInstanceKey =
					newSelectedInstanceKey || defaultSub[newLangType.type] || "__default";

				// Check-Clone Instance before switching to it for new type if not already exists [ Selection / Instance ]
				if (
					!newSelectedInstanceKey ||
					!editorInstances[newSelectedInstanceKey]
				) {
					if (newLangType.type === "BIN") {
						console.log(">> Ignoring BIN file");
					} else {
						Vue.set(
							editorInstances,
							[newInstanceKey],
							editorInstances[previousSelectedInstanceKey],
						);
					}
				}

				// Change language so it matches the new file type
				const mergedFileObj = {
					...eh.getTabActiveObj(state).file,
					...newLangType,
				};
				const newModelLang = filemanager.getMonacoLang(mergedFileObj);
				monacoEditor.editor.setModelLanguage(
					editorInstances[newInstanceKey].monacoModel?.(),
					newModelLang,
				);

				// console.warn('Creating instance', previousSelectedInstanceKey, newInstanceKey, payload, editorInstances);
			}

			// Change file lang
			// Payload must be formatted prior to saving
			eh.checkAndCreateObj(tabOptions, "pending");
			const mergedPending = merge({}, tabOptions.pending, newLangType);
			Vue.set(tabOptions, "pending", mergedPending);
		},
		SAVE_FILE_OPT_PARAMS(state, formattedPayload) {
			const tab = eh.getTabActiveObj(state);
			eh.checkAndCreateObj(tab, "options");
			eh.checkAndCreateObj(tab.options, "pending");
			eh.checkAndCreateObj(tab.options.pending, "options");
			try {
				if (tab.options?.pending?.options) {
					let parsedOpt = tab.options.pending.options;
					if (parsedOpt && typeof parsedOpt === "string") {
						parsedOpt = JSON.parse(parsedOpt);
					}

					parsedOpt = { ...parsedOpt, ...formattedPayload };
					// Vue.set(tabOptions, "options", JSON.stringify(parsedOpt));
					Vue.set(tab.options.pending.options, "methods", parsedOpt.methods);
				} else {
					// Vue.set(tabOptions, "options", JSON.stringify(formattedPayload));
					Vue.set(
						tab.options.pending.options,
						"methods",
						formattedPayload.methods,
					);
				}
			} catch (err) {
				console.warn(err.message);
			}
		},

		TOGGLE_EDITOR_PREVIEW(state, payload) {
			state.isEditorPreview = payload;
		},
		TOGGLE_MULTI_PREVIEW(state, payload) {
			state.isMultiPreview = payload;
		},
		STORE_FILE_OTHER_TABS(state, payload) {
			state.editorFileApiSidebarTab = payload;
		},

		ADD_TAB_TO_HISTORY(state, filePath) {
			state.tabPathHistoryStack.push(filePath);
		},
		REMOVE_TAB_FROM_HISTORY(state, filePath) {
			if (filePath) {
				state.tabPathHistoryStack = state.tabPathHistoryStack.filter(
					(path) => path !== filePath,
				);
			} else {
				// Clear tab history
				console.log("Clearing whole tab history...");
				state.tabPathHistoryStack = [];
			}
		},
		CHANGE_FILE_INSTANCE_SELECTION(state, payload) {
			eh.checkAndCreateObj(payload.tabObj, "instaSelection");
			Vue.set(payload.tabObj.instaSelection, payload.key, payload.value);
		},
	},
	actions: {
		toggleEditorLinter({ commit }) {
			commit("TOGGLE_EDITOR_LINTER");
		},
		setAutosaveFilepath({ state, commit }, path) {
			commit("SET_AUTOSAVE_INTERVAL", path);
			return state.autosaveFilepaths;
		},
		getFileLock({ commit }, payload) {
			return api.getFileLock(payload);
		},
		async getFileAndStoreApi({ state, getters, dispatch, commit }, item) {
			const marsFilePath = item?.fullPath;
			if (!marsFilePath) {
				console.warn("[Get file - Download] No file / path", item);
				return false;
			}
			try {
				const res = await dispatch("getFileData", { path: marsFilePath });
				const writeResToStore = (res) => {
					const storeTabObj = {
						// __editor: This has to be set after all data manipulation
						__file_downloaded: true,
						file: item,
						path: marsFilePath,
						options: {
							dirty: false,
							// isDirty: false, // Not used currently
						},
						fileProps: {
							fileVersion: 1,
						},
					};

					let dataObj = eh.formatFileDataResponse(res.data, item.type);

					const setupApiBlock = () => {
						const getFirstAvailableMethodFromData = (data) => {
							const avalKeys = Object.keys(data.dt_data);
							const foundEl = getters.methodList.find((method) =>
								avalKeys.includes(method),
							);
							// Don't set default method here
							// const defaultMethod = getters.methodList[0].name

							return foundEl;
						};

						//  EAP / WSS legacy
						storeTabObj.options = {
							api: getFirstAvailableMethodFromData(dataObj),
						};

						// Setup for websocket legacy compatibility
						const patchData = dataObj.dt_data?.PATCH;
						if (patchData === "WS") {
							// Convert API [EAP] to WS [WSS]
							storeTabObj.file.type = "WSS";
							dataObj = eh.formatWebSocketData(dataObj);
						}
					};

					const setupWssBlock = () => {
						const getFirstAvailableWsEventFromData = (data) => {
							const avalKeys = Object.keys(data.dt_data);
							const foundEl = getters.eventList.find((wsEvent) =>
								avalKeys.includes(wsEvent.name),
							);
							return foundEl?.name || getters.eventList[0].name;
						};

						storeTabObj.options = {
							api: getFirstAvailableWsEventFromData(dataObj),
						};
					};

					// Check file type [ Default when starting ]
					switch (item.type) {
						case "API": {
							// Format legacy API * Untested
							storeTabObj.file.type = "EAP"; // API
							dataObj = eh.formatLegacyApiData(dataObj);
							break;
						}
						case "EAP": {
							setupApiBlock();
							break;
						}
						case "WSS": {
							setupWssBlock();
							break;
						}
						default: {
							break;
						}
					}

					const editorDataObj = {
						...dataObj,
						lang: filemanager.getMonacoLang(item),
					};

					// It will merge with existing model

					const openFileIndex = state.tabs.findIndex(
						(tab) => tab.path === item.file?.fullPath,
					);
					if (openFileIndex?.__editor) {
						// Update current models
						const currentFilePath = getters.getCurrentFileInfo.fullPath;
						const isCurrentFile = currentFilePath === item.file.fullPath;
						const currentSubAct = getters.getFileApiLabelTypeFromPath(
							item.file.fullPath,
						).name;

						// Same file [Skip current file / sub]
						storeTabObj.__editor = isCurrentFile
							? // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
								updateEditorOneInstanceModel(
									openFileIndex.__editor,
									editorDataObj.dt_data,
									currentSubAct,
								)
							: // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
								updateEditorOneInstanceModel(
									openFileIndex.__editor,
									editorDataObj.dt_data,
								);

						console.warn(
							"DEBUG :: Changing editor instance, this should be a bug",
							storeTabObj.__editor,
						);
					} else {
						// Create new models
						storeTabObj.__editor = generateEditorInstanceView(editorDataObj);
					}

					// Object.entries(storeTabObj.__editor).forEach(([key, ed]) => console.warn(key, ed.monacoModel?.().getValue()));
					dispatch("mergeFileStore", storeTabObj);
				};
				writeResToStore(res);
				return true;
			} catch (err) {
				dispatch("fileNotValid", item);
				console.warn("Failed to get file", err.message);
			}
			return false;
		},
		fileNotValid({ state }, payload) {
			console.warn("Item invalid:", payload);
			eventBus.$emit("on-toast-message", ["File couldn't be opened", "error"]);
		},
		async checkDownloadEditorFile({ state, getters, dispatch, commit }, item) {
			// Used to download [BIN] files
			const tabFileOpenIndex = getters.getTabIndexFromFilePath(item.fullPath);
			const tabFile = state.tabs[tabFileOpenIndex];
			if (tabFileOpenIndex === null || !tabFile?.__file_downloaded) {
				// Download the file if doesn't exist
				const isFileFetched = await dispatch("getFileAndStoreApi", item);
				console.warn(">> Check download fetch status:", isFileFetched);
				return true;
			}
			// File already downloaded
			return false;
		},
		async getFileData(state, { id, path }) {
			const payload = {};
			if (path) {
				payload.path = path;
			} else {
				payload.id = id;
			}
			const response = await api.getFile(payload);
			return response;
		},
		async getFileMetaInfo(state, { id, path }) {
			const payload = {};
			if (!id && !path) {
				console.error("You must provide id or path", id, pathf);
				return;
			}

			if (path) {
				payload.path = path;
			} else {
				payload.id = id;
			}
			const res = await api.getFileMetaInfo(payload);
			const dataObj = res.data.data;
			if (!dataObj) {
				return {};
			}

			const splitPath = dataObj.pth_path.split("/");
			const name = splitPath[splitPath.length - 1];
			const transformedObj = {
				id: dataObj.pth_id,
				created: dataObj.pth_created,
				fullPath: dataObj.pth_path,
				name,
				type: dataObj.pth_type,
				options: dataObj.pth_options,
				errors: 0, // API doesn't send
				usr_name: dataObj.usr_name,
				children: [], // API doesn't send
			};
			return transformedObj;
		},
		async createFileState({ dispatch }, payload) {
			const payloadData = payload.data;
			const cancelToken = payload.cancelToken;
			const data = "";
			const file = {
				fullPath: payloadData.changedPath,
				options: {
					language: "",
				},
				type: payloadData.type || "EAP",
			};

			const scriptTypes = ["EAP", "WSS", "APL", "WSL", "LIB"];
			if (scriptTypes.includes(file.type)) {
				file.options.language = payloadData.language || "JavaScript";
			}

			return eh.createFileHelper(data, file, cancelToken, dispatch);
		},
		async createFolder({ commit }, payload) {
			const params = {
				data: {
					fileArray: [
						{
							filedata: "",
							path: payload.changedName,
							type: "FLD",
						},
					],
				},
				cancelToken: payload.cancelToken,
			};

			return api.postFile(params);
		},
		async saveFileActivePath({ state, getters, dispatch, commit }, params) {
			const { path, cancelToken } = params;
			const fileData = getters.getTabInfoFromPath(path);
			const tabId = fileData.id;
			let edData = getters.getMonacoFileDataFromID(tabId);

			// Filter by file type [Not directly on instances]
			// edData is always an object
			const fileLangObj = getters.getFileApiLabelTypeFromPath(path);
			if (fileLangObj.type && fileLangObj.type !== "TXT") {
				const getValidSubs = SubKeysPerType[fileLangObj.type];
				if (Array.isArray(getValidSubs) && getValidSubs.length) {
					const predicate = ([key, val]) => getValidSubs.includes(key);
					edData = Object.fromEntries(Object.entries(edData).filter(predicate));
				} else {
					// Handle [__default subpage - plain instance - returns string]
					edData = edData.__default;
				}
			} else {
				// Default fallback
				edData = edData.__default;
			}

			const marsFileMeta = fileData.file;
			const pendingFile = fileData.options?.pending;
			const getInstance = (item) => {
				const MarsFileMeta = filemanager.MarsFileMeta;
				return item instanceof MarsFileMeta
					? item
					: new filemanager.MarsFileMeta(item);
			};

			const marsFileInstance = getInstance(marsFileMeta);

			if (pendingFile) {
				if (marsFileInstance && "mergeMarsFile" in marsFileInstance) {
					marsFileInstance.mergeMarsFile(pendingFile);
				} else {
					infoConsole("[X] - Error saving file 1", "error", fileData);
				}
			}

			// This will update the file with the new file type
			const payload = {
				edData: edData || "",
				marsFileMeta: marsFileInstance,
				tabId,
				cancelToken,
				store: {
					dispatch,
					commit,
				},
			};

			// API POST
			const responseOrError = await eh.modifyFile(payload);

			// Check and force refresh iframe (timeout - browser caching)
			setTimeout(() => {
				// Timeout because of browser caching iframe
				if (state.isEditorPreview) {
					eventBus.$emit("trigger-refresh-frame", () => this.setupRandLink());
				}
			}, 400);

			mergeFileTree(responseOrError, marsFileMeta);

			return responseOrError;
		},
		async createEmptyEditorInstance({ state, getters, commit }, payload) {
			// Creates an empty instance
			const tab = state.tabs.find((tab) => tab.path === payload.path);
			if (tab) {
				const payloadMutation = {
					tab,
					subPage: payload.subPage,
					lang: payload.lang,
					type: payload.type,
					data: "",
					editorVersion: payload.editorVersion,
				};
				commit("CREATE_SUBS_MODEL_DATA", payloadMutation);
				return tab.__editor[payload.subPage];
			}
			return null;
		},
		syncCurrentFileSavedToCurrent({ state, commit }, tabId) {
			// WIP [2] Check this and "updateEditorSavedStateVersion" function to make it one
			const foundTab = state.tabs.find((tab) => tab.id === tabId);
			if (foundTab) {
				const editorObj = foundTab?.__editor;
				if (editorObj) {
					commit("UPDATE_SAVED_FROM_CURRENT", editorObj);
				} else {
					console.warn("[Sync editor saved] No editor object found");
				}
			} else {
				console.warn("[Sync editor saved] No tab found");
			}
		},
		async updateAllEditorInstances(
			{ state, getters, dispatch, commit },
			payload,
		) {
			// Updates editors instances { whole data } and / or their versions
			// ** payload > { path, lang, isManualFileDirty, instances: { ... } }
			// ** [get]: { editorVersion, value, state, ... }
			// ** model is generated on the fly, "lang" is for creation
			const tabInfo = getters.getTabInfoFromPath(payload.path);
			let isAllNewInstances = false;
			if (tabInfo) {
				const updateInstances = async () => {
					const createdInstancesKeys = [];
					const updatedInstances = Object.fromEntries(
						Object.entries(payload.instances).map(([instanceK, instanceV]) => {
							const oldInstance = tabInfo.__editor?.[instanceK];
							if (oldInstance) {
								// Update
								let editorVersion = { ...oldInstance.editorVersion };
								if (instanceV.editorVersion) {
									// Update editor version
									editorVersion = {
										...editorVersion,
										...instanceV.editorVersion,
									};
									editorVersion.max = Math.max(
										editorVersion.max,
										editorVersion.current,
									);
								}
								if (instanceV.isCheckpoint === true) {
									// Update [saved to current - for local editor]
									editorVersion.min = Math.min(
										editorVersion.min,
										editorVersion.current,
									);
									editorVersion.max = Math.max(
										editorVersion.max,
										editorVersion.current,
									);
									editorVersion.saved = editorVersion.current;
								}

								const updatedInstance = {
									...oldInstance,
									editorVersion,
									state: instanceV.state,
								};

								if (instanceV.value) {
									// Don't change order of execution
									// Update model and current version from model if any

									// eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
									const newModel = updateEditorOneInstanceModel(
										oldInstance,
										instanceV.value,
									);

									console.warn(
										"DEBUG :: [2] Changing editor instance, this should be a bug",
										newModel,
									);

									updatedInstance.editorVersion.current =
										newModel?.getAlternativeVersionId();
									updatedInstance.monacoModel = () => newModel;
								}

								return [instanceK, updatedInstance];
							} else {
								// Create
								createdInstancesKeys.push(instanceK);

								return [
									instanceK,
									generateEmptyEditorInstanceView(instanceV, payload.lang),
								];
							}
						}),
					);
					// console.log('Updated subs model', updatedInstances);

					isAllNewInstances =
						Object.keys(payload.instances).length ===
						createdInstancesKeys.length;
					if (isAllNewInstances && payload.isManualFileDirty) {
						// Set whole file dirty [without subs - subs are gen. later]
						// One drawback is when the other user undoes the changes, for this user file stays dirty
						const params = {
							isDirty: true,
							path: payload.path,
						};
						console.warn("editor update dirty state 222", params);
						await dispatch("changeFileDirtyState", params);
					}

					return {
						tab: tabInfo,
						data: {
							...tabInfo.__editor,
							...updatedInstances,
						},
						// __file_downloaded: true,  // Testing value because it doesn't get computed
					};
				};

				const allInstances = await updateInstances();
				commit("UPDATE_SUBS_MODEL_DATA", allInstances);
				if (!isAllNewInstances) {
					await dispatch("updateDirtyStateFromProps", tabInfo.id);
				}
				return true;
			}
			console.warn(">>> [Tab update instance] Not found", payload);
			return false;
		},
		setFileSelection({ state, commit }, payload) {
			const tabObj = state.tabs.find(
				(tab) => tab.file.fullPath === payload.path,
			)?.options;
			if (!tabObj) {
				console.error("Tab not found", payload.path, payload);
				return;
			}

			const params = {
				tabObj,
				...payload,
			};

			commit("CHANGE_FILE_INSTANCE_SELECTION", params);
		},
		getFileErrors(store, payload) {
			return api.getFileErrors(payload);
		},
		updateEditorSavedStateVersion({ commit, dispatch, getters }, path) {
			// WIP [2] Check this and "syncCurrentFileSavedToCurrent" function to make it one
			const tabInfo = getters.getTabInfoFromPath(path);
			const tabEditor = tabInfo?.__editor;
			commit("UPDATE_MONACO_SAVED_STATE_VER", tabEditor);
			dispatch("updateDirtyStateFromProps", tabInfo?.id);
		},
		mergeFileStore({ state, commit }, payload = {}) {
			// Check if already exist
			const fullPath = payload.file?.fullPath || payload.file?.path; // Compat
			if (payload.file?.path) {
				console.warn("Using outdated path key, switch to fullPath", payload);
			}

			if (!fullPath) {
				const msg = "File (Full Path) must be provided when updating state";
				console.warn(msg, payload);
				return;
			}

			const openFileIndex = state.tabs.findIndex(
				(el) => el.file?.fullPath === fullPath,
			);
			if (openFileIndex === -1) {
				// Create
				// ID is added in mutation
				const tabs = [
					{
						__editor: {},
						options: {},
						path: fullPath,
						...payload,
					},
				];
				commit("ADD_TABS_TO_STORE", tabs);

				if (!payload?.isPreloadOnly) {
					// It will take & open the last tab that is not preloaded (from multi)
					const previousTab = state.tabCo - 1;
					commit("SET_ACTIVE_TAB", previousTab);
				}
			} else {
				const changedOpenFile = { ...state.tabs[openFileIndex] };
				const mergedArr = [...state.tabs]; // Re-use

				const setupMergeEdModelState = (inCurr, inNew) => {
					const updateModel = (currModel, newModel) => {
						const valueFromNewModel = newModel.getValue();
						currModel.pushEditOperations(
							[],
							[
								{
									range: currModel.getFullModelRange(),
									text: valueFromNewModel,
								},
							],
							() => null,
						);
					};

					const edCurr = inCurr.__editor || {};
					const edNew = inNew.__editor;

					if (edNew) {
						const regenEd = {};
						console.warn("DEBUG :: MERGE FILE STORE ENTRY", edNew);
						// eslint-disable-next-line no-unreachable-loop
						for (const [key, val] of Object.entries(edNew)) {
							console.log("DEBUG :: MERGE FILE METHOD:", key, val);
							regenEd[key] = {
								...edCurr[key],
							};

							const currModel = edCurr[key]?.monacoModel?.();
							if (currModel) {
								// Update current model (To preserve undo stack)
								// eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
								const updatedModel = updateModel(
									currModel,
									val.monacoModel?.(),
								);
								console.log("DEBUG :: UPDATE", key, updatedModel);
								regenEd[key].monacoModel = () => updatedModel;
							} else {
								// Add new model
								console.log("DEBUG :: ADD", key, val);

								regenEd[key].monacoModel = () => val.monacoModel?.();
							}

							return { ...regenEd };
						}
					}
					return edNew;
				};

				const mergedEd = {
					// Add new model subs
					__editor: setupMergeEdModelState(changedOpenFile, payload),
				};

				// << Previously used Object.assign() without recursion
				// Have to use lodash merge because of recursive merge
				const mergedObj = merge(
					{ options: {} },
					changedOpenFile,
					payload,
					mergedEd,
				);

				mergedArr.splice(openFileIndex, 1, mergedObj);

				commit("SET_TABS_TO_STORE", mergedArr);
			}
		},
		async openDefaultFileTemplate({ dispatch }, item = {}) {
			// Used when converting folder to file API <> vice-versa?
			console.log(">> Creating default file template", item);
			const formatFileFormat = () => {
				const params = {
					type: "EAP",
					options: { language: "JavaScript" },
				};
				let res = null;
				if (item && "mergeMarsFile" in item) {
					res = item.mergeMarsFile(params);
				} else {
					console.error("[X] - Error merge file store", params);
				}
				return res;
			};

			const storeTabObj = {
				__file_downloaded: true, // File is empty so no need to download
				path: item.fullPath,
				file: formatFileFormat(),
				fileProps: {
					fileVersion: 1,
				},
			};

			await dispatch("mergeFileStore", storeTabObj); // First time to create
			await dispatch("mergeFileStore", storeTabObj); // Second time to setup editor
		},
		async openDefaultBinTemplate({ dispatch }, item) {
			console.log("🏛 Creating default bin template", item);
			const storeTabObj = {
				path: item.fullPath,
				file: item,
			};

			await dispatch("mergeFileStore", storeTabObj);
		},
		updateFileDataOptions({ getters, commit }, payload) {
			const methodOrEvent = payload.method;
			// fileData = { id, data }
			const { data } = payload.fileData;

			const currentFileOptionsNoLang = getters.getCurrentFileOptionsParsed;
			let obj = { ...currentFileOptionsNoLang };
			if (obj.methods) {
				// Re-use
				obj.methods[methodOrEvent] = data;
			} else {
				// Create methods
				obj = {
					methods: {
						[methodOrEvent]: data,
					},
				};
			}

			// console.warn(
			//   `Updated data >>> `,
			//   `iD: ${payload.fileData.id}`,
			//   data,
			//   methodOrEvent
			// );
			commit("SAVE_FILE_OPT_PARAMS", obj);
		},
		clearEditorData({ commit }, payload) {
			commit("CLEAR_EDITOR_DATA", payload);
		},
		setSelectedItemStore({ state, getters, dispatch, commit }, payload) {
			// Returns: Created tab [true] | Switched tab [false]
			const { opt } = payload;
			const file = payload.file;

			const fileInTab = getters.getTabInfoFromPath(file.fullPath);
			if (fileInTab?.path || fileInTab?.id) {
				if (
					fileInTab.path === file.fullPath &&
					fileInTab.id === state.activeTabID
				) {
					// If in tabs and is active [Ignore]
					// console.log('Same file - no tab switch');
				} else {
					commit("SWITCH_TO_TAB_FROM_PATH", file.fullPath);
				}
				return false;
			} else {
				// Open file [tab list]
				dispatch("removeTabFromHistory", [file.fullPath]);
				dispatch("mergeFileStore", {
					file,
					path: file.fullPath,
					isPreloadOnly: opt?.isPreloadOnly,
				});
			}
			return true;
		},
		updateTabItemStoreFromID({ commit }, payload) {
			commit("UPDATE_FILE_FROM_ID", payload);
		},
		updateEditorInstance({ state, commit, getters, dispatch }, params) {
			const { path, payload, subKey } = params;
			// Updates monaco state and model
			const tab = getters.getTabInfoFromPath(path);
			if (tab?.id) {
				// [Later] Add a function to get editor instance by path and /subkey
				const editorView = path ? getters.getEditorViewPathAndSub() || {} : {};

				// Create active ID monaco model
				const newPayload = {
					...editorView,
					...payload,
				};

				const apiObj = getters.getFileApiLabelTypeFromPath(tab?.file?.fullPath);
				commit("MANAGE_MONACO_STATE", {
					tab,
					subAct: apiObj?.name,
					payload: newPayload,
				});
				dispatch("updateDirtyStateFromProps", tab.id);
			} else {
				console.warn(
					"[Tab Saving Dirty State] - Failed no tab found [id]",
					params,
				);
			}
		},
		updateDirtyStateFromProps({ state, dispatch }, tabId) {
			if (tabId) {
				const foundTab = state.tabs.find((tab) => tab.id === tabId);
				if (foundTab) {
					// console.warn(
					//   ">> Sync file dirty state with local state [id] [path]: ",
					//   tabId,
					//   foundTab.path
					// );
					// Read dirty from editor model [no subs] & [subs]
					// Read dirty from manual set [subs]
					// Set to { options: { isDirty: (Boolean) } }

					const checkModelSubs = () => {
						try {
							return Object.values(foundTab.__editor).some(
								(eapi) =>
									eapi.editorVersion.saved !== eapi.editorVersion.current,
							);
						} catch {
							// ignored
						}
						return false;
					};
					const checkManualSubs = () => {
						try {
							return Boolean(foundTab.options.dirtySubcategoriesManual.length); // ARRAY
							// return Boolean(foundTab.options.dirtySubcategoriesManual.size); // SET
						} catch {
							// ignored
						}
						return false;
					};
					const checkManualWholeFile = () => {
						return Boolean(foundTab.options?.dirtyFileManual);
					};

					const isAnyModelDirtySub = checkModelSubs();
					const isAnyManualDirtySub = checkManualSubs();
					const isAnyManualDirtyWholeFile = checkManualWholeFile();
					const isAnyDirty =
						isAnyModelDirtySub ||
						isAnyManualDirtySub ||
						isAnyManualDirtyWholeFile;
					const payload = {
						isDirty: isAnyDirty,
						path: foundTab.path,
					};
					// console.warn(">> editor update 111", payload);
					dispatch("changeFileDirtyState", payload);
				} else {
					console.log("[Tab not found]", state.tabs.length, state.tabs);
				}
			} else {
				console.error("[No TABID]");
			}
		},

		updateTabsInViewDatabase() {
			eventBus.$emit("set-user-editor-tab-config");
		},
		removeTabFilePath({ state, getters, dispatch }, path) {
			const foundTab = state.tabs.find((tab) => tab.path === path);
			if (foundTab?.id) {
				dispatch("removeTabFileID", foundTab.id);
				dispatch("updateTabsInViewDatabase");
			} else {
				console.error("Invalid tab", path, foundTab);
			}
		},
		removeTabFileID({ state, getters, dispatch, commit }, id) {
			if (id) {
				const fIndex = state.tabs.findIndex((tab) => tab.id === id);
				if (fIndex === -1) {
					// If closed too fast, or misbehaved
					console.log("[WARN] Tab is not opened", id);

					return;
				}

				const removedTabPath = state.tabs[fIndex].path;
				const tempTabs = [...state.tabs];
				tempTabs.splice(fIndex, 1);

				dispatch("addTabsToHistory", [removedTabPath]);

				if (id === state.activeTabID) {
					// Change active tab if tab closed
					if (fIndex > 0) {
						commit("UPDATE_ACTIVE_TAB_ID", tempTabs[fIndex - 1].id);
					} else if (fIndex === 0 && tempTabs.length !== 0) {
						commit("UPDATE_ACTIVE_TAB_ID", tempTabs[fIndex].id);
					} else {
						commit("UPDATE_ACTIVE_TAB_ID", null);
					}
				}

				commit("UPDATE_TABS_FROM_REMOVE_TAB", tempTabs);
				dispatch("updateTabsInViewDatabase");
			} else {
				console.log("[WARN] No tab id:", id);
			}
		},
		removeTabFilesOther({ state, getters, commit, dispatch }, id) {
			const tabsPathsToClose = state.tabs
				.filter((tab) => tab.id !== id)
				.map((tab) => tab.path);
			const tabToKeepArr = state.tabs.filter((tab) => tab.id === id);
			dispatch("addTabsToHistory", tabsPathsToClose);

			commit("UPDATE_ACTIVE_TAB_ID", id); // If closing from inactive tab
			commit("UPDATE_TABS_FROM_REMOVE_OTHER", tabToKeepArr);
			dispatch("updateTabsInViewDatabase");
		},
		removeTabFilesFromRight({ state, getters, commit, dispatch }, id) {
			const fIndex = state.tabs.findIndex((el) => el.id === id);
			const activeTabIndex = state.tabs.findIndex(
				(el) => el.id === state.activeTabID,
			);

			if (activeTabIndex > fIndex) {
				commit("UPDATE_ACTIVE_TAB_ID", state.tabs[fIndex].id);
			}

			const tabsPathsToClose = state.tabs
				.slice(fIndex + 1)
				.map((tab) => tab.path);
			dispatch("addTabsToHistory", tabsPathsToClose);
			commit("REMOVE_FILES_RIGHT", fIndex);
			dispatch("updateTabsInViewDatabase");
		},
		removeTabFilesSaved({ state, getters, commit, dispatch }) {
			for (const tab of state.tabs) {
				if (!tab.options || (tab.options && tab.options.dirty !== true)) {
					dispatch("addTabsToHistory", [tab.path]);
					dispatch("removeTabFileID", tab.id);
					dispatch("updateTabsInViewDatabase");
				}
			}
		},
		resetOpenFiles({ commit, dispatch }) {
			commit("RESET_ALL_TAB_FILES");
			dispatch("updateTabsInViewDatabase");
		},

		setEditorActiveTab({ commit }, id) {
			commit("SET_ACTIVE_TAB", id);
		},
		setEditorActiveTabFromPath(
			{ state, getters, commit },
			{ path, goDefaultIfNoFile },
		) {
			const tab = getters.getTabInfoFromPath(path);
			if (tab?.id) {
				commit("SET_ACTIVE_TAB_FROM_PATH", tab.id);
			} else if (goDefaultIfNoFile && state.tabs.length) {
				// Last tab is default one
				commit("SET_ACTIVE_TAB", state.tabs[state.tabs.length - 1].id);
			} else {
				console.log("⛔ No tab selected", path);
			}
		},
		updateDraggableTabs({ commit }, payload) {
			commit("UPDATE_TAB_ARRAY", payload);
		},
		updatePinnedTabs({ commit }, payload) {
			commit("UPDATE_TAB_PINNED_STATUS", payload);
		},

		changeFileDirtyState({ state, getters, commit }, payload) {
			// Setting Dirty State Per File [ Only if different from store ]
			const tabOptions = getters.getTabInfoFromPath(payload.path).options;
			if (payload.isForce || tabOptions.dirty !== payload.isDirty) {
				const params = {
					...payload,
					tabOptions,
				};
				commit("CHANGE_DIRTY_STATE_FILE", params);
			}
		},
		async changeApiDirtyState({ state, getters, dispatch, commit }, payload) {
			// Payload > {isDirty, path}
			if (!payload.path) {
				console.warn("No tab path provided");
				return;
			}

			const tabOptions = getters.getTabInfoFromPath(payload.path).options;
			const fileNameTypeObj = getters.getFileApiLabelTypeFromPath(payload.path);
			const params = {
				tabOpt: tabOptions,
				method: fileNameTypeObj.name,
			};

			const isSubInstance = ["API", "EAP", "WSS"].includes(
				fileNameTypeObj.type,
			);
			if (isSubInstance) {
				if (payload.isDirty) {
					commit("UPDATE_DIRTY_STATE", params);
				} else {
					commit("REMOVE_DIRTY_STATE", params);
				}
			} else {
				// Whole file instance
				console.log(">> editor update 000", payload);
				await dispatch("changeFileDirtyState", payload);
			}
		},
		changeFileLang({ commit }, payload) {
			commit("CHANGE_FILE_LANG", payload);
		},
		toggleEditorPreview({ state, commit }, payload) {
			const value = payload || !state.isEditorPreview;
			commit("TOGGLE_EDITOR_PREVIEW", value);
		},
		toggleMultiPreview({ commit }, payload) {
			commit("TOGGLE_MULTI_PREVIEW", payload);
		},
		storeFileOtherTabData({ commit }, payload) {
			commit("STORE_FILE_OTHER_TABS", payload);
		},

		cycleTab({ state, getters, commit }, payload) {
			const trimmedTabs = state.tabs.map((tab) => tab.id);
			if (trimmedTabs.length > 1) {
				const indexCurrentTab = trimmedTabs.indexOf(state.activeTabID);
				const tempCycleTabs = [...trimmedTabs];
				if (payload === 1) {
					tempCycleTabs.push(tempCycleTabs.shift());
				} else if (payload === -1) {
					tempCycleTabs.unshift(tempCycleTabs.pop());
				} else {
					console.error(">>> [File cycle] Error inc", payload);
				}
				commit("UPDATE_ACTIVE_TAB_ID", tempCycleTabs[indexCurrentTab]);
			}
		},

		// Tab history
		addTabsToHistory({ state, commit }, paths = []) {
			const filterValidPaths = paths.map((path) => path);
			if (filterValidPaths.length) {
				for (const path of filterValidPaths) {
					if (state.tabPathHistoryStack.includes(path)) {
						console.log("[WARN] Tab history - duplicated path:", path);
					} else {
						commit("ADD_TAB_TO_HISTORY", path);
					}
				}
			} else {
				console.warn("[WARN] Tab history - invalid list", paths);
			}
		},
		removeTabFromHistory({ state, commit }, paths = []) {
			const filterValidPaths = paths.map((path) => path);
			if (filterValidPaths.length) {
				for (const path of filterValidPaths) {
					if (state.tabPathHistoryStack.includes(path)) {
						commit("REMOVE_TAB_FROM_HISTORY", path);
					} else {
						// Also captures files that are open cleanly
						// console.log('[WARN] Tab history - can\'t remove nonexistent tab: ', path);
					}
				}
			} else {
				console.warn("[WARN] Tab history - invalid list", paths);
			}
		},
	},
};
