<script setup lang="ts">
import BrowserDetect from "@/modules/shared/browser-detect/BrowserDetect.vue";
const isOutdatedBrowser = computed(() => {
	const canFirefox77 = String.prototype.replaceAll; // 2020-6 ; Chrome 85
	// const canChrome87 = CSS.supports('inset'); // 2020-11 ; Firefox 66
	return !canFirefox77;
});
const options = reactive({
	wrapper: {
		style: {
			background: "rgba(0, 0, 0, 0.7)",
		},
	},
});
</script>

<template lang="pug">
browser-detect(v-if="isOutdatedBrowser", :options="options")
</template>

<style lang="scss" scoped></style>
