import api from "@/api";

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		getTickets: (store, data) => api.getTickets(data),
		getTaskInfoData: (store, data) => api.getTaskInfoData(data),
		postTicket: (store, data) => api.postTicket(data),
		patchTicket: (store, data) => api.patchTicket(data),
		getTicketMessages: (store, data) => api.getTicketMessages(data),
		postTicketMessages: (store, data) => api.postTicketMessages(data),
		putTicketMessages: (store, data) => api.putTicketMessages(data),
		getAvailableUsers: (store, data) => api.getAvailableUsers(data),
		getAvailableSites: (store, data) => api.getAvailableSites(data),
	},
};
