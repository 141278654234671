<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		options: Record<string, any>;
	}>(),
	{
		options: () => ({}),
	},
);

const isLoadDelay = ref(false);
const wrapperStyle = computed(() => props.options.wrapper?.style);

onMounted(() => {
	void nextTick(() => {
		isLoadDelay.value = true;
	});
});
</script>

<template lang="pug">
.browser-detect-wrap(:style="wrapperStyle")
	transition(name="scale")
		.content(v-if="isLoadDelay")
			.title You're using an outdated browser, please download newest version of Google Chrome or Mozilla Firefox
			.action
				a.chrome(href="https://www.google.com/chrome/", target="_blank")
					img(src="./chrome.svg")
				a.firefox(
					href="https://www.mozilla.org/en-US/firefox/new/",
					target="_blank"
				)
					img(src="./firefox.svg")
</template>

<style lang="scss" scoped>
.browser-detect-wrap {
	$dark-gray: $dark-gray;
	$button-border-radius: $button-border-radius;
	$text-color: white;

	display: flex;
	background: rgba(0, 0, 0, 0.7); // Fallback
	align-items: center;
	justify-content: center;
	position: fixed;
	inset: 0;
	z-index: 1000;
	color: $text-color;

	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
		background: $dark-gray;
		padding: 20px;
		border-radius: $button-border-radius;
		cursor: default;
		user-select: none;

		.title {
			font-weight: bold;
		}

		.action {
			display: flex;
			gap: 20px;

			img {
				width: 80px;
				height: 80px;
				cursor: pointer;
			}
		}
	}

	.scale-enter-active,
	.scale-leave-active {
		transition: transform 0.2s ease;
	}

	.scale-enter,
	.scale-enter-from,
	.scale-leave-to {
		transform: scale(0.9);
		opacity: 0;
	}
}
</style>
