<template lang="pug">
.user-avatar(:class="{ clickable: clickable }", @click="onClickAvatar")
	img(
		v-if="getUserImage && isImageTryLoad",
		:src="getUserImage",
		@error="onError"
	)
	gravatar-image(v-else, :name="name", :size="size")
</template>

<script>
import { baseURL } from "../../../api/config";
export default {
	props: {
		name: {
			type: String,
			default: "",
		},
		size: {
			type: Number,
			default: 0,
		},
		clickable: {
			type: Boolean,
			default: false,
		},
		userId: {
			type: Number,
			default: 0,
		},
		imageLink: {
			type: String,
			default: "",
		},
	},
	emits: ["click"],
	data() {
		return {
			isImageTryLoad: true,
		};
	},
	computed: {
		getUserImage() {
			try {
				const storageUser = JSON.parse(sessionStorage.user);
				const userId = this.userId || storageUser?.usr_id;
				if (this.imageLink) {
					return this.imageLink;
				} else if (userId) {
					return `${baseURL}/user/${userId}/avatar`;
				}
				return null;
			} catch {
				return null;
			}
		},
	},
	methods: {
		onError(evt) {
			this.isImageTryLoad = false;
		},
		onClickAvatar(evt) {
			this.$emit("click", evt);
		},
	},
};
</script>

<style lang="scss" scoped>
.user-avatar {
	width: 100%;
	height: 100%;

	&.clickable {
		cursor: pointer;
	}

	img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		object-fit: cover;
		object-position: center top;
		user-select: none;
		pointer-events: none;
	}
}
</style>
