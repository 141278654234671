<template lang="pug">
#privacy-policy
	span Privacy policy...
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#privacy-policy {
	display: flex;
}
</style>
