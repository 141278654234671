<template>
	<div class="modal-wrapper">
		<!-- <div class="modal-title">
      Add certificate
    </div> -->
		<div class="section">
			<span class="title">Apply Certificate private key</span>
			<textarea v-model="privateKey" rows="3"></textarea>
		</div>
		<div class="section">
			<span class="title">Certificate</span>
			<textarea
				v-model="certificate"
				rows="3"
				@keyup.enter="addCertificate"
			></textarea>
		</div>
		<div class="section actions">
			<button class="btn cancel inv" @click="closeModernModalCompat">
				Cancel
			</button>
			<button class="btn apply" @click="addCertificate">Add</button>
		</div>
	</div>
</template>

<script>
import api from "@/api";
export default {
	name: "AddCertificate",
	props: ["options"],
	data() {
		return {
			privateKey: "",
			certificate: "",
		};
	},
	methods: {
		addCertificate() {
			const params = {
				certificate: this.certificate,
				private_key: this.privateKey,
				id: this.options.dom.dom_id,
				type: "OWN",
			};
			api.addCertificate(params).then((res) => {
				if (res.data.result === "OK") {
					this.openToastSuccessMsg("Certificate added successfully");
				} else {
					this.openToastErrorMsg("Error adding certificate");
				}
				this.closeModernModalCompat();
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/adminModals";
.modal-wrapper {
	@include admin-modals-styles;
}
.section textarea {
	height: 100px;
	margin-top: 10px;
}
</style>
