<template lang="pug">
.auth-popup
	login-comp(:isPopup="true", :userProp="userData", @logged-in="onLoginFunc")
	.loading-overlay(v-if="isMiniLoad")
		.loader
</template>

<script>
import LoginComp from "../components/LoginComp.vue";
import authMixin from "../authMixin";
import * as projectFunc from "@/modules/project-list/projectFunc.js";
import { mapGetters } from "vuex";
export default {
	components: {
		LoginComp,
	},
	mixins: [authMixin],
	data() {
		return {
			isMiniLoad: false,
		};
	},
	computed: {
		...mapGetters({
			userData: "moduleAuth/userData",
			getProjectInfo: "moduleProject/getProjectInfo",
			getSiteData: "moduleProject/getSiteData",
		}),
	},
	methods: {
		async onLoginFunc(response) {
			const resUserId = response?.res?.user?.usr_id;
			if (resUserId) {
				this.isMiniLoad = true;
				const currentUser = this.userData.usr_id;
				if (resUserId === currentUser) {
					if (this.getSiteData?.sit_id) {
						// Auto enter project >> site
						const payload = {
							site: this.getSiteData.sit_id,
							project: this.getProjectInfo.pro_id,
						};
						await projectFunc.autoEnterSiteFunc(payload);
						this.isMiniLoad = false;
					}

					// Close if only the same users signs in
					this.closeModernModalCompat();
				} else {
					this.isMiniLoad = false;
					console.warn(this.$i18n.t("auth.invalidUser"), response);
					this.showErrMessage(this.$i18n.t("auth.invalidUser"));
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.auth-popup {
	padding: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	justify-content: space-between;
	user-select: none;

	:deep(.form) {
		width: 100%;

		.action {
			margin: 0 auto;
		}
	}

	.title {
		font-size: 1.2em;
	}
}
</style>
