import type * as monacoEditor from "monaco-editor";

const defaultEditorValues: Partial<
	monacoEditor.editor.IEditorOptions &
		monacoEditor.editor.IStandaloneEditorConstructionOptions
> = {
	automaticLayout: true,
	showUnused: true,
	mouseWheelZoom: true,
	wordWrap: "off",
	codeLens: false,
	dragAndDrop: true,
	lineNumbersMinChars: 5,
	contextmenu: false,
	wordBasedSuggestions: false,
	tabCompletion: "onlySnippets",
	quickSuggestions: true,
	readOnly: false,
	scrollBeyondLastLine: false,
	fontSize: 16,
	fontFamily: "Monospace", // Mono not recognized on Win
	fontLigatures: false,
	fontWeight: "400",
	mouseWheelScrollSensitivity: 3,
	hover: {
		enabled: false,
		sticky: false, // Causes editor error display bug
	},
	scrollbar: {
		useShadows: false,
		verticalHasArrows: false,
		horizontalHasArrows: false,
		vertical: "auto",
		horizontal: "auto",
		verticalScrollbarSize: 6,
		horizontalScrollbarSize: 9,
		arrowSize: 6,
	},
	guides: {
		bracketPairs: true,
	},
	bracketPairColorization: {
		enabled: true,
		independentColorPoolPerBracketType: false,
	},
	model: null,
	tabSize: 2,
	matchBrackets: "always",
};

export default {
	namespaced: true,
	state: {
		isEditorReady: false, // Not used yet
		canLoadMonaco: false,
		monacoOptions: JSON.parse(JSON.stringify(defaultEditorValues)),
		isSpinnerBlockActive: false,
	},
	getters: {
		// @ts-expect-error
		currentMonacoKeyOption: (state) => (key) => {
			return state.monacoOptions[key];
		},
	},
	mutations: {
		// @ts-expect-error
		SET_CAN_LOAD_MONACO(state, payload) {
			state.canLoadMonaco = payload;
		},
		// @ts-expect-error
		IS_EDITOR_READY(state, p) {
			state.isEditorReady = p;
		},
		// @ts-expect-error
		SET_MONACO_OPTIONS(state, payload) {
			state.monacoOptions = { ...state.monacoOptions, ...payload };
		},
		// @ts-expect-error
		RESET_MAIN_STATE(state, payload) {
			state.isEditorReady = false; // Not used yet
			state.canLoadMonaco = false;
			state.monacoOptions = JSON.parse(JSON.stringify(defaultEditorValues));
		},
		// @ts-expect-error
		SET_SPINNER_BLOCK(state, payload) {
			state.isSpinnerBlockActive = payload;
		},
	},
	actions: {
		// @ts-expect-error
		setCanLoadMonacoStore({ commit }, payload) {
			commit("SET_CAN_LOAD_MONACO", payload);
		},
		// @ts-expect-error
		isEditorReady: ({ commit }, p) => commit("IS_EDITOR_READY", p),
		// @ts-expect-error
		setMonacoOptions({ commit }, payload) {
			// Temp settings
			// Mostly used when updating profile settings (per-user)
			commit("SET_MONACO_OPTIONS", payload);
		},
		// @ts-expect-error
		resetMainState({ commit }, p) {
			commit("RESET_MAIN_STATE", p);
		},
		// @ts-expect-error
		setLoading({ commit }, data) {
			commit("SET_SPINNER_BLOCK", data);
		},
	},
};
