<template lang="pug">
#blank-page
	.loader(v-if="!isProgress")
</template>

<script>
export default {
	props: {
		isProgress: {
			type: Boolean,
			default: true,
		},
	},
};
</script>

<style lang="scss" scoped>
#blank-page {
	display: flex;
	justify-content: center;
	align-items: center;
	background: black;
}
</style>
