function indexOf(el, prop, arr) {
	return arr.findIndex((element) => element[prop] === el[prop]);
}

function areColumnsChanged(arr1, arr2) {
	for (let el of arr1) {
		if (!arr2.includes(el)) {
			return true;
		}
	}

	for (let el of arr2) {
		if (!arr1.includes(el)) {
			return true;
		}
	}

	return false;
}

const ForeignKey = {
	isModified: (foreignKey, foreignKeysArr) => {
		let fkIndex = indexOf(foreignKey, "constraintName", foreignKeysArr);
		return (
			fkIndex === -1 ||
			ForeignKey.isChanged(foreignKey, foreignKeysArr, fkIndex)
		);
	},

	areColumnsChanged: areColumnsChanged,

	getColumns: (columns) => {
		let result = [];
		for (const column of columns) result.push("`" + column + "`");
		return result.join(", ");
	},

	findForeignKey: (foreignKeysArr, foreignKey) => {
		let index = indexOf(foreignKey, "constraintName", foreignKeysArr);
		return index === -1 ? null : foreignKeysArr[index];
	},

	isChanged: (initialForeignKey, foreignKey) => {
		for (let prop in initialForeignKey) {
			let value = initialForeignKey[prop];
			if (value === null || typeof value !== "object") {
				if (value !== foreignKey[prop]) {
					return true;
				}
			} else {
				// compare if referring or referenced columns had been changed
				if (areColumnsChanged(initialForeignKey.columns, foreignKey.columns)) {
					return true;
				}
				if (
					areColumnsChanged(
						initialForeignKey.referencedColumns,
						foreignKey.referencedColumns,
					)
				) {
					return true;
				}
			}
		}
		return false;
	},
};

export default ForeignKey;
