// 1. Create a folder [group] that has subGroup [name] steps
// 2. Import the file and include it in tour object
// 3. ...
// 4. Profit

import editorFirstApi from "../modules/tour/editor/editorFirstApi";
import editorOne from "../modules/tour/editor/editorOne";
import projectFirstApi from "../modules/tour/project/projectFirstApi";
import projectOne from "../modules/tour/project/projectOne";
import { settingsTransfer } from "../modules/tour/settings/settingsPageTour.js";

const tour = {
	project: {
		intro: projectOne,
		"first-api": projectFirstApi,
	},
	editor: {
		intro: editorOne,
		"first-api": editorFirstApi,
	},
	settings: {
		"settings-transfer": settingsTransfer,
	},
	// Add others below ==
	// import: {},
	// recovery: {},
	// errors: {},
	// database: {},
	// profile: {}
};

export default tour;
