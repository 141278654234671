import api from "@/api";
import userConfigKeys from "@/script/userConfigKeys.js";
import { createStore } from "vuex";
import meta from "../public/metadata.json";
import moduleAuth from "./modules/auth/state";
import moduleContext from "./modules/context-menu/state";
import moduleDatabase from "./modules/database-page/state";
import moduleEditor from "./modules/editor/state";
import moduleErrors from "./modules/errors-page/state";
import moduleFileTree from "./modules/file-tree/state";
import moduleImportExport from "./modules/import-export/state";
import moduleMain from "./modules/main/state";
import moduleProfile from "./modules/profile/state";
import moduleProject from "./modules/project-list/state";
import moduleSettings from "./modules/settings-page";
import modulePopup from "./modules/shared/popup/state";
import moduleToast from "./modules/shared/toast/state";
import moduleSuperAdmin from "./modules/superadmin-panel/state";
import moduleTemplates from "./modules/templates/state";
import moduleTickets from "./modules/tickets/state";

const appMetaVer = meta.build;

const state = {
	whatsNewModal: {
		show: false,
		version: null,
	},
	miniSidebarNavWidth: 0,
	isMobileView: false,
	isMobileMenuShown: false,
	globalWebsocket: null,
	isWebsocketActive: true,
	isSidebarHidden: false,
	enablementModule: {},
	selectedModernModalData: {
		comp: null,
		binds: {},
	},
	selectedSiteDomains: null,
	domainsData: null,
};

const getters = {
	isShowWhatsNewModal: (state) => state.whatsNewModal.show,
	isSameUserSession: (state) => (id) => state.websocketInitData.id === id,
	getProjectDomains: (state) => state.selectedSiteDomains || [],
	getSecureProjectDomains: (state) =>
		(state.domainsData || []).filter((el) => el.is_https),
};

const mutations = {
	CLEAR_STORE_DATA: (state, payload) => (state = defaultState),
	SET_MOB_VIEW: (state, payload) => (state.isMobileView = payload),
	SET_MOB_MENU_VIEW: (state, payload) => (state.isMobileMenuShown = payload),
	SET_MINI_SIDE_WID: (state, payload) => (state.miniSidebarNavWidth = payload),
	SET_GLOBAL_WS: (state, { WsClass, url }) =>
		(state.globalWebsocket = url ? new WsClass(url) : null),
	SET_WHATS_NEW_MODAL_VERSION: (state, payload) =>
		(state.whatsNewModal.version = payload || appMetaVer),
	TOGGLE_WHATS_NEW_MODAL: (state, value) => (state.whatsNewModal.show = value),
	SET_SIDEBAR_STATE: (state, isVisible = true) =>
		(state.isSidebarHidden = isVisible),
	SET_WEBSOCKET_STATE: (state, isActive) =>
		(state.isWebsocketActive = isActive),
	SET_WEBSOCKET_INIT_DATA: (state, payload) =>
		(state.websocketInitData = payload),
	SET_ENABLEMENT_FEATURES: (state, payload) =>
		(state.enablementModule = payload),
	SET_GLOBAL_MODERN_MODAL_DATA: (state, payload) =>
		(state.selectedModernModalData = payload),
	SET_SITE_STATS_DATA: (state, p) => (state.selectedSiteDomains = p),
	SET_DOMAINS_DATA: (state, p) => (state.domainsData = p),
};

const actions = {
	getAllUserConfig: () => api.getAllUserConfig(),
	async getUserConfig(store, key) {
		try {
			const res = await api.getUserConfig(key);
			const parsedVal = JSON.parse(res.data.value);
			return parsedVal;
		} catch (err) {
			console.log("Error parsing JSON - user config ::", err.message);
			return;
		}
	},
	setUserConfig(store, array) {
		// You can use 'mutateUserConfigArray' helper to generate array
		// value has to be a string!
		// Array [{key,value}]
		const params = {
			config: array,
		};
		return api.setUserConfig(params);
	},
	deleteUserConfig(store, array) {
		// Array that is string with commas (,)
		const params = {
			config: array.map((el) => el.key).join(","),
		};
		return api.deleteUserConfig(params);
	},
	async logoutApi(store, sid) {
		// Reset project and editor details
		try {
			store.dispatch("moduleProject/clearProjectData", {}, { root: true });
			store.dispatch("moduleMain/resetMainState", {}, { root: true });
			store.dispatch("moduleAuth/clearUserData", {}, { root: true });
			store.dispatch("moduleEditor/clearEditorData");
			sessionStorage.clear();
			const res = await api.logoutApi(sid);
			return res;
		} catch (err) {
			return err;
		}
	},
	async getDashboardData({ commit }, payload) {
		let res = await api.getDashboardData(payload);
		commit("SET_SITE_STATS_DATA", res.data.data.info.domains);
		return res;
	},
	setDomainAutoSiteOpen({ commit }, payload) {
		commit("SET_DOMAINS_DATA", payload.domain_details);
	},
	setMobileView({ commit }, isMob) {
		commit("SET_MOB_VIEW", isMob);
	},
	changeMobileSidebar({ commit }, isVisible) {
		commit("SET_MOB_MENU_VIEW", isVisible);
	},
	clearProjectData({ commit }, data) {
		commit("CLEAR_STORE_DATA");
	},
	storeMiniSidebarWid({ commit }, data) {
		commit("SET_MINI_SIDE_WID", data);
	},
	setWhatsNewModal({ dispatch, commit }, currentApiVer) {
		console.log("Vers:", appMetaVer, currentApiVer);
		if (!currentApiVer || appMetaVer > currentApiVer) {
			console.log("Synched what's new config", appMetaVer, currentApiVer);
			// Set API if newer than server & run modal
			const saveParam = [
				{
					key: userConfigKeys.global.userWhatsNew,
					value: JSON.stringify(appMetaVer),
				},
			];
			dispatch("setUserConfig", saveParam, { root: true });

			commit("SET_WHATS_NEW_MODAL_VERSION", currentApiVer);
			if (appMetaVer % 2 === 0) {
				commit("TOGGLE_WHATS_NEW_MODAL", true);
			}
		}
	},
	setGlobalWebsocket: ({ commit }, payload) => commit("SET_GLOBAL_WS", payload),
	toggleWhatsNewModal: ({ commit }, value) =>
		commit("TOGGLE_WHATS_NEW_MODAL", value),
	changeSidebarState: ({ commit }, isVisible) =>
		commit("SET_SIDEBAR_STATE", isVisible),
	setWebsocketStatus: ({ commit }, isActive) =>
		commit("SET_WEBSOCKET_STATE", isActive),
	setWebsocketInitialData: ({ commit }, isActive) =>
		commit("SET_WEBSOCKET_INIT_DATA", isActive),
	getEnablements: async ({ commit }, payload) => {
		try {
			const res = await api.getEnablements(payload);
			commit("SET_ENABLEMENT_FEATURES", res.data.feat);
		} catch {
			// ignore
		}
	},
	// Services (Bundles)
	getServicesBundles: ({ c }, payload) => api.getServicesBundles(payload),
	postCreateUserServices: ({ c }, payload) =>
		api.postCreateUserServices(payload),
	deleteUserServices: ({ c }, payload) => api.deleteUserServices(payload),
	postChangeUserServices: ({ c }, payload) =>
		api.postChangeUserServices(payload),
	postApproveUserServices: ({ c }, payload) =>
		api.postApproveUserServices(payload),
	getPaymentOptions: ({ c }, payload) => api.getPaymentOptions(payload),
	// Settings
	putAddRemoveUsers: ({ c }, payload) => api.putAddRemoveUsers(payload),
	closeModernModalCompat({ state, commit }, payload) {
		state.selectedModernModalData.binds?.onCloseModal?.(payload);

		commit("SET_GLOBAL_MODERN_MODAL_DATA", {
			comp: null,
			binds: {},
		});
	},
	getTwoFactor: ({ c }, p) => api.getTwoFactor(p),
	postTwoFactor: ({ c }, p) => api.postTwoFactor(p),
	deleteTwoFactor: ({ c }, p) => api.deleteTwoFactor(p),
	postTwoFactorVerify: ({ c }, p) => api.postTwoFactorVerify(p),
	openModernModalCompat({ commit }, payload) {
		// Compat function from old modal
		if (!payload.data) {
			console.error("Invalid modal config, missing [data]");
			return;
		}

		const parsed = {
			config: {
				...payload.data.config,
				title: payload.data.header || payload.data.options?.header,
			},
			formStyle: payload.data.style,
			options: payload.data.options,
			onCloseModal: (evt) => {
				commit("SET_GLOBAL_MODERN_MODAL_DATA", {
					comp: null,
					binds: {},
				});

				payload.events?.onclose?.(evt);
			},
		};

		const selectedModernModalData = {
			comp: payload.data.comp,
			binds: parsed,
		};

		commit("SET_GLOBAL_MODERN_MODAL_DATA", selectedModernModalData);
	},
	getDbUsers: (store, data) => api.getDbUsers(data),
	postDbUsers: (store, data) => api.postDbUsers(data),
	putDbUsers: (store, data) => api.putDbUsers(data),
	deleteDbUsers: (store, data) => api.deleteDbUsers(data),
};

export default createStore({
	modules: {
		moduleMain,
		modulePopup,
		moduleToast,
		moduleAuth,
		moduleProject,
		moduleProfile,
		moduleErrors,
		moduleEditor,
		moduleFileTree,
		moduleContext,
		moduleTickets,
		moduleSettings,
		moduleDatabase,
		moduleImportExport,
		moduleSuperAdmin,
		moduleTemplates,
	},
	state,
	getters,
	mutations,
	actions,
});

const defaultState = Object.assign({}, state);
