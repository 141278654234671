import Index from "../entities/Index";
import ForeignKey from "../entities/ForeignKey.js";
import MariaDBCommandsFactory from "../commands/MariaDBCommandsFactory";

class Synchronizer {
	/*
        data: {
            commander: @/commander/AbstractCommander,
            oldSchema: @/samples/ProcessableSchema.json,
            newSchema: @/samples/ProcessableSchema.json
        }
    */
	constructor(data) {
		let necessaryProperties = ["commander", "oldSchema", "newSchema"];
		let keys = Object.keys(data);
		if (!necessaryProperties.every((i) => keys.includes(i))) {
			throw `Synchronizer doesn't contains all necessary properties. Necessary properties: ${necessaryProperties}. \nSend keys: ${keys}`;
		}

		this.commander = data.commander;
		this.oldSchema = data.oldSchema;
		this.newSchema = data.newSchema;

		this.commandsFactory = new MariaDBCommandsFactory();
	}

	// private
	addCommand(command) {
		this.commander.addCommand(command);
	}

	// public
	synchronize() {
		for (const newTable of this.newSchema.tables) {
			if (isNull(newTable.oldEntity)) {
				// Create table
				this.createTable(newTable);
				this.createTrigger(newTable);
			} else {
				// Update table
				this.processIndexes(newTable.oldEntity, newTable);
				this.processForeignKeys(newTable.oldEntity, newTable);
				this.changeTableColumns(newTable.oldEntity, newTable);
				this.updateTriggers(newTable.oldEntity, newTable);
				this.processTableRename(newTable.oldEntity, newTable);
			}
		}
		for (const oldTable of this.oldSchema.tables) {
			if (isNull(oldTable.newEntity)) this.dropTable(oldTable);
		}

		// PROCESS COMMANDS AND GENERATE DDL
		return this.commander.generateDDL();
	}

	createTable(table) {
		// Init table: Create columns, primary key, collation
		let createTableCommand = this.commandsFactory.CreateTableCommand(table);
		this.addCommand(createTableCommand);

		// Add indexes (not primary key indexes)
		let notPrimaryKeyindexes = this.getNotPrimaryIndexes(table.indexes);
		for (const index of notPrimaryKeyindexes) {
			let createIndexCommand = this.commandsFactory.CreateIndexCommand(
				table,
				index,
			);
			this.addCommand(createIndexCommand);
		}

		// Create foreign keys
		for (const foreignKey of table.foreignKeys) {
			let foreignKeyCommand = this.commandsFactory.CreateForeignKeyCommand(
				foreignKey,
				table,
			);
			this.addCommand(foreignKeyCommand);
		}
	}

	dropTable(table) {
		let command = this.commandsFactory.DropTableCommand(table);
		this.addCommand(command);
	}

	changeTableColumns(oldTable, newTable) {
		function findDifference(newColumns, oldColumns) {
			function isColumnChanged(colBefore, colAfter) {
				for (let prop in colBefore) {
					if (prop === "order" || prop == "after" || prop === "oldEntity")
						continue;
					if (
						typeof colBefore[prop] !== "object" &&
						colBefore[prop] !== colAfter[prop]
					) {
						return true;
					}
				}

				// Check if collation is changed. If the column collation is not specified, it is null (typeof = object), so the change couldn't be detected
				if (colBefore.collation.name !== colAfter.collation.name) {
					return true;
				}

				// check if comment is changed:
				if (colBefore.comment !== colAfter.comment) {
					return true;
				}

				// check if relevant non-primitive properties are changed:
				if (
					colBefore.type.name !== colAfter.type.name ||
					colBefore.type.length !== colAfter.type.length
				) {
					return true;
				}
				for (let option in colBefore.options) {
					if (colBefore.options[option] !== colAfter.options[option]) {
						return true;
					}
				}

				return false;
			}

			function isColumnReordered(colBefore, colAfter) {
				return colBefore.order !== colAfter.order;
			}

			let added = [];
			let deleted = [];
			let changed = [];
			let reordered = [];

			for (const column of newColumns) {
				let pair = {
					newColumn: column,
					oldColumn: column.oldEntity,
				};
				if (pair.newColumn && !pair.oldColumn) {
					added.push(pair.newColumn);
				} else {
					if (isColumnChanged(pair.oldColumn, pair.newColumn)) {
						changed.push(pair.newColumn);
					}
					if (isColumnReordered(pair.oldColumn, pair.newColumn)) {
						reordered.push(pair.newColumn);
					}
				}
			}
			for (const column of oldColumns) {
				if (column.newEntity === null) deleted.push(column.name);
			}
			// BORIS - new reorder logic :
			// fixing reordering when adding or removeing columns bug
			reordered = [];
			let oldColumnsCopy = [];
			let newColumnsCopy = [];
			function compare(a, b) {
				if (a.order > b.order) return 1;
				if (b.order > a.order) return -1;

				return 0;
			}
			for (const column of newColumns) {
				let pair = {
					newColumn: column,
					oldColumn: column.oldEntity,
				};
				if (!(pair.newColumn && !pair.oldColumn)) {
					newColumnsCopy.push(column);
				}
			}
			for (const column of oldColumns) {
				if (column.newEntity !== null) oldColumnsCopy.push(column);
			}
			oldColumnsCopy.sort(compare);
			newColumnsCopy.sort(compare);
			for (const [i, element] of oldColumnsCopy.entries()) {
				console.log(element);
				if (element.name !== newColumnsCopy[i].name) {
					reordered.push(newColumnsCopy[i]);
				}
			}
			// console.log(oldColumnsCopy, newColumnsCopy);
			// console.log(reordered);
			// BORIS - END

			return {
				added,
				deleted,
				changed,
				reordered,
			};
		}

		function isEmpty(ob) {
			for (let k in ob) {
				if (ob[k].length) return false;
			}
			return true;
		}

		let differences = findDifference(newTable.columns, oldTable.columns);

		// oved dolazi do greske ako se menja table comment ili table collation
		// if(isEmpty(differences))
		//     return

		let tableData = {
			added: differences.added,
			deleted: differences.deleted,
			changed: differences.changed,
			reordered: differences.reordered,
			oldTableName: oldTable.name,
			newTableName: newTable.name,
			tableComment:
				oldTable.comment === newTable.comment ? null : newTable.comment,
			tableCollation:
				oldTable.collation === newTable.collation ? null : newTable.collation,
		};

		if (
			isEmpty(differences) &&
			tableData.tableComment === null &&
			tableData.tableCollation === null
		)
			return;

		// console.log(tableData);
		let command = this.commandsFactory.AlterTableCommand(tableData);
		this.addCommand(command);
	}

	processTableRename(oldTable, newTable) {
		if (oldTable.name !== newTable.name) {
			this.addCommand(
				this.commandsFactory.RenameTableCommand(oldTable, newTable),
			);
		}
	}

	getNotPrimaryIndexes(indexes) {
		let notPrimaryIndexes = [];
		for (const index of indexes) {
			if (index.type !== "PRIMARY") notPrimaryIndexes.push(index);
		}
		return notPrimaryIndexes;
	}

	processIndexes(oldTable, newTable) {
		let oldIndexes = oldTable.indexes;
		let newIndexes = newTable.indexes;

		for (const oldIndex of oldIndexes) {
			let newIndex = Index.findIndex(oldIndex, newIndexes);
			if (!newIndex) {
				this.addCommand(
					this.commandsFactory.DropIndexCommand(oldTable, oldIndex),
				);
			}
		}

		for (const newIndex of newIndexes) {
			let oldIndex = Index.findIndex(newIndex, oldIndexes);
			if (oldIndex) {
				if (Index.isChanged(oldIndex, newIndex)) {
					this.addCommand(
						this.commandsFactory.DropIndexCommand(newTable, newIndex),
					);
					this.addCommand(
						this.commandsFactory.CreateIndexCommand(newTable, newIndex),
					);
				}
			} else {
				this.addCommand(
					this.commandsFactory.CreateIndexCommand(newTable, newIndex),
				);
			}
		}
	}

	processForeignKeys(oldTable, newTable) {
		let oldKeys = oldTable.foreignKeys;
		let newKeys = newTable.foreignKeys;

		for (const oldFk of oldKeys) {
			let newFk = ForeignKey.findForeignKey(newKeys, oldFk);
			if (!newFk) {
				this.addCommand(
					this.commandsFactory.DropForeignKeyCommand(oldFk, newTable),
				);
			}
		}

		for (const newFk of newKeys) {
			let oldFk = ForeignKey.findForeignKey(oldKeys, newFk);
			if (!oldFk) {
				this.addCommand(
					this.commandsFactory.CreateForeignKeyCommand(newFk, newTable),
				);
			} else if (ForeignKey.isChanged(oldFk, newFk)) {
				this.addCommand(
					this.commandsFactory.DropForeignKeyCommand(newTable, oldFk),
				);
				this.addCommand(
					this.commandsFactory.CreateForeignKeyCommand(newFk, newTable),
				);
			}
		}
	}

	createTrigger(tableData) {
		for (const trigger of tableData.triggers) {
			if (trigger.isDefined && trigger.name && trigger.action) {
				let command = this.commandsFactory.CreateTriggerCommand(
					trigger,
					tableData,
				);
				this.addCommand(command);
			}
		}
	}

	updateTriggers(tableInitial, tableCurr) {
		function isTriggerChanged(triggerInitial, triggerCurr) {
			return (
				triggerInitial.name !== triggerCurr.name ||
				triggerCurr.action !== triggerInitial.action
			);
		}
		// The order of triggers is always constant and there is no need to search for each.
		// Every trigger can be accessed by index in another table state.
		for (const [i, trigger] of tableInitial.triggers.entries()) {
			// BORIS : nova logika za prepoznavanje izmena triggera :
			let hasPair = null;
			for (let j = 0; j < tableCurr.triggers.length; j++) {
				if (trigger.id === tableCurr.triggers[j].id) {
					hasPair = tableCurr.triggers[j];
					if (isTriggerChanged(trigger, tableCurr.triggers[j])) {
						let dropCommand = this.commandsFactory.DropTriggerCommand(trigger);
						this.addCommand(dropCommand);
						let createCommand = this.commandsFactory.CreateTriggerCommand(
							tableCurr.triggers[j],
							tableCurr,
						);
						this.addCommand(createCommand);
					}
					break;
				}
			}
			if (hasPair === null) {
				let command = this.commandsFactory.DropTriggerCommand(trigger);
				this.addCommand(command);
			}
			// if ((trigger.isDefined && (!tableCurr.triggers || !tableCurr.triggers[i] || !tableCurr.triggers[i].isDefined))) {
			//   console.log('a');
			//     let command = this.commandsFactory.DropTriggerCommand(trigger)
			//     this.addCommand(command)
			// } else if ((!trigger.isDefined && tableCurr.triggers[i].isDefined)) {
			//     let command = this.commandsFactory.CreateTriggerCommand(tableCurr.triggers[i], tableCurr)
			//     this.addCommand(command)
			//     console.log('b');
			// } else if (trigger.isDefined && tableCurr.triggers[i].isDefined && isTriggerChanged(trigger, tableCurr.triggers[i])) {
			//     let dropCommand = this.commandsFactory.DropTriggerCommand(trigger)
			//     this.addCommand(dropCommand)
			//     let createCommand = this.commandsFactory.CreateTriggerCommand(tableCurr.triggers[i], tableCurr)
			//     this.addCommand(createCommand)
			//     console.log('c');
			// }
		}
		for (const [i, trigger] of tableCurr.triggers.entries()) {
			let newTrigger = true;
			for (const [j, triggerOld] of tableInitial.triggers.entries()) {
				if (trigger.id === triggerOld.id) {
					newTrigger = false;
				}
			}
			if (newTrigger) {
				let command = this.commandsFactory.CreateTriggerCommand(
					trigger,
					tableCurr,
				);
				this.addCommand(command);
			}
		}
	}
}

export default Synchronizer;

function isNull(a) {
	return a === undefined || a === null;
}
