<script setup lang="ts">
import store from "@/store";

type TProjectSites = {
	sit_id: number;
	sit_name: string;
	usr_id: number;
	pro_id: number;
	sit_type?: any;
	sit_maxdbsize: number;
	dbs_id: number;
	pro_name: string;
	sit_alias: string;
	prm_level?: any;
	rol_id?: any;
	dcn_state: string;
	srv_id: number;
	srv_name: string;
	usr_email: string;
	usr_name: string;
};

// type TProjectSitesExtended = TProjectSites & {
// 	__isChecked: boolean;
// };

const props = defineProps<{
	checkedList: Record<TProjectSites["sit_id"], boolean>;
}>();

const emit = defineEmits(["update"]);

let tempCheckedList = reactive<Record<string, boolean>>({});

const cbOptions = reactive({
	theme: "theme-alt",
	isCheckbox: true,
});

const getProjectSites = computed<TProjectSites[]>(
	() => store.getters["moduleProject/getProjectSites"],
);

function getCheckedSite(site: TProjectSites): boolean {
	return props.checkedList[site.sit_id] || false;
}
function onCheckedInput(evt: { checked: boolean }, site: TProjectSites) {
	tempCheckedList[site.sit_id] = evt.checked;
	emit("update", tempCheckedList);
}
// function onClickAddAll() {
// 	for (const site of filteredSites) {
// 		props.checkedList[site.sit_id] = true;
// 	}
// }

onMounted(() => {
	tempCheckedList = reactive(props.checkedList);
});
</script>

<template lang="pug">
.sites-checklist
	//- .add-to-all(@click="onClickAddAll")
	//- 	span.fa.fa-check
	//- 	span Add to all
	label.section-label
		span Access to sites
	div(v-for="(site, index) in getProjectSites", :key="index")
		checkbox-input(
			:checked="getCheckedSite(site)",
			:options="cbOptions",
			@change="onCheckedInput($event, site)"
		)
		.label(@click="onCheckedInput({ checked: !getCheckedSite(site) }, site)") {{ site.sit_alias }}
</template>

<style lang="scss" scoped>
.sites-checklist {
	display: flex;
	flex-direction: column;
	gap: 10px;
	max-height: 300px;
	overflow: auto;

	.section-label {
		font-weight: bold;
		font-size: 1rem;

		span {
			color: rgb(225, 225, 225);
		}
	}

	> div {
		display: flex;
		align-items: center;
		gap: 25px;

		.label {
			cursor: pointer;
		}
	}

	.add-to-all {
		display: flex;
		align-items: center;
		gap: 10px;
		color: $main-accent-color;
		cursor: pointer;
	}
}
</style>
