<template lang="pug">
#auth-page
	template(v-if="!isMobileView")
		.first-line
		.second-line
	.left-side
		.content
			.logo-wrap
				img(
					src="@/assets/image/MARSLogoSVG.svg",
					alt="mars-logo",
					:draggable="false"
				)
			.title
				span
					span {{ getTransl.title }}
					//- span(v-if="isLoginPage && hasUserName") ,
				span.name(v-if="isLoginPage && hasUserName") {{ hasUserName }}
			.text
				span {{ getTransl.text }}
			router-view(v-slot="{ Component }")
				transition(name="slide-fade", mode="out-in")
					component(
						:is="Component",
						:email="previousEmail",
						@logged-in="onLoggedInFunc"
					)
	.right-side(v-if="!isMobileView")
		//- img(src="@/assets/image/auth/video-placeholder.jpg")
		video(
			ref="video",
			loop,
			muted,
			poster="@/assets/image/auth/video-placeholder.jpg"
		)
			source(src="@/assets/image/auth/video-intro.mp4", type="video/mp4")
	//- .environment(v-if="canShowEnvChange")
		.dev
		  button(@click="onClickEnvironment('dev')") Dev
		.live
		  button(@click="onClickEnvironment('live')") Live
</template>

<script>
import store from "@/store";
import { autoEnterSiteFunc } from "@/modules/project-list/projectFunc.js";
import { mapActions } from "vuex";
export default {
	async beforeRouteEnter(to, from, next) {
		if (to.name === "LoginComp" && sessionStorage.sid) {
			// Move to app if the user is logged in
			const checkSessionValidity = async (sid) => {
				try {
					const res = await store.dispatch("moduleAuth/expandSession", {});
					if (res.data.result === "OK") {
						return true;
					}
				} catch {
					// ignored
				}
				return false;
			};

			const isValid = await checkSessionValidity(sessionStorage.sid);
			if (isValid) {
				next("/");
				return;
			}
		}
		next();
	},
	data() {
		return {
			timeoutVideo: null,
		};
	},
	computed: {
		isLoginPage() {
			return this.$route.name === "LoginComp";
		},
		isRegisterPage() {
			return this.$route.name === "RegisterComp";
		},
		getTransl() {
			if (this.isLoginPage) {
				return {
					title: "Welcome back",
					text: "",
					// text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et mrbi purus nibh etiam tincidunt pretium.Lorem ipsum dolor sit amet, consectetur adipiscing elitmrbi purus nib...",
				};
			} else if (this.isRegisterPage) {
				return {
					title: "Create your account now",
					text: "",
					// text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et mrbi purus nibh etiam tincidunt pretium.Lorem ipsum dolor sit amet, consectetur adipiscing elitmrbi purus nib...",
				};
			}
			return {
				title: "",
				text: "",
			};
		},
		hasUserName() {
			try {
				return (localStorage.displayName || "").trim();
			} catch {
				return "";
			}
		},
		previousEmail() {
			try {
				return localStorage.email || "";
			} catch {
				return "";
			}
		},
		// canShowEnvChange() {
		//   // Unused
		//   //- const isDev = !import.meta.env.PROD;
		//   //- const isAdminTestSite = location.host === "admin-t.mars-server.net";
		//   //- return isDev || isAdminTestSite;
		//   return false;
		// },
	},
	mounted() {
		this.videoPlayPauseSetup();
	},
	beforeUnmount() {
		clearTimeout(this.timeoutVideo);
		this.timeoutVideo = null;
	},
	methods: {
		...mapActions({
			setBlockLoading: "moduleMain/setLoading",
		}),
		async videoPlayPauseSetup() {
			try {
				await this.$refs.video?.play(); // Avoids autoplay attribute
				this.pauseVideoDelay();
			} catch (err) {
				console.warn("Video play interrupted", err.message);
			}
		},
		pauseVideoDelay() {
			const DELAY = 100_000;
			this.timeoutVideo = setTimeout(() => {
				this.$refs.video?.pause();
			}, DELAY);
		},
		async onLoggedInFunc(evt) {
			try {
				// Used for displaying name in login page
				localStorage.displayName = JSON.parse(sessionStorage.user).name;
				localStorage.email = evt.form.email;
			} catch {
				console.log("Invalid logged in user storage");
			}

			const routeFunc = (obj = {}) => {
				this.$router.push(obj);
			};

			if (this.$route.query?.l) {
				if (this.$route.query?.st && this.$route.query?.pt) {
					await this.setBlockLoading(true);
					const payload = {
						project: this.$route.query.pt,
						site: this.$route.query.st,
					};
					const result = await autoEnterSiteFunc(payload);
					if (result) {
						routeFunc({
							path: this.$route.query.l,
						});
					} else {
						console.warn("Error loading previous session!", this.$route);
						routeFunc({
							name: "ProjectList",
						});
					}
					await this.setBlockLoading(false);
				} else {
					// Default fallback because project is the only page that can be accessed without DB
					routeFunc({
						name: "ProjectList",
						// path: this.$route.query.l,
					});
				}
			} else {
				// Default project
				routeFunc({
					name: "ProjectList",
				});
			}
		},
		onClickEnvironment(envStr) {
			sessionStorage.__env = envStr;
			location.reload();
		},
	},
};
</script>

<style lang="scss" scoped>
#auth-page {
	display: flex;
	background: $dark-login;
	color: white;
	gap: 100px;
	height: 100%;
	width: 100%;

	.first-line {
		width: 18vw;
		height: 130vh;
		top: -130px;
		position: fixed;
		right: 35vw;
		background: #03da99;
		transform: rotate(21deg);
		opacity: 0;
		transition: opacity 0.2s ease;
		z-index: 0;
	}

	.second-line {
		width: 7vw;
		height: 130vh;
		top: -130px;
		position: fixed;
		right: 20vw;
		background: #03da99;
		transform: rotate(20deg);
		opacity: 0;
		transition: opacity 0.2s ease;
		z-index: 0;
	}

	.logo-wrap {
		max-width: 60%;
		width: 100%;
		margin: auto;

		img {
			height: 50px;
			width: 100%;
			z-index: 0;
			opacity: 0.7;

			@media screen and (min-width: 768px) {
				height: 100px;
			}
		}
	}

	.left-side {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 0 20px;
		width: auto;
		z-index: 1;
		background: transparent;
		overflow: auto;
		min-width: 250px;

		.content {
			margin-top: 10px;
			padding: 0 0 20px;
			width: 100%;
			background: $dark-login;

			.title {
				font-weight: bold;
				// font-size: clamp(2rem, 10vw, 3.2rem);
				font-size: clamp(2rem, 9vw, 2.8rem);
				display: inline-flex;
				flex-direction: column;
				align-items: center;
				width: 100%;
			}

			.text {
				color: #9a9a9a;
				font-size: clamp(0.5rem, 3vw, 1rem);
				line-height: 29px;
				margin-bottom: 35px;
			}
		}

		@include slideFadeTransition;
	}

	.right-side {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		position: relative;
		opacity: 0;
	}

	.environment {
		display: flex;
		gap: 10px;
		position: relative;
	}

	@media screen and (min-width: 768px) {
		.left-side {
			margin: 0 80px;
			padding: 0 10px;
			min-width: 400px;
			width: 500px;
			// overflow: hidden;
			// overflow-y: auto;
			overflow-y: visible;
			overflow-x: visible;

			.content {
				margin-top: 14vh;
				// overflow: hidden;

				.logo-wrap {
					margin: 0;
				}

				.title {
					align-items: baseline;
				}
			}
		}

		.right-side {
			video {
				width: 100%;
				position: absolute;
				bottom: 0;
				right: 0;
				// top: 140px;
				opacity: 1;
				border-top-left-radius: 15px;
			}
		}
	}

	@media screen and (min-width: 1200px) {
		.left-side {
			.content {
				margin-top: 16vh;
			}
		}

		.right-side {
			opacity: 1;

			video {
				border-top-left-radius: 38px;
			}
		}

		.first-line,
		.second-line {
			opacity: 1;
		}
	}
}
</style>
