const invokeEvent = (state, eventName, eventData) => {
	if (state.popupOptions.events && state.popupOptions.events[eventName]) {
		state.popupOptions.events[eventName](eventData);
	}
};

export default {
	namespaced: true,
	state: {
		popupOptions: {
			show: false,
			data: {},
			events: {},
		},
	},
	getters: {
		isPopupShown: (state) => {
			return state.popupOptions?.show || false;
		},
		popupData: (state) => {
			return state.popupOptions?.data || {};
		},
		popupEvents: (state) => {
			return state.popupOptions?.events || {};
		},
	},
	mutations: {
		OPEN_POPUP(state, payload) {
			state.popupOptions.data = payload.data || {};
			state.popupOptions.events = payload.events;
			state.popupOptions.show = true;
		},
		CLOSE_POPUP(state, payload) {
			invokeEvent(state, "onclose");
			state.popupOptions.show = false;
			state.popupOptions.data = {}; // Reset popup data
		},
		UPDATE_POPUP(state, payload) {
			payload.data && Object.assign(state.popupOptions.data, payload.data);
			payload.events &&
				Object.assign(state.popupOptions.events, payload.events);
			"show" in payload && (state.popupOptions.show = payload.show);
		},
		ALERT_POPUP(state, payload) {
			invokeEvent(state, "onok");
		},
		CONFIRM_POPUP(state, payload) {
			if (payload) {
				invokeEvent(state, "onconfirm");
			} else {
				invokeEvent(state, "oncancel");
			}
		},
		PROMPT_POPUP(state, payload) {
			invokeEvent(state, "onok", payload);
		},
		PROMPT_CHANGE(state, payload) {
			invokeEvent(state, "onchange", payload);
		},
	},
	actions: {
		openPopupStorage({ commit }, data) {
			if (!data || !Object.prototype.hasOwnProperty.call(data, "data")) {
				console.error("Popup needs data object");
				return;
			}
			commit("OPEN_POPUP", data);
		},
		closePopupStorage({ commit }, data) {
			commit("CLOSE_POPUP", data);
		},
		updatePopupStorage: ({ commit }, data) => commit("UPDATE_POPUP", data),
		fireAlertPopupEvent({ commit }, data) {
			commit("ALERT_POPUP", data);
		},
		fireConfirmPopupEvent({ commit }, data) {
			commit("CONFIRM_POPUP", data);
		},
		firePromptPopupEvent({ commit }, data) {
			commit("PROMPT_POPUP", data);
		},
		firePromptPopupChange({ commit }, data) {
			commit("PROMPT_CHANGE", data);
		},
	},
};
