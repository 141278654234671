export default {
	// Types: 'warning', 'error', 'success', 'info'
	namespaced: true,
	state: {
		toastID: 1,
		toasts: [],
	},
	getters: {
		toastData: (state) => (id) => {
			const toastObj = state.toasts.find((el) => el.id === id);
			return toastObj?.data || {};
		},
		toastEvents: (state) => (id) => {
			const toastObj = state.toasts.find((el) => el.id === id);
			return toastObj?.events || {};
		},
	},
	mutations: {
		OPEN_TOAST(state, payload) {
			state.toasts.push({
				id: payload.id,
				data: payload.data || {},
				events: payload.events,
			});
		},
		CLOSE_TOAST(state, { id }) {
			const itemIndex = state.toasts.findIndex((el) => el.id === id);
			// User invoke
			if (itemIndex !== -1) {
				// Close only if exist
				const toast = state.toasts[itemIndex];
				if (toast && toast.events && toast.events.onclose) {
					toast.events.onclose(toast);
				}

				state.toasts.splice(itemIndex, 1);
			}
		},
		CLOSE_FIRST_TOAST(state, data) {
			const itemIndex = 0;

			const toast = state.toasts[itemIndex];
			if (toast && toast.events && toast.events.onclose) {
				toast.events.onclose(toast);
			}

			state.toasts.splice(itemIndex, 1);
		},
	},
	actions: {
		openToastWrapStore({ state, dispatch }, { msg, type = "info" }) {
			if (!msg) {
				console.warn("Message has to be provided");
				return;
			}
			const tPar = {
				data: {
					type: type,
					text: msg,
				},
			};
			dispatch("openToastStorage", tPar);
		},
		openToastStorage({ state, commit }, payload) {
			if (!payload || !Object.prototype.hasOwnProperty.call(payload, "data")) {
				console.log("[Toast] Data object not found");
				return;
			}

			// Limit toasts
			if (state.toasts.length > 4) {
				commit("CLOSE_FIRST_TOAST");
			}

			const id = state.toastID++;
			payload.id = id;

			commit("OPEN_TOAST", payload);
		},
		closeToastStorage({ commit }, data) {
			commit("CLOSE_TOAST", data);
		},
	},
};
