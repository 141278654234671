const mixin = {
	methods: {
		dataLoop(data, table, isOmitNull = false) {
			table.rows = [];
			for (const datum of data) {
				for (let variable in datum) {
					if (datum.hasOwnProperty(variable)) {
						const column = this.addColumn(variable, table.columns);
						if (column) {
							table.columns.push(column);
						}
					}
				}
				const row = this.addRow(datum, isOmitNull);
				table.rows.push(row);
			}
		},
		addRow(data, isOmitNull = false) {
			const row = {};
			row.values = {};
			for (let variable in data) {
				if (data.hasOwnProperty(variable)) {
					const value = data[variable];
					if (isOmitNull && value === null) {
						continue;
					}
					row.values[variable] = {
						name: variable,
						value: data[variable],
					};
				}
			}
			if (row.values.slot) {
				row.slot = row.values.slot.value;
			}
			if (row.values.dropdown) {
				row.dropdown = row.values.dropdown.value;
			}
			row.visibility = true;
			return row;
		},
		addColumn(data, columns) {
			for (const column of columns) {
				if (column.name === data) {
					return;
				}
			}
			if (!columns.hasOwnProperty(data)) {
				const position = columns.length;
				const column = {
					position: position,
					name: data,
					visibility: false,
				};
				return column;
			}
		},
		addSpecialColumns(data, table) {
			for (const datum of data) {
				const column = {
					name: datum.name,
					position: table.columns.length,
					icon: datum.icon,
				};
				table.columns.push(column);
				for (let j = 0; j < table.rows.length; j++) {
					table.rows[j].values[datum.name] = {
						name: datum.name,
						value: "",
						icon: datum.icon,
						position: column.position,
					};
				}
			}
		},
	},
};

export default mixin;
