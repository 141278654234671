<template lang="pug">
form#register-comp.form(autocomplete="off", @submit.prevent)
	.email-pass
		label
			span.lbl Email
			.input-field
				input.flat.flat-auth(
					v-model.trim="form.email",
					type="email",
					name="email",
					autofocus,
					required="required",
					autocomplete="off",
					:placeholder="$i18n.t('auth.enterEmail')",
					:class="{ valid: isEmailValid }"
				)
				.icon.checkmark(v-if="isEmailValid")
					inline-svg(
						:src="checkmarkIcon",
						width="15",
						height="15",
						viewBox="0 0 300 300"
					)
			span(v-show="error.email") {{ error.email }}
		label
			span.lbl Password
			.input-field
				input#password.flat.flat-auth(
					v-model.trim="form.pass",
					:type="dynamicTypePassword",
					name="password",
					required="required",
					autocomplete="new-password",
					:placeholder="$i18n.t('auth.enterPassword')"
				)
				span.display-pass(@click="isDisplayPass = !isDisplayPass")
					span.fa(
						:class="{ 'fa-eye': !isDisplayPass, 'fa-eye-slash': isDisplayPass }"
					)
				.pass-check(v-show="form.pass && !isMobileView")
					password-strength(:text="form.pass")
			span(v-show="error.pass") {{ error.pass }}
	.privacy-policy
		checkbox-input(
			:checked="form.isPrivacyChecked",
			:options="checkboxPrivacyOptions",
			@change="form.isPrivacyChecked = $event.checked"
		)
		.text
			span I agree to the MARS
			router-link(:to="{ name: 'PrivacyPolicy' }") Privacy Policy
			span and
			router-link(:to="{ name: 'TermsService' }") Terms of Service
	.password-caps(:style="capsBarStyle") {{ $i18n.t("auth.capsLockOn") }}
	.action
		button.btn-acc-alt.signup(
			:disabled="!hasValidFields",
			@click.stop="onSubmitForm"
		) Create Account
		span.right-text
			span Already have an account?
			router-link(:to="{ name: 'LoginComp' }")
				span Log in
</template>

<script>
import checkmarkIcon from "@/assets/image/icons/checkmark.svg";
import PasswordStrength from "./PasswordStrength.vue";
import authMixin from "../authMixin.js";
export default {
	components: {
		PasswordStrength,
	},
	mixins: [authMixin],
	props: {
		userProp: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			checkmarkIcon: checkmarkIcon,
			form: {
				email: "",
				pass: "",
				isPrivacyChecked: false,
			},
			error: {
				email: "",
				pass: "",
			},
			checkboxPrivacyOptions: {
				// rightText: `${this.$i18n.t("privacypolicy")}`,
				rightText: ``,
				theme: "theme-alt",
			},
			isCapsLock: false,
			isDisplayPass: false,
		};
	},
	computed: {
		hasValidFields() {
			return Object.values(this.form).every(Boolean) && this.isEmailValid;
		},
		dynamicTypePassword() {
			if (this.isDisplayPass) {
				return "text";
			}
			return "password";
		},
		capsBarStyle() {
			if (this.isCapsLock) {
				return {};
			}
			return { visibility: "hidden" };
		},
		isEmailValid() {
			return this.validateEmail(this.form.email);
		},
	},
	mounted() {
		this.$el.querySelector("input")?.focus();

		// Set user object from props if any
		if (Object.keys(this.userProp).length) {
			this.form = this.userProp;
		}

		document.addEventListener("keyup", this.checkGlobalKeyUp);
	},
	beforeUnmount() {
		document.removeEventListener("keyup", this.checkGlobalKeyUp);
	},
	methods: {
		checkGlobalKeyUp(evt) {
			evt.preventDefault();
			const keyCaps = evt.code === "CapsLock";
			// Modifier is sometimes undefined [Browser related]
			const clModifier = evt.getModifierState?.("CapsLock");
			if (keyCaps) {
				// [RARE] Sometimes can show that it's on when it isn't (browser event bug)
				this.isCapsLock = clModifier && this.isCapsLock ? false : clModifier;
			} else {
				if (!clModifier) {
					this.isCapsLock = false;
				}
			}
		},
		async onSubmitForm() {
			if (this.hasValidFields) {
				const params = {
					usr_email: this.form.email,
					usr_name: this.form.name || "",
					usr_password: this.form.pass,
				};
				await this.registerUserApi({ user: params });
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.form {
	@include auth-form;

	.pass-check {
		position: absolute;
		right: -140px;
		background: #39393d;
		padding: 11px 12px;
		border-radius: 6px;
		font-size: 0.7rem;
		width: 130px;
	}

	.privacy-policy {
		display: flex;
		gap: 10px;
		margin-top: 38px;

		> * {
			display: flex;
			gap: 4px;
			color: #9a9a9a;
			flex-wrap: wrap;
		}

		a {
			color: white;
		}
	}

	:deep(.checkbox-inner-box) {
		border-radius: 4px !important;
	}
}
</style>
