<template lang="pug">
.verify-mail
	img(src="@/assets/image/auth/user.svg")
	h2 Verify your email address to finish signing up for MARS
	div
		span Please check your inbox to verify email address and follow the instructions
	router-link(:to="{ name: 'LoginComp' }")
		span Back to login
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.verify-mail {
	display: flex;
	flex-direction: column;
	gap: 20px;
	text-align: center;
	align-items: center;

	@include auth-form;

	.form-group {
		margin: 40px 0 20px;
		text-align: center;
	}

	img {
		width: 100px;
		height: 100px;
	}

	a {
		color: $green;
		text-align: center;
	}

	button {
		height: 46px;
		width: 145px;
	}
}
</style>
