<template lang="pug">
#login-comp.form(
	:class="getAppClass",
	@keyup.prevent.stop,
	@click.prevent.stop,
	@submit.prevent.stop
)
	transition(name="slide-down2")
		.email-pass(v-if="!hasTwoFactor")
			label
				span.lbl Email
				.input-field
					input#email.flat.flat-auth(
						v-model.trim="form.email",
						name="email",
						type="email",
						autofocus,
						required="required",
						:placeholder="$i18n.t('auth.enterEmail')",
						:class="{ valid: isEmailValid }",
						@keyup.enter="onLoginForm"
					)
					.icon.checkmark(v-if="isEmailValid")
						inline-svg(
							:src="checkmarkIcon",
							width="15",
							height="15",
							viewBox="0 0 300 300"
						)
				span(v-show="error.email") {{ error.email }}
			label
				span.lbl Password
				.input-field
					input#password.flat.flat-auth(
						v-model.trim="form.pass",
						name="password",
						:type="dynamicTypePassword",
						required="required",
						:placeholder="$i18n.t('auth.enterPassword')",
						@keyup.enter="onLoginForm"
					)
					span.display-pass(@click="isDisplayPass = !isDisplayPass")
						span.fa(
							:class="{ 'fa-eye': !isDisplayPass, 'fa-eye-slash': isDisplayPass }"
						)
				span(v-show="error.pass") {{ error.pass }}
	transition(name="slide-down")
		.two-factor-group(v-if="hasTwoFactor")
			a(@click.prevent="hasTwoFactor = false")
				span Back
			label
				span.lbl 2FA Token
				.input-field
					input.twofactor.flat.flat-auth(
						v-model.trim="form.mfacode",
						name="2fa",
						type="text",
						required="required",
						:placeholder="$i18n.t('auth.twoFactorCode')",
						@keyup.enter="onLoginForm"
					)
	template(v-if="!isPopup && !hasTwoFactor")
		router-link.forgot-password(:to="{ name: 'ForgotPass' }") Forgot password?
	.password-caps(:style="capsBarStyle") {{ $i18n.t("auth.capsLockOn") }}
	.action
		button.btn-acc-alt.login(:disabled="!hasValidFields", @click="onLoginForm") {{ $t("auth.signin") }}
		span.right-text(v-if="!isPopup")
			span Don't have an account?
			router-link(:to="{ name: 'RegisterComp' }")
				span Sign up
</template>

<script>
import checkmarkIcon from "@/assets/image/icons/checkmark.svg";
import authMixin from "../authMixin.js";
export default {
	mixins: [authMixin],
	props: {
		userProp: {
			type: Object,
			default: () => ({}),
		},
		isPopup: {
			type: Boolean,
			default: false,
		},
		email: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			checkmarkIcon: checkmarkIcon,
			form: {
				email: this.email,
				pass: "",
				mfacode: undefined,
			},
			error: {
				email: "",
				pass: "",
				mfacode: "",
			},
			isCapsLock: false,
			isDisplayPass: false,
			hasTwoFactor: false,
			errorMessage: "",
		};
	},
	computed: {
		hasValidFields() {
			// Some accounts don't have a proper email;
			return this.form.email && this.form.pass;
			// return Object.values(this.form).every(el => el) && this.isEmailValid;
		},
		dynamicTypePassword() {
			return this.isDisplayPass ? "text" : "password";
		},
		// passwordDisplayTitle () {
		//   if (this.isDisplayPass) {
		//     return this.$i18n.t('auth.hidePassword');
		//   }
		//   return this.$i18n.t('auth.showPassword');
		// },
		capsBarStyle() {
			if (this.isCapsLock) {
				return {};
			}
			return { visibility: "hidden" };
		},
		getAppClass() {
			const isLiveTest = sessionStorage.__env === "live";
			return {
				liveTest: isLiveTest,
			};
		},
		isEmailValid() {
			return this.validateEmail(this.form.email);
		},
	},
	watch: {
		hasTwoFactor(val) {
			if (val) {
				nextTick(() => {
					document.querySelector(".twofactor")?.focus();
				});
			}
		},
	},
	mounted() {
		this.$el.querySelector("input")?.focus();

		// Set user object from props if any
		if (Object.keys(this.userProp).length) {
			this.form = this.userProp;
		}

		document.addEventListener("keyup", this.checkGlobalKeyUp);
	},
	beforeUnmount() {
		document.removeEventListener("keyup", this.checkGlobalKeyUp);
	},
	methods: {
		checkGlobalKeyUp(evt) {
			evt.preventDefault();
			const keyCaps = evt.code === "CapsLock";
			// Modifier is sometimes undefined [Browser related]
			const clModifier = evt.getModifierState?.("CapsLock");
			if (keyCaps) {
				// [RARE] Sometimes can show that it's on when it isn't (browser event bug)
				this.isCapsLock = clModifier && this.isCapsLock ? false : clModifier;
			} else {
				if (!clModifier) {
					this.isCapsLock = false;
				}
			}
		},
		onLoginForm() {
			if (this.hasValidFields) {
				this.loginUserApi(this.form);
			} else {
				console.log("Invalid fields", this.form);
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.form {
	@include auth-form;

	&.liveTest {
		border: 3px solid yellow;
	}

	.forgot-password {
		cursor: pointer;
		display: block;
		width: 150px;
		font-size: 12px;
		font-style: italic;
		color: #a9a9a9;
	}

	.two-factor-group {
		display: flex;
		flex-direction: column;
		gap: 10px;

		a {
			cursor: pointer;
		}
	}

	.slide-down-enter-active {
		transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
	}

	.slide-down-leave-active {
		transition: none;
	}

	.slide-down-enter,
	.slide-down-enter-from,
	.slide-down-leave-to {
		transform: translateY(-50px) !important;
	}
}
</style>
