export default [
	{
		label: "Active4D",
		name: "active4d",
	},
	{
		label: "All Hallows Eve",
		name: "all-hallows-eve",
	},
	{
		label: "Amy",
		name: "amy",
	},
	{
		label: "Birds of Paradise",
		name: "birds-of-paradise",
	},
	{
		label: "Blackboard",
		name: "blackboard",
	},
	{
		label: "Brilliance Black",
		name: "brilliance-black",
	},
	{
		label: "Brilliance Dull",
		name: "brilliance-dull",
	},
	{
		label: "Chrome DevTools",
		name: "chrome-devtools",
	},
	{
		label: "Clouds Midnight",
		name: "clouds-midnight",
	},
	{
		label: "Clouds",
		name: "clouds",
	},
	{
		label: "Cobalt",
		name: "cobalt",
	},
	{
		label: "Dawn",
		name: "dawn",
	},
	{
		label: "Dominion Day",
		name: "dominion-day",
	},
	{
		label: "Dracula",
		name: "dracula",
	},
	{
		label: "Dreamweaver",
		name: "dreamweaver",
	},
	{
		label: "Eiffel",
		name: "eiffel",
	},
	{
		label: "Espresso Libre",
		name: "espresso-libre",
	},
	{
		label: "GitHub",
		name: "github",
	},
	{
		label: "IDLE",
		name: "idle",
	},
	{
		label: "Katzenmilch",
		name: "katzenmilch",
	},
	{
		label: "Kuroir Theme",
		name: "kuroir-theme",
	},
	{
		label: "LAZY",
		name: "lazy",
	},
	{
		label: "MagicWB (Amiga)",
		name: "magicwb--amiga-",
	},
	{
		label: "Merbivore Soft",
		name: "merbivore-soft",
	},
	{
		label: "Merbivore",
		name: "merbivore",
	},
	{
		label: "Monokai Bright",
		name: "monokai-bright",
	},
	{
		label: "Monokai",
		name: "monokai",
	},
	{
		label: "Night Owl",
		name: "night-owl",
	},
	{
		label: "Oceanic Next",
		name: "oceanic-next",
	},
	{
		label: "Pastels on Dark",
		name: "pastels-on-dark",
	},
	{
		label: "Slush and Poppies",
		name: "slush-and-poppies",
	},
	{
		label: "Solarized-dark",
		name: "solarized-dark",
	},
	{
		label: "Solarized-light",
		name: "solarized-light",
	},
	{
		label: "SpaceCadet",
		name: "spacecadet",
	},
	{
		label: "Sunburst",
		name: "sunburst",
	},
	{
		label: "Textmate (Mac Classic)",
		name: "textmate--mac-classic-",
	},
	{
		label: "Tomorrow-Night-Blue",
		name: "tomorrow-night-blue",
	},
	{
		label: "Tomorrow-Night-Bright",
		name: "tomorrow-night-bright",
	},
	{
		label: "Tomorrow-Night-Eighties",
		name: "tomorrow-night-eighties",
	},
	{
		label: "Tomorrow-Night",
		name: "tomorrow-night",
	},
	{
		label: "Tomorrow",
		name: "tomorrow",
	},
	{
		label: "Twilight",
		name: "twilight",
	},
	{
		label: "Upstream Sunburst",
		name: "upstream-sunburst",
	},
	{
		label: "Vibrant Ink",
		name: "vibrant-ink",
	},
	{
		label: "Xcode",
		name: "xcode-default",
	},
	{
		label: "Zenburnesque",
		name: "zenburnesque",
	},
	{
		label: "iPlastic",
		name: "iplastic",
	},
	{
		label: "idleFingers",
		name: "idlefingers",
	},
	{
		label: "krTheme",
		name: "krtheme",
	},
	{
		label: "monoindustrial",
		name: "monoindustrial",
	},
];
