import api from "@/api";

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		getTemplateList: (store, data) => api.getTemplateList(data),
		postTemplateList: (store, data) => api.postTemplateList(data),
	},
};
