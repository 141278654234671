<script setup>
import infoIcon from "@/assets/image/info-icon.svg";
import { useRegisterSW } from "virtual:pwa-register/vue";

const intervalMS = 60 * 60 * 1000; // 1 Hour

const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
	onRegistered(r) {
		r &&
			setInterval(() => {
				r.update();
			}, intervalMS);
	},
});

const close = async () => {
	offlineReady.value = false;
	needRefresh.value = false;
};
</script>

<template lang="pug">
transition(name="slide-pop")
	//- .pwa-toast(v-if="offlineReady || needRefresh", role="alert")
	.pwa-toast(v-if="needRefresh", role="alert")
		.message
			.icon
				inline-svg(:src="infoIcon")
			//- span(v-if="offlineReady")
			//-   | {{ $t("app.version.readyOffline") }}
			//- span(v-else)
			.title
				.bold
					| {{ $t("app.version.title") }}.
				span
					| {{ $t("app.version.reloadMsg") }}.
		.action
			button.btn-acc-alt(v-if="needRefresh", @click="updateServiceWorker()")
				| {{ $t("button.reload") }}
			button.btn-inv(@click="close")
				| {{ $t("button.close") }}
</template>

<style lang="scss">
.pwa-toast {
	position: fixed;
	right: 0;
	bottom: 0;
	margin: 20px;
	padding: 15px 25px;
	border: 1px solid #8885;
	z-index: 1;
	text-align: left;
	background-color: #353538;
	display: flex;
	flex-direction: column;
	align-items: start;
	gap: 10px;

	.message {
		margin-bottom: 8px;
		display: flex;
		align-items: center;
		gap: 12px;
		color: #c0c0c0;

		> .icon {
			width: 30px;
			height: 30px;

			svg {
				width: 100%;
				height: 100%;
			}
		}

		.title {
			display: flex;
			flex-direction: column;
			gap: 3px;

			.bold {
				font-weight: bold;
				color: white;
			}
		}
	}

	.action {
		display: flex;
		gap: 5px;

		button {
			border: 1px solid #8885;
			outline: none;
			margin-right: 5px;
			border-radius: 2px;
			padding: 3px 10px;
		}
	}
}

.slide-pop-enter,
.slide-pop-enter-from,
.slide-pop-leave-to {
	opacity: 0;
	transform: translateY(80px);
}

.slide-pop-enter-active,
.slide-pop-leave-active {
	transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
</style>
