<script>
import { format } from "sql-formatter";
import * as monaco from "monaco-editor";

// Vite 2.8+
// const urls = {
//   editorWorker: "monaco-editor/esm/vs/editor/editor.worker",
//   jsonWorker: "monaco-editor/esm/vs/language/json/json.worker",
//   cssWorker: "monaco-editor/esm/vs/language/css/css.worker",
//   htmlWorker: "monaco-editor/esm/vs/language/html/html.worker",
//   tsWorker: "monaco-editor/esm/vs/language/typescript/ts.worker",
// };
// const worker = new Worker(new URL("./worker.js", import.meta.url), {
//   type: "module",
// });

import MonacoJsonWorker from "monaco-editor/esm/vs/language/json/json.worker?worker";
import MonacoCssWorker from "monaco-editor/esm/vs/language/css/css.worker?worker";
import MonacoHtmlWorker from "monaco-editor/esm/vs/language/html/html.worker?worker";
import MonacoTsWorker from "monaco-editor/esm/vs/language/typescript/ts.worker?worker";
import MonacoEditorWorker from "monaco-editor/esm/vs/editor/editor.worker?worker";

const isDev = !import.meta.env.PROD;
console.debug(`* lazy imported monaco-editor, development: ${isDev}`);

self.MonacoEnvironment = {
	getWorker: function (workerId, label) {
		console.debug(
			`* lazy imported Monaco Editor worker id '${workerId}', label '${label}'`,
		);
		const getWorkerModule = (moduleUrl, label) => {
			return new Worker(
				new URL(
					"/node_modules/monaco-editor/esm/vs/" + moduleUrl + ".js?worker",
					import.meta.url,
				),
				{
					name: label,
					type: "module",
				},
			);
		};
		switch (label) {
			case "json": {
				return isDev
					? getWorkerModule("language/json/json.worker", label)
					: new MonacoJsonWorker();
			}
			case "css":
			case "scss":
			case "less": {
				return isDev
					? getWorkerModule("language/css/css.worker", label)
					: new MonacoCssWorker();
			}
			case "html":
			case "handlebars":
			case "razor": {
				return isDev
					? getWorkerModule("language/html/html.worker", label)
					: new MonacoHtmlWorker();
			}
			case "typescript":
			case "javascript": {
				return isDev
					? getWorkerModule("language/typescript/ts.worker", label)
					: new MonacoTsWorker();
			}
			default: {
				return isDev
					? getWorkerModule("editor/editor.worker", label)
					: new MonacoEditorWorker();
			}
		}
	},
};

// import editorWorker from "monaco-editor/esm/vs/editor/editor.worker?worker";
// import jsonWorker from "monaco-editor/esm/vs/language/json/json.worker?worker";
// import cssWorker from "monaco-editor/esm/vs/language/css/css.worker?worker";
// import htmlWorker from "monaco-editor/esm/vs/language/html/html.worker?worker";
// import tsWorker from "monaco-editor/esm/vs/language/typescript/ts.worker?worker";

// self.MonacoEnvironment = {
//   getWorker(_, label) {
//     if (label === "json") {
//       return new jsonWorker();
//     }
//     if (label === "css" || label === "scss" || label === "less") {
//       return new cssWorker();
//     }
//     if (label === "html" || label === "handlebars" || label === "razor") {
//       return new htmlWorker();
//     }
//     if (label === "typescript" || label === "javascript") {
//       return new tsWorker();
//     }
//     return new editorWorker();
//   },
// };

export default {
	name: "MonacoEditor",
	model: {
		event: "change",
	},
	props: {
		original: {
			type: String,
			default: "",
		},
		value: {
			type: String,
			required: true,
		},
		theme: {
			type: String,
			default: "mars-ui",
		},
		language: {
			type: String,
			default: "",
		},
		options: {
			type: Object,
			default: () => ({}),
		},
		// amdRequire: {
		//   type: Function,
		// },
		diffEditor: {
			type: Boolean,
			default: false,
		},
	},
	emits: ["editorWillMount", "editorDidMount", "change", "change-all"],
	data() {
		return {
			disposableFormatterProvider: null,
		};
	},
	watch: {
		options: {
			deep: true,
			handler(options) {
				if (this.editor) {
					const editor = this.getModifiedEditor();
					editor.updateOptions(options);
				}
			},
		},
		value(newValue) {
			if (this.editor) {
				const editor = this.getModifiedEditor();
				if (newValue !== editor.getValue()) {
					editor.setValue(newValue);
				}
			}
		},
		original(newValue) {
			if (this.editor && this.diffEditor) {
				const editor = this.getOriginalEditor();
				if (newValue !== editor.getValue()) {
					editor.setValue(newValue);
				}
			}
		},
		language(newVal) {
			if (this.editor) {
				const editor = this.getModifiedEditor();
				this.monaco.editor.setModelLanguage(editor.getModel(), newVal);
			}
		},
		theme(newVal) {
			if (this.editor) {
				this.monaco.editor.setTheme(newVal);
			}
		},
	},
	mounted() {
		// if (this.amdRequire) {
		//   this.amdRequire(["vs/editor/editor.main"], () => {
		//     this.monaco = window.monaco;
		//     this.$nextTick(() => {
		//       this.initMonaco(window.monaco);
		//     });
		//   });
		// } else {
		// ESM format so it can't be resolved by commonjs `require` in eslint
		this.monaco = monaco;
		this.$nextTick(() => {
			this.initMonaco(monaco);
		});
		// }

		// this.disableMonacoLinter();
		this.addCustomFormatters();
	},
	beforeUnmount() {
		this.editor?.dispose();
		this.disposableFormatterProvider?.dispose();
	},
	methods: {
		initMonaco(monaco) {
			this.$emit("editorWillMount", this.monaco);

			const options = Object.assign(
				{
					value: this.value,
					theme: this.theme,
					language: this.language,
				},
				this.options,
			);

			if (this.diffEditor) {
				this.editor = monaco.editor.createDiffEditor(this.$el, options);
				const originalModel = monaco.editor.createModel(
					this.original,
					this.language,
				);
				const modifiedModel = monaco.editor.createModel(
					this.value,
					this.language,
				);
				this.editor.setModel({
					original: originalModel,
					modified: modifiedModel,
				});
			} else {
				this.editor = monaco.editor.create(this.$el, options);
			}

			// @event `change`
			const editor = this.getModifiedEditor();
			editor.onDidChangeModelContent((event) => {
				const value = editor.getValue();
				if (this.value !== value) {
					this.$emit("change", value, event);
				}
				this.$emit("change-all", {
					event,
					value,
				});
			});

			this.$emit("editorDidMount", this.editor);
		},
		getEditor() {
			return this.editor;
		},
		getModifiedEditor() {
			return this.diffEditor ? this.editor.getModifiedEditor() : this.editor;
		},
		getOriginalEditor() {
			return this.diffEditor ? this.editor.getOriginalEditor() : this.editor;
		},
		focus() {
			this.editor.focus();
		},
		// disableMonacoLinter(isDisable = true) {
		//   this.monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions(
		//     {
		//       noSemanticValidation: isDisable,
		//       noSyntaxValidation: isDisable,
		//     }
		//   );
		//   this.monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions(
		//     {
		//       noSemanticValidation: isDisable,
		//       noSyntaxValidation: isDisable,
		//     }
		//   );
		// },
		addSqlFormatter() {
			this.disposableFormatterProvider =
				this.monaco.languages.registerDocumentFormattingEditProvider("sql", {
					provideDocumentFormattingEdits(model) {
						const formatted = format(model.getValue());
						return [
							{
								range: model.getFullModelRange(),
								text: formatted,
							},
						];
					},
				});
		},
		addCustomFormatters() {
			this.addSqlFormatter();
		},
	},
	render() {
		return h("div");
	},
};
</script>

<style lang="scss">
.monaco-editor {
	.overflowingContentWidgets {
		position: unset; // Used to overwrite pos:relative
	}
}
</style>
