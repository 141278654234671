<template lang="pug">
.inner
	.content(v-if="parseText") {{ parseText }}
	.content(v-if="parseHtml && !parseText", v-html="parseHtml")
	.button-group
		button.btn-ok.acc(@click="okAlert", @keyup.esc="okAlert") {{ parseOkBtn }}
</template>

<script>
import popupMixin from "./popupMixin.js";
import { mapActions } from "vuex";

export default {
	mixins: [popupMixin],
	props: {
		popupData: Object,
	},
	emits: ["close-popup"],
	methods: {
		...mapActions({
			fireAlertPopupEvent: "modulePopup/fireAlertPopupEvent",
		}),
		okAlert() {
			this.fireAlertPopupEvent();
			this.$emit("close-popup");
		},
	},
};
</script>

<style lang="scss" scoped>
.content {
	margin-bottom: 30px;
}

.button-group {
	text-align: right;
}
</style>
