import eventBus from "@/mixins/eventBus.js";
import { mapActions, mapGetters, mapState } from "vuex";

function parseDataForWrite(resData, resDataKey, dataKey) {
	function getObj(path, obj) {
		return path.split("/").reduce((prev, curr) => {
			return prev ? prev[curr] : null;
		}, obj || self);
	}
	let errorMsg = [`No resData for ${dataKey}`, resData];

	if (resData) {
		if (resDataKey.includes("/")) {
			// compThis[dataKey] = getObj(resDataKey, resData)
			return {
				status: "OK",
				message: null,
				data: getObj(resDataKey, resData),
			};
		} else if (Object.prototype.hasOwnProperty.call(resData, resDataKey)) {
			// compThis[dataKey] = resData[resDataKey]
			return {
				status: "OK",
				message: null,
				data: resData[resDataKey],
			};
		}
		errorMsg = [`No valid resData parser for ${dataKey}`, resData];
	}
	return {
		status: "ERR",
		message: errorMsg,
		data: null,
	};
}

const mixin = {
	data() {
		return {
			__globalScrollBusy: false,
		};
	},
	computed: {
		...mapState({
			miniSidebarNavWidth: (state) => state.miniSidebarNavWidth,
			isMobileView: (state) => state.isMobileView,
			globalWebsocket: (state) => state.globalWebsocket,
			enablementModule: (state) => state.enablementModule || {},
		}),
		...mapGetters({
			projectGenDomainLink: "moduleProject/projectGenDomainLink",
		}),
	},
	methods: {
		...mapActions({
			openModernModalCompat: "openModernModalCompat",
			closeModernModalCompat: "closeModernModalCompat",
			openPopupStorage: "modulePopup/openPopupStorage",
			closePopupStorage: "modulePopup/closePopupStorage",
			updatePopupStorage: "modulePopup/updatePopupStorage",
			openToastStorage: "moduleToast/openToastStorage",
			closeToastStorage: "moduleToast/closeToastStorage",
			openContextMenuStorage: "moduleContext/openContextMenuStorage",
			closeContextMenuStorage: "moduleContext/closeContextMenuStorage",
			setSelectedItemStore: "moduleEditor/setSelectedItemStore",
			expandTreeParentPaths: "moduleFileTree/expandTreeParentPaths",
			getAllUserConfig: "getAllUserConfig",
			getUserConfigKey: "getUserConfig",
			setUserConfig: "setUserConfig",
			deleteUserConfig: "deleteUserConfig",
		}),
		openToastWrap(msg, type = "info") {
			if (!msg) {
				console.warn("Message has to be provided");
				return;
			}
			const tPar = {
				data: {
					type: type,
					text: msg,
				},
			};
			this.openToastStorage(tPar);
		},
		openToastInfoMsg(msg) {
			this.openToastWrap(msg, "info");
		},
		openToastSuccessMsg(msg) {
			this.openToastWrap(msg, "success");
		},
		openToastErrorMsg(msg) {
			this.openToastWrap(msg, "error");
		},
		openToastWarningMsg(msg) {
			this.openToastWrap(msg, "warning");
		},
		async closeDirtyFileEditor(files, funcToInvokeOnClose, funcParam) {
			// File dirty don't close
			const modalProps = {
				data: {
					comp: "FileDirtyModal",
					allowWrapClose: false,
					style: {
						width: "500px",
						height: "300px",
					},
					options: {
						header: this.$i18n.t("file.fileNotSaved"),
						files,
						funcToInvokeOnClose,
						funcParam,
					},
				},
			};
			this.openModernModalCompat(modalProps);
		},
		async setupSelectedItemTree(payloadObj) {
			const {
				itemObj,
				isSaveMonacoState,
				isSaveMonacoView,
				gotoLineNumber = -1,
				isSetUserTabConfig = true,
				isPreloadOnly = false,
				isExpandChildren = false,
			} = payloadObj;
			if (isSaveMonacoState !== false) {
				// console.warn('Saving monaco file');
				eventBus.$emit("trigger-save-state");
			} else if (isSaveMonacoView !== false) {
				eventBus.$emit("trigger-save-view");
			}

			// Send data to store and open it || switch to it [tab]
			const marsFileObj = itemObj;
			const fileFullPath = marsFileObj.fullPath;

			const payload = {
				file: marsFileObj,
				opt: {
					isPreloadOnly,
				},
			};

			const isCreated = await this.setSelectedItemStore(payload);

			if (!isPreloadOnly && isExpandChildren) {
				await this.expandTreeParentPaths(fileFullPath);
			}

			if (gotoLineNumber !== -1) {
				// Used for editor
				this.$nextTick(() => {
					setTimeout(() => {
						// Has to wait for the editor to load before [goto]
						eventBus.$emit("editor-goto-line", gotoLineNumber);
					}, 1000);
				});
			}

			if (isCreated && isSetUserTabConfig) {
				// Save editor tab config API
				eventBus.$emit("set-user-editor-tab-config");
			}

			if (!isPreloadOnly) {
				eventBus.$emit("scroll-to-tab-and-node", fileFullPath);
			}
		},
		writeResToData(resData, resDataKey, dataKey, compThis) {
			const parsedData = parseDataForWrite(resData, resDataKey, dataKey);
			if (parsedData.status !== "ERR") {
				compThis[dataKey] = parsedData.data;
			} else if (parsedData.message) {
				console.warn(parsedData.message);
			}
		},
		appendResToData(resData, resDataKey, dataKey, compThis) {
			// For arrays only
			const parsedData = parseDataForWrite(resData, resDataKey, dataKey);
			const currData =
				Array.isArray(compThis[dataKey]) && compThis[dataKey].length;
			const newData = Array.isArray(parsedData.data) && parsedData.data.length;
			if (parsedData.status !== "ERR" && currData && newData) {
				// console.log(parsedData.data, compThis[dataKey]);

				compThis[dataKey].push(...parsedData.data);
			} else if (parsedData.message) {
				console.warn(parsedData.message);
			}
		},
		transformUserConfigKey(inputKey, replacementArray) {
			let cleanedKey = inputKey;
			if (
				cleanedKey &&
				Array.isArray(replacementArray) &&
				replacementArray.length
			) {
				for (const [index, strToReplace] of replacementArray.entries()) {
					const regex = new RegExp(`%${index}`, "gi");
					cleanedKey = cleanedKey.replace(regex, strToReplace);
				}
			}
			if (!inputKey) {
				console.warn("<< No input key for user config >>");
				return null;
			}
			return cleanedKey;
		},
		getUserConfig(inputKey, ...optionalReplace) {
			const cleanedKey = this.transformUserConfigKey(inputKey, optionalReplace);
			return this.getUserConfigKey(cleanedKey);
		},
		mutateUserConfigArray(inputKey, value, ...optionalReplace) {
			// For setting user config
			const cleanedKey = this.transformUserConfigKey(inputKey, optionalReplace);
			const valueString = JSON.stringify(value);

			const kvObj = {
				key: cleanedKey,
				value: valueString,
			};
			// if (array) {
			//   array.push(kvObj)
			//   return null
			// }
			return [kvObj];
		},
		onScrollCheckLogic(evt, ratioToCheck = 95, delay = 500) {
			if (!this.scrollInvoke) {
				console.warn("No scrolling logic implemented");
				return;
			}

			if (this.__globalScrollBusy || !evt) {
				return;
			}

			const scrollTop = evt.target.scrollTop;
			const scrollHeight = evt.target.scrollHeight;
			const clientHeight = evt.target.clientHeight;
			const totalScrollDist = scrollHeight - clientHeight;

			const ratioScrolled = Math.round(scrollTop / (totalScrollDist / 100));
			if (ratioScrolled > ratioToCheck) {
				this.__globalScrollBusy = true;
				this.scrollInvoke();

				setTimeout(() => {
					this.__globalScrollBusy = false;
				}, delay);
			}
		},
		async clipboardCopyFunc(textToCopy) {
			try {
				await navigator.clipboard.writeText(textToCopy);
				this.openToastInfoMsg("Text copied to clipboard");
			} catch (err) {
				// No permission
				this.openToastWarningMsg("Could not copy text");
				console.log("[Clipboard] Could not copy:", err, textToCopy);
			}
		},
		clipboardCopyUrl(fullPath = "", protocol = "") {
			const url = new URL(`${this.projectGenDomainLink}/${fullPath}`);
			if (protocol) {
				url.protocol = protocol;
			}
			this.clipboardCopyFunc(url.href);
		},
	},
};

export default mixin;
