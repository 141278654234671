/* eslint-disable promise/prefer-await-to-then */
import { instance as axios } from "./config.js";

export default {
	// Auth
	authLogin: (params) => axios.post("auth/login", params), // Works with 2FA
	registerApi: (params) =>
		axios({
			url: "signup",
			method: "POST",
			data: params,
		}),
	validateToken: (params) =>
		axios({
			url: "verify-email",
			method: "POST",
			data: params,
		}),
	logoutApi: (sid) =>
		axios({
			url: "logout",
			method: "POST",
			data: {
				sid,
			},
		}),
	forgotPass: (params) =>
		axios({
			url: "password-reset",
			params,
		}),
	resetPass: (params) =>
		axios({
			url: "password-reset",
			method: "PATCH",
			data: {
				params,
			},
		}),
	checkPrivilege: (params) =>
		axios({
			url: "check-privilege",
			params,
		}),
	checkUser: (params) =>
		axios({
			url: "check-user",
			params,
		}),

	// Enablement API
	getEnablements: (params) => axios.get("enablement-module", { params }),

	// Projects
	getProjects: (params) => axios.get("projects", { params }),
	postProjects: (params) => axios.post("projects", params),
	getProjectData: (id) => axios.get(`projects/${id}`),
	updateProjectData: (params) =>
		axios.put(`projects/${params.id}`, params.payload),
	deleteProject: (params) =>
		axios.delete(`projects/${params.id}`, { params: params.payload }),

	// Sites
	reqProjectSiteOptions: (params) =>
		axios.get(`projects/${params.id}/site_options`, { params: params.body }),
	postSites: (params) => axios.post("sites", params),

	getSitesList: (params) => axios.get("sites", { params }),
	reqSiteData: (siteID) => axios.get(`sites/${siteID}`),
	getSiteMetainfo: () => axios.get(`sites/info`),
	getProjectUsers: () => axios.get("user-sites"),
	// Select DB
	postSelectDB: (params) =>
		axios({
			url: "select-db",
			method: "POST",
			data: params,
		}),
	// Tree
	getTreeData: (params) => axios.get("tree-v2", { params }),
	// Search file
	getSearchTree: (params) =>
		axios({
			url: "search-files",
			params,
		}),
	getDashboardData: (params) =>
		axios({
			url: "dashboard/siteStats",
			params,
		}),
	// Check file if exist
	checkPathName: (params) =>
		axios.get("check-path", {
			params: params.data,
			signal: params.signal,
		}),

	// Refresh session
	expandSession: (params) => {
		if (params.cancelToken) {
			return axios.get("expand-session", {
				params: params.data,
				cancelToken: params.cancelToken,
			});
		}
		return axios.get("expand-session", { params: params.data });
	},

	// Get Site config
	getSiteConfig: (params) =>
		axios({
			url: "site-config",
			params,
		}),

	// Rename project
	renameAlias: (params) => axios.patch("alias", params),
	getDatabaseData: (params) => axios.get("databases", { params }),
	getSiteOptions: (params) => axios.get("sites/options", { params }),

	// #region File
	// Get File Lock
	getFileLock: (params) =>
		axios({
			url: "file-lock",
			params,
		}),

	// Get File Info
	getFile: (params) =>
		axios({
			url: "file",
			params,
		}),
	// Used for restore versions
	postPreviousVersionFile: (params) =>
		axios({
			url: "file",
			method: "POST",
			data: params,
		}),
	// Delete file
	deleteTreeFile: (params) =>
		axios({
			url: "file",
			method: "DELETE",
			data: params,
		}),

	// Get file meta info
	getFileMetaInfo: (params) =>
		axios({
			url: "file/info",
			params,
		}),

	// Save file
	postFile: (params) =>
		axios({
			url: "files",
			method: "POST",
			data: params.data,
			cancelToken: params.cancelToken,
		}),
	// Rename file
	renameMoveTreeFile: (params) =>
		axios({
			url: "files",
			method: "PUT",
			data: params,
		}),
	deleteAllTreeFiles: (params) =>
		axios({
			url: "files",
			method: "DELETE",
			params,
		}),
	getFileErrors: (params) =>
		axios({
			url: "file/last-error",
			params,
		}),

	// Tickets
	getTickets: (params) =>
		axios({
			url: "ticketsProxy/tickets",
			params,
		}),
	getTaskInfoData: (params) =>
		axios({
			url: `ticketsProxy/tickets/${params.id}`,
			params,
		}),
	postTicket: (params) =>
		axios({
			url: "ticketsProxy/tickets",
			method: "POST",
			data: params,
		}),
	patchTicket: (params) =>
		axios({
			url: `ticketsProxy/tickets/${params.id}`,
			method: "PATCH",
			data: params,
		}),

	// Ticket messages
	getTicketMessages: (params) =>
		axios({
			url: `ticketsProxy/tickets/${params.id}/messages`,
			params,
		}),
	postTicketMessages: (params) =>
		axios({
			url: `ticketsProxy/tickets/${params.id}/messages`,
			method: "POST",
			data: params.fd,
		}),
	putTicketMessages: (params) =>
		axios({
			url: `ticketsProxy/tickets/${params.id}/messages`,
			method: "PUT",
			data: params.fd,
		}),

	// User settings
	getAllUserConfig: (params) =>
		axios({
			url: "userconfig",
			params,
		}),
	getUserConfig: (key) =>
		axios({
			url: `userconfig/${key}`,
		}),
	setUserConfig: (params) =>
		axios({
			url: "userconfig",
			method: "PUT",
			data: params,
		}),
	deleteUserConfig: (params) =>
		axios({
			url: "userconfig",
			method: "DELETE",
			params,
		}),
	// #endregion

	// Fetch previous versions
	postGetPreviousVersions: (data) =>
		axios({
			url: "previous-versions",
			method: "POST",
			data,
		}),

	// settings page
	// settings transfer - add site
	// api_v2/transfer/transfer-settings
	addTransfer: (params) =>
		axios({
			url: "transfer/transfer-settings",
			method: "POST",
			data: params,
		}),
	transferPrepare: () =>
		axios({
			url: "transfer/prepare",
			method: "POST",
		}),
	// begin transfer
	beginTransfer: () =>
		axios({
			url: "transfer/transfer",
			method: "POST",
		}),
	// check transfer
	checkTransfer: () =>
		axios({
			url: "transfer/transfer",
		}),
	// get transfers
	getTransfers: (params) =>
		axios({
			url: "transfer/transfer-settings",
			params,
		}),
	// http://admin-t.mars-server.net/api_v2/test/testDP/compareSiteDb3
	// http://admin_t.mars-t2.mars-hosting.com/test/testDP/compareSiteDb3
	checkDatabases: (params) =>
		axios({
			url: "transfer/compare-db",
			method: "POST",
			data: params,
		}),
	// check databases differences
	// getDifferences: params => axios({
	//   url: '',
	//   params
	// }),
	// settings - Get Site init scripts
	getSiteInitScripts: () =>
		axios({
			url: "init-scripts",
		}),

	// settings - add script
	addScript: (params) =>
		axios({
			url: "init-scripts",
			method: "PUT",
			data: params,
		}),

	// settings - add config
	addConfig: (params) =>
		axios({
			url: "site-config",
			method: "POST",
			data: params,
		}),

	// settings - file system
	getFileSystemFiles: () =>
		axios({
			url: "file-system/files",
		}),

	// settings - delete files
	deleteFileSystemFiles: (params) =>
		axios({
			url: "file-system/files",
			method: "DELETE",
			data: params,
		}),

	// settings - get domains
	getDomains: (params) =>
		axios({
			url: "domains",
			params,
		}),

	// settings - create domain
	createDomain: (params) =>
		axios({
			url: "domains",
			method: "POST",
			data: { params },
		}),

	// settings - add certificate
	// id, type ( own ili free)
	// if own - certificate, private_key
	addCertificate: (params) =>
		axios({
			url: "domain-cert",
			method: "POST",
			params,
		}),

	// settings - delete domain
	deleteDomain: (params) =>
		axios({
			url: "domains",
			method: "DELETE",
			params,
		}),

	// settings - get users
	getUsers: (params) =>
		axios({
			url: "owner/users",
			params,
		}),

	// settings - get events
	getSiteEvents: (params) =>
		axios({
			url: "site-events",
			params,
		}),

	// settings - add event
	addSiteEvent: (params) =>
		axios({
			url: "site-events",
			method: "POST",
			data: params,
		}),

	// settings remove event
	removeSiteEvent: (params) =>
		axios({
			url: "site-events",
			method: "DELETE",
			data: params,
		}),

	// Profile page:
	// profile - get user info
	getUserInfo: () =>
		axios({
			url: "user-info",
		}),

	// profile - update user info
	updateUserInfo: (params) =>
		axios({
			url: "update-profile",
			method: "PATCH",
			data: params,
		}),

	// profile - set avatar
	updateUserAvatar: (params) =>
		axios({
			url: "update-profile/avatar",
			method: "PATCH",
			data: params,
		}),

	// profile - get avatar:
	getUserAvatar: (id) =>
		axios({
			url: `user/${id}/avatar`,
		}),

	// profile - get work hours:
	getWorkHours: (params) =>
		axios({
			url: "dashboard/userSessions",
			method: "GET",
			params,
		}),

	// Errors page:
	// Errors - get site errors
	getSiteErrors: (params) =>
		axios({
			url: "site-errors",
			params,
		}),

	// profile - update user info
	getErrors: (params) =>
		axios({
			url: "errors2",
			params,
		}),

	// Database page:
	// Database - get site tables
	getSiteTables: (params) =>
		axios({
			url: "db/tables1",
			params,
		}),

	// Database - get site views
	getSiteViews: (params) =>
		axios({
			url: "db/views",
			method: "GET",
			params,
		}),

	// Database - get single site view
	getSiteView: (params) =>
		axios({
			url: `db/views/${params.name}`,
			method: "GET",
		}),

	// Database - get site functions and procedures
	getSiteFunctionsAndProcedures: (params) =>
		axios({
			url: "db/routines",
			method: "GET",
		}),

	// Database - create site function or procedure
	setSiteFunctionOrProcedure: (params) =>
		axios({
			url: "db/routines",
			method: "POST",
			data: { createOptions: params },
		}),

	// Database - get site function or procedure
	getSiteFunctionOrProcedure: (params) =>
		axios({
			url: `db/routines/${params.name}`,
			method: "GET",
		}),

	// Database - alter site function or procedure
	alterSiteFunctionOrProcedure: (params) =>
		axios({
			url: `db/routines/${params.name}`,
			method: "POST",
			data: params,
		}),

	// Database - delete site function or procedure
	deleteSiteFunctionOrProcedure: (params) =>
		axios({
			url: `db/routines/${params.name}`,
			method: "DELETE",
			params,
		}),

	// Database - get site functions
	getSiteFunctions: (params) =>
		axios({
			url: "db/functions",
			mathod: "GET",
			params,
		}),

	// Database - get site procedures
	getSiteProcedures: (params) =>
		axios({
			url: "db/procedures",
			method: "GET",
			params,
		}),

	// Database - get single site view
	getSiteProcedure: (params) =>
		axios({
			url: `db/procedures/${params.name}`,
			method: "GET",
		}),

	// Database - get table data
	getTableData: (params) =>
		axios({
			url: "db/tables/info",
			params,
		}),

	// Database - get collation
	getCollation: (params) =>
		axios({
			url: "/db/collation",
			params,
		}),

	// Database - query
	getQueryResult: (params) =>
		axios({
			url: "/db/query",
			method: "POST",
			data: params,
		}),

	// Database - create trigger
	setTrigger: (params) =>
		axios({
			url: "/db/triggers",
			method: "POST",
			data: params,
		}),

	// Database - alter trigger
	alterTrigger: (params) =>
		axios({
			url: `db/triggers/${params.name}`,
			method: "POST",
			data: params,
		}),

	// Database - delete trigger
	deleteTrigger: (params) =>
		axios({
			url: "/db/triggers",
			method: "DELETE",
			data: params,
		}),

	// fetch all files in current project
	fetchAllFiles: (params) =>
		axios({
			url: "export",
			params,
		}),

	// downloads a single file
	forceDownload: (params) => {
		const id = params.file.id;
		const config = params.config;
		const data = {
			id,
		};
		return axios
			.post("export", data, config)
			.then((response) => {
				return response.data;
			})
			.catch((e) => {
				throw e;
			});
	},

	fetchSelected: (path) => {
		return axios.get("for-export", {
			// for-export(get)
			params: {
				path,
			},
		});
	},

	postImportAppend: (params) =>
		axios({
			url: "importAppend",
			method: "POST",
			headers: {
				"Content-Type": "multipart/form-data",
			},
			data: params,
		}),

	import: (obj) => {
		return axios.post("import", obj.file, obj.config);
	},

	importAppend: (obj) => {
		return axios.post("importAppend", obj.file, obj.pathId, obj.config);
	},

	getBackupKey: (params) => axios.get("db/backup/credentials", params),

	checkCertificate: (params) => axios.post("domain-cert/check", params),

	// Object Storage
	getFileFromPath: (path) =>
		axios.get("object-storage/getList", {
			params: {
				path,
			},
		}),
	deleteOSFile: (path) =>
		axios.delete("object-storage/file", {
			data: {
				path,
			},
		}),

	fileUploadToObjectStorage: (params) =>
		axios({
			url: "object-storage/file",
			method: "POST",
			data: params,
		}),

	// super-admin region
	// Fetches all sites on MARS
	suadmin_fetchSites: (params) => {
		return axios
			.get("admin/site", { params })
			.then((response) => {
				if (response.data.result === "OK") {
					return response.data;
				}
			})
			.catch((e) => {
				throw e;
			});
	},
	// Create new site
	suadmin_createSite: (params) => {
		return axios
			.post("admin/site", { site: params })
			.then((response) => {
				if (response.data.result === "OK") {
					return response.data;
				}
			})
			.catch((e) => {
				throw e;
			});
	},
	// Edit site:
	suadmin_editSite: (params) => {
		console.log(params, "edit site params");
		return axios
			.patch("admin/site", { site: params })
			.then((response) => {
				return response.data.result === "OK"
					? Promise.resolve(response.data)
					: Promise.reject(response.data);
			})
			.catch((e) => {
				throw e;
			});
	},
	// Delete site
	suadmin_deleteSite: (params) => {
		return axios
			.delete("admin/site", { params })
			.then((response) => {
				return response.data.result === "OK"
					? Promise.resolve(response.data)
					: Promise.reject(response.data);
			})
			.catch((e) => {
				throw e;
			});
	},
	// Returns all users on a MARS site
	suadmin_fetchUsersSite: (params) => {
		return axios
			.get("admin/user", { params })
			.then((response) => {
				if (response.data.result === "OK") {
					return response.data;
				}
			})
			.catch((e) => {
				throw e;
			});
	},
	// Modifies user access to site
	suadmin_siteAccess: (params) => {
		// action can be 'add' or 'remove'
		if (!params.action) {
			params.action = "add";
		}
		return axios
			.put("admin/user", { user: params })
			.then((response) => {
				if (response.data.result === "OK") {
					return response.data;
				}
			})
			.catch((e) => {
				throw e;
			});
	},
	// Modifies user information
	suadmin_userInfo: (params) => {
		return axios
			.patch("admin/user", params)
			.then((response) => {
				if (response.data.result === "OK") {
					return response.data;
				}
			})
			.catch((e) => {
				throw e;
			});
	},
	// Deletes a user from the system
	suadmin_userDelete: (params) => {
		return axios
			.delete("admin/user", { params })
			.then((response) => {
				if (response.data.result === "OK") {
					return response.data;
				}
			})
			.catch((e) => {
				throw e;
			});
	},
	// Creates a user on the system
	suadmin_userCreate: (params) => {
		return axios
			.post("admin/user", { user: params })
			.then((response) => {
				if (response.data.result === "OK") {
					return response.data;
				}
			})
			.catch((e) => {
				throw e;
			});
	},
	// Lists DB users for a site
	suadmin_dbUsersFetch: (params) => {
		return axios
			.get("db-users", { params })
			.then((response) => {
				if (response.data.result === "OK") {
					return response.data;
				}
			})
			.catch((e) => {
				throw e;
			});
	},
	// Creates a DB user on the site
	suadmin_dbUserCreate: (params) => {
		// site - 628v121
		return axios
			.post("db-users", { user: params })
			.then((response) => {
				if (response.data.result === "OK") {
					return response.data;
				}
			})
			.catch((e) => {
				throw e;
			});
	},
	// Deletes a DB user on the site
	suadmin_dbUserDelete: (params) => {
		const res = {
			user: params,
		};
		return axios
			.delete("db-users", { data: res })
			.then((response) => {
				if (response.data.result === "OK") {
					return response.data;
				}
			})
			.catch((e) => {
				throw e;
			});
	},

	// DbUsers
	getDbUsers: (params) => axios.get(`sites/${params.id}/dbusers`, { params }),
	postDbUsers: (params) => axios.post(`sites/${params.id}/dbusers`, params),
	putDbUsers: (params) => axios.put(`sites/${params.id}/dbusers`, params),
	deleteDbUsers: (params) =>
		axios.delete(`sites/${params.id}/dbusers`, { params }),

	// Get databases
	suadmin_databases: () => {
		return axios
			.get("admin/databases")
			.then((response) => {
				if (response.statusText === "OK") {
					return response.data;
				}
			})
			.catch((e) => {
				throw e;
			});
	},

	// end super-admin region

	// fetches recovery data

	fetchRecoveryRange: (range) =>
		axios({
			url: "/uros/chart-data",
			params: { range },
		}),

	// fetches recovery date range
	fetchAllDates: () =>
		axios({
			url: "/uros/all-dates",
		}),

	// recovers filesystem to date
	recoverFiles: (date) =>
		axios({
			url: "files",
			method: "PATCH",
			data: { date },
		}),

	// gets recovery points
	getRecoveryDataPoints: () =>
		axios({
			url: "/recoverypoints",
		}),

	createDatapoint: (data) =>
		axios({
			url: "/recoverypoints",
			method: "POST",
			data,
		}),

	editDatapoint: (data) =>
		axios({
			url: "/recoverypoints/" + data.rcp_id,
			method: "PUT",
			data,
		}),

	deleteDatapoint: (rcp_id) =>
		axios({
			url: "/recoverypoints/" + rcp_id,
			method: "DELETE",
		}),

	// Copy files [file tree - editor]
	copyFileNodeTree: (params) => axios.post("file/copy", params),

	// Tasks
	getAvailableUsers: (params) => axios.get("user/availableUsers", { params }),
	getAvailableSites: (params) => axios.get("user/availableSites", { params }),

	// Templates
	getTemplateList: (params) => axios.get("templates", { params }),
	postTemplateList: (params) => axios.post("templates", params),

	// Payments (Bundles)
	getServicesBundles: (params) => axios.get("services/bundles", { params }),
	postCreateUserServices: (params) => axios.post("userservices", params),
	deleteUserServices: (id) => axios.delete(`userservices/${id}`),
	postChangeUserServices: (params) =>
		axios.put("userservices/active_bundle", params),
	postApproveUserServices: (id) => axios.post(`userservices/${id}/approve`),
	getPaymentOptions: (params) =>
		axios.get("payments/paypal/payment_options", { params }),

	// Settings
	putAddRemoveUsers: (params) => axios.put("sites/add_remove_user", params),

	// Power-ups
	getPowerUps: (params) => axios.get("powerups", { params }),
	postPowerUps: (params) =>
		axios.post(`projects/${params.sit_id}/powerup_email`, params),

	// ImportExport [Nodejs]
	getExportNodejs: (params) =>
		axios.get("nodejs/export", { params, responseType: "blob" }),

	// Site [Restart]
	postSiteRestart: (params) => axios.post(`sites/restart`, params),

	// Two factor Auth
	getTwoFactor: (p) => axios.get(`auth/two-factor`, { params: p }),
	deleteTwoFactor: (p) => axios.delete(`auth/two-factor`, { params: p }),
	postTwoFactor: (p) => axios.post(`auth/two-factor`, p),
	postTwoFactorVerify: (p) => axios.post(`auth/two-factor/verify`, p),

	// Proxycall test
	getIframeBlob: (p) => axios.get(p, { responseType: "blob" }),

	// New export
	getExportParamList: (p) =>
		axios.get("export-import/download/param-list", { params: p }),
	getExportDownloadFiles: (p) => axios.get("export-import/download", p),
};
