import Command from "../AbstractCommand.js";

/*
  INTERFACE:

  INPUT:
    table: Processable schema table
    Table example:
    table: {
      name,
      collation,
      columns: [
        {
          name,
          type,
          length,
          options: {
            unsigned: boolean
            notNull,
            autoIncrement,
            binary,
            zerofill
          },
          default,
          collation
        }
      ]
    }
*/

class CreateTableCommand extends Command {
	// mora da primi kao podatak: naziv tabele, kolone
	constructor(table) {
		super("CreateTable");
		this.table = {
			name: table.name,
			collation:
				table.collation && table.collation !== "" ? table.collation : "DEFAULT",
			columns: table.columns,
			comment: table.comment,
		};
	}

	execute() {
		// Start create table
		let ddl = `CREATE TABLE \`${this.table.name}\` (\n`;

		// Check PrimaryKey
		let havePrimaryKey = false;
		let pkCol = findPrimaryKeyColumns(this.table.columns);
		if (pkCol.length > 0) havePrimaryKey = true;

		// Init columns
		for (let i = 0; i < this.table.columns.length; i++) {
			ddl += initColumn(this.table.columns[i]);
			if (havePrimaryKey || i !== this.table.columns.length - 1)
				// If I have primary key print always, othervise print for every column except last one
				ddl += ",";
			ddl += "\n";
		}

		// Primary key
		if (havePrimaryKey) {
			ddl += "PRIMARY KEY (";
			for (let i = 0; i < pkCol.length; i++) {
				ddl += "`" + pkCol[i].name + "`";
				if (i !== pkCol.length - 1) ddl += ", ";
			}
			ddl += ")\n";
		}

		// End create table
		ddl += ")";

		// Table options
		console.log(this.table);
		// Comment
		if (this.table.comment) {
			ddl += `\nCOMMENT = '${this.table.comment}'`;
		}
		// Collation
		if (
			this.table.collation.toLowerCase() !== "schema default" &&
			this.table.collation.toLowerCase() !== "default"
		) {
			ddl += `\nDEFAULT CHARACTER SET = '${
				this.table.collation.split("_")[0]
			}'\nCOLLATE = '${this.table.collation}'`;
		}
		return ddl;
	}
}

// private functions
function initColumn(columnData) {
	let ddl = `\`${columnData.name}\` ${columnData.type.name} `;

	// Length
	if (columnData.type.length > 0) {
		ddl += `(${columnData.type.length}`;
		if (columnData.type.decimal && columnData.type.decimal > 0)
			ddl += `,${columnData.type.decimal}`;
		ddl += ") ";
	}

	// Options
	let o = columnData.options;
	if (o.unsigned) ddl += "UNSIGNED ";
	if (o.notNull) ddl += "NOT NULL ";
	// else
	//   ddl += 'NULL '
	if (columnData.default && columnData.default.toUpperCase() !== "NULL")
		ddl += `DEFAULT ${columnData.default} `;
	if (o.autoIncrement) ddl += "AUTO_INCREMENT ";
	if (o.unique) ddl += "UNIQUE ";
	if (o.binary) ddl += "BINARY ";
	if (o.zerofill) ddl += "ZEROFILL";

	// handle column comments
	if (columnData.comment) {
		ddl += `COMMENT '${columnData.comment.replace(/'/g, "\\'")}'`;
	}

	return ddl;
}

function findPrimaryKeyColumns(columns) {
	let pkCol = [];
	for (const column of columns) {
		if (column.options.primaryKey) pkCol.push(column);
	}
	return pkCol;
}

// module.exports = CreateTableCommand
export default CreateTableCommand;
