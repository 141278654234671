<template>
	<div class="modal-wrapper">
		<!-- <div class="modal-title">
      New software settings
    </div> -->
		<div class="section">
			<span class="title">Key:</span>
			<input
				v-model="key"
				type="text"
				@keyup.enter="addConfig"
				@keyup.esc="closeModernModalCompat()"
			/>
		</div>
		<div class="section">
			<span class="title">Value:</span>
			<input
				v-model="value"
				type="text"
				@keyup.enter="addConfig"
				@keyup.esc="closeModernModalCompat()"
			/>
		</div>
		<div class="section actions">
			<button class="btn close inv" @click="closeModernModalCompat()">
				close
			</button>
			<button class="btn create" @click="addConfig()">create</button>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
	name: "SettingsAddConfig",
	data() {
		return {
			key: "",
			value: "",
			params: {
				cfg: {
					created: [],
					deleted: [],
					updated: [],
				},
			},
		};
	},
	methods: {
		...mapActions({
			addConfigAction: "moduleSettings/addConfig",
		}),
		addConfig() {
			if (this.key !== "" && this.value !== "") {
				const obj = {};
				obj.cfg_key = this.key;
				obj.cfg_value = this.value;
				this.params.cfg.created.push(obj);
				this.addConfigAction(this.params)
					.then((response) => {
						this.closeModernModalCompat();
						this.openToastSuccessMsg("Software settings added successfully");
					})
					.catch((e) => {
						this.openToastErrorMsg("Error adding software settings");
					});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/adminModals";
.modal-wrapper {
	@include admin-modals-styles;
}
</style>
