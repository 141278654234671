<template>
	<div class="database-sql-modal-wrapper">
		<div class="editor-wrapper">
			<monaco-editor
				ref="monaco"
				class="editor"
				:options="monacoOptions"
				:language="'sql'"
				:value="sql"
				@change="valueUpdatedFunc"
			/>
		</div>
		<div class="actions-wrapper">
			<button class="btn inv" @click="closeModernModalCompat">Cancel</button>
			<button class="apply-btn btn" @click="applySql">Apply</button>
		</div>
	</div>
</template>

<script>
import api from "@/api";
import MonacoEditor from "@/modules/shared/monaco-editor/MonacoEditor.vue";
import Generator from "../ddl-Generator/DDLGenerator.js";
import { mapState, mapActions } from "vuex";
import eventBus from "@/mixins/eventBus.js";
export default {
	name: "DatabseSqlModal",
	components: {
		MonacoEditor,
	},
	props: ["options"],
	data() {
		return {
			monacoOptions: {
				theme: "vs-dark",
				showUnused: true,
				mouseWheelZoom: true,
				automaticLayout: true,
				readOnly: false,
			},
			sql: "",
			errorMsg: "",
			functionParams: {},
			res: null,
		};
	},
	computed: {
		...mapState({
			tabs: (state) => state.moduleDatabase.tabs,
			activeTabID: (state) => state.moduleDatabase.activeTabId,
		}),
		activeCompData() {
			for (let i = 0; i < this.tabs.length; i++) {
				if (this.tabs[i].id === this.activeTabID) {
					return this.tabs[i];
				}
			}
			return null;
		},
	},
	mounted() {
		if (this.options && this.options.drop === true) {
			if (this.options.sql) {
				this.sql = this.options.sql;
				this.res = [];
				this.res.push(this.sql);
			} else {
				this.sql = "DROP TABLE `" + this.options.tableName + "`;";
				this.res = [];
				this.res.push(this.sql);
			}
		} else if (this.activeCompData.type === "function") {
			this.sql = this.activeCompData.tabData.sql;
			this.res = [];
			this.res.push(this.sql);
		} else if (this.activeCompData.type === "functions") {
			const acdParams = this.activeCompData.tabData.params;
			const params = {
				body: this.activeCompData.tabData.sql,
				name: acdParams.name,
				returns: acdParams.returns.label,
				type: acdParams.type.value,
				parameters: [],
				characteristics: [],
				toSql: true,
			};
			for (let i = 0; i < acdParams.characteristics.length; i++) {
				params.characteristics.push(acdParams.characteristics[i].type.label);
			}
			for (let i = 0; i < acdParams.parameters.length; i++) {
				const parameter = {
					name: acdParams.parameters[i].name,
					type: acdParams.parameters[i].type.label,
				};
				if (acdParams.type.value === "procedure") {
					parameter.inOut = acdParams.parameters[i].dir.label;
				}
				params.parameters.push(parameter);
			}
			this.functionParams = params;
			api.setSiteFunctionOrProcedure(params).then((res) => {
				if (res.statusText === "OK") {
					this.sql = res.data.message;
				}
			});
			this.monacoOptions.readOnly = true;
		} else if (
			this.options?.hasOwnProperty("alterDataset") &&
			this.options.alterDataset === true
		) {
			this.res = this.options.res;
			this.sql = this.options.sql;
		} else {
			console.log("tu sam");
			const oldObj = {
				schemaCollation: this.activeCompData.oldTabData.schemaCollation,
				tables: {},
			};
			if (this.activeCompData.create !== true) {
				oldObj.tables[this.activeCompData.name] =
					this.activeCompData.oldTabData;
				oldObj.tables[this.activeCompData.name].name =
					this.activeCompData.oldTabData.name;
			}
			const newObj = {
				schemaCollation: this.activeCompData.tabData.schemaCollation,
				tables: {},
			};
			newObj.tables[this.activeCompData.name] = this.activeCompData.tabData;
			newObj.tables[this.activeCompData.name].name =
				this.activeCompData.tabData.name;
			newObj.tables[this.activeCompData.name].collation =
				this.activeCompData.tabData.collation;
			// newObj.tables[this.activeCompData.name].oldEntity = null
			// console.log(oldObj, newObj);
			this.res = Generator.synchronize(oldObj, newObj);
			let ddlToShow = "";
			for (let i = 0; i < this.res.length; i++) {
				if (typeof this.res[i] === "object" && this.res[i] !== null) {
					ddlToShow += this.res[i].ddl;
					this.monacoOptions.readOnly = true;
				} else {
					ddlToShow += this.res[i];
					this.monacoOptions.readOnly = false;
				}
				ddlToShow += "\n";
			}
			this.sql = ddlToShow;
			// this.sql = this.res
		}
	},
	methods: {
		...mapActions({
			changeCreateToEdit: "moduleDatabase/changeCreateToEdit",
			resetRevert: "moduleDatabase/resetRevert",
		}),
		valueUpdatedFunc(newVal) {
			this.sql = newVal;
		},
		applySql() {
			if (this.options?.hasOwnProperty("dropRoutinesParams")) {
				const params = this.options.dropRoutinesParams;
				params.toSql = false;
				api
					.deleteSiteFunctionOrProcedure(params)
					.then((res) => {
						this.openToastSuccessMsg("Success");
						this.closeModernModalCompat();
					})
					.catch((err) => {
						console.log(err);
						this.openToastErrorMsg("Error");
						this.closeModernModalCompat();
					});
			} else if (
				this.activeCompData &&
				this.activeCompData.type === "functions"
			) {
				this.functionParams.toSql = false;
				api
					.setSiteFunctionOrProcedure(this.functionParams)
					.then((res) => {
						this.openToastSuccessMsg("Success");
						this.closeModernModalCompat();
					})
					.catch((err) => {
						console.log(err);
						this.openToastErrorMsg("Error");
						this.closeModernModalCompat();
					})
					.finally(() => {
						eventBus.$emit("reset-sidebar");
					});
			} else {
				let hasObj = false;
				for (var i = 0; i < this.res.length; i++) {
					if (typeof this.res[i] === "object") {
						hasObj = true;
					}
				}
				const sqlToSend = this.sql.split(";");
				for (i = 0; i < sqlToSend.length; i++) {
					if (sqlToSend[i].trim() === "") {
						sqlToSend.splice(i, 1);
					} else {
						sqlToSend[i] = sqlToSend[i] + ";";
					}
				}
				console.log(this.res, sqlToSend);
				const params = {
					commands: hasObj ? this.res : sqlToSend,
				};
				api
					.getQueryResult(params)
					.then((res) => {
						// Check if table is created, change to alter so next sql starts with alter table:
						if (this.activeCompData.create) {
							this.changeCreateToEdit().then(() => {
								eventBus.$emit("reset-sidebar");
							});
						}
						// reset revert for query resultset
						if (
							this.options?.hasOwnProperty("alterDataset") &&
							this.options.alterDataset === true
						) {
							eventBus.$emit("revert-query-dataset");
						}
						// reset revert to new value
						this.resetRevert();
						// this.SET_QUERY_RESULT(res.data.resultset)
						this.openToastSuccessMsg("Success");
						this.closeModernModalCompat();
					})
					.catch((err) => {
						console.log(err);
						this.openToastErrorMsg("Error");
						this.closeModernModalCompat();
					});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.database-sql-modal-wrapper {
	min-width: 40vw;
	height: 60vh;
}
.actions-wrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding: 10px;

	.apply-btn {
		margin-left: 15px;
		// background-color: green;
	}
	// .apply-btn:hover{
	//   background-color: green;
	//   opacity: 0.9;
	// }
}
.modal-header {
	padding: 10px;
	font-size: 20px;
}
.editor-wrapper {
	padding: 10px;
	height: 90%;
	width: 100%;
	position: relative;
	display: flex;

	.editor {
		flex: 1;
		// width: auto;

		// .editor.showUnused {
		//   height: 400px;
		//   // width: auto;
		// }
		// .monaco-editor {
		//   height: 400px;
		//   // width: auto !important;
		// }
		// .overflow-guard {
		//   height: 400px;
		//   // width: auto;
		// }
	}
}
</style>
