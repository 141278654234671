export default {
	props: {
		checked: {
			required: false,
		},
		options: {
			type: Object,
			default: () => {
				return {};
			},
			required: false,
		},
	},
	computed: {
		isDirectChecked() {
			return this.checked !== undefined || this.checked;
		},
		getChecked() {
			if (this.isDirectChecked) {
				return Boolean(this.checked);
			}
			if (this.options?.checked) {
				return Boolean(this.options.checked);
			}
			return null;
		},
		getLeftText() {
			return this.options.text;
		},
		getRightText() {
			return this.options.rightText;
		},
		tabIndexBasedOnDisabled() {
			if (this.options.disabled) {
				return -1;
			}
			return 0;
		},
	},
	methods: {
		changeRefInput() {
			if (!this.options.disabled) {
				this.$refs.input.click();
			}
		},
	},
};
