import AuthPopup from "@/modules/auth/view/AuthPopup.vue";
import FileDirtyModal from "@/modules/editor/components/Modal/FileDirtyModal.vue";
import PasswordModal from "@/modules/profile/components/PasswordModal.vue";
import CreateDatapoint from "@/modules/recovery/components/CreateDatapoint.vue";
import editDatapointComponent from "@/modules/recovery/components/EditDatapoint.vue";
import settingsAddConfig from "@/modules/settings-page/components/settingsModals/settingsAddConfig.vue";
import settingsAddScript from "@/modules/settings-page/components/settingsModals/settingsAddScript.vue";
import SettingsUserEdit from "@/modules/settings-page/components/SettingsUsers/SettingsUserEdit.vue";
import databaseDiffereneces from "@/modules/settings-page/components/settingsModals/databaseDifferences.vue";
import addEvent from "@/modules/settings-page/components/settingsModals/eventsAddEvent.vue";
import addDomain from "@/modules/settings-page/components/settingsModals/domainsAddDomain.vue";
import addCertificate from "@/modules/settings-page/components/settingsModals/domainsAddCertificate.vue";
import DatabaseSqlModal from "@/modules/database-page/components/DatabaseSqlModal.vue";
import SettingsDbUserAdd from "@/modules/settings-page/components/SettingsUsers/SettingsDbUserAdd.vue";
import columnModal from "@/modules/database-page/components/ColumnModal.vue";
import DeleteProjectModal from "@/modules/settings-page/modals/DeleteProjectModal.vue";

export default {
	components: {
		AuthPopup,
		FileDirtyModal,
		PasswordModal,
		CreateDatapoint,
		editDatapointComponent,
		settingsAddConfig,
		settingsAddScript,
		SettingsUserEdit,
		databaseDiffereneces,
		addEvent,
		addDomain,
		addCertificate,
		DatabaseSqlModal,
		SettingsDbUserAdd,
		columnModal,
		DeleteProjectModal,
	},
};
