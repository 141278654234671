import PrioritiesCommander from "./PrioritiesCommander";
import initialPriorities from "./priorities/InitialPriorities.json";

class CommanderFactory {
	//public
	newPrioritiesCommander(priorities = initialPriorities.prioreties) {
		return new PrioritiesCommander(priorities);
	}

	// public
	initialPriorities() {
		return initialPriorities;
	}
}

export default CommanderFactory;
