<template lang="pug">
.activate-acc
	.loading-overlay(v-if="isLoading")
		.loader
	template(v-else)
		template(v-if="isSuccess")
			img(src="@/assets/image/auth/address.svg")
			h2 Thank you!
			div
				span You have verified your email address
		template(v-else)
			h2 Your account has not been verified!
			div
				span Please try again
	router-link(:to="{ name: 'LoginComp' }")
		span Continue to login
</template>

<script>
import { mapActions } from "vuex";
export default {
	data() {
		return {
			isLoading: true,
			isSuccess: false,
		};
	},
	mounted() {
		this.verifyAccount();
	},
	methods: {
		...mapActions({
			validateToken: "moduleAuth/validateToken",
		}),
		async verifyAccount() {
			const params = {
				t: this.$route.query.t,
			};
			try {
				const res = await this.validateToken(params);
				console.log("account verify", res.data);
				this.isSuccess = true;
			} catch (err) {
				console.warn(err.message);
				this.isSuccess = false;
			} finally {
				this.isLoading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.activate-acc {
	display: flex;
	flex-direction: column;
	gap: 20px;
	text-align: center;
	align-items: center;

	@include auth-form;

	.form-group {
		margin: 40px 0 20px;
		text-align: center;
	}

	img {
		width: 100px;
		height: 100px;
	}

	a {
		color: $green;
		text-align: center;
	}

	button {
		height: 46px;
		width: 145px;
	}
}
</style>
