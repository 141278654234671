<template lang="pug">
.input-view(:class="$attrs.class", :style="$attrs.style")
	.input-container(
		:class="[{ 'no-carrots': !(options.type === 'number' && options.showCarrots), disabled: options.disabled }]"
	)
		//- Supports font awesome icons
		label.left-icon(
			v-if="options.leftIcon",
			:for="`${uniqueGen}`",
			:class="`${options.leftIcon}`"
		)
		label.left-icon(v-if="options.svgLeftIcon", :for="`${uniqueGen}`")
			svg.left-icon-svg(
				:style="[{ background: `no-repeat url('` + searchIcon + `') 0% 0% / 100% 100%` }]"
			)
		input(
			v-if="options.isTextArea !== true",
			v-bind="$attrs",
			:id="`${uniqueGen}`",
			ref="inputViewInput",
			:value="getInputValue",
			:type="getType",
			:class="getInputClasses",
			:placeholder="options.placeholder",
			:title="options.title",
			:autocomplete="options.autocomplete",
			:style="options.style",
			:disabled="options.disabled",
			:min="options.min",
			:max="options.max",
			@input="inputHandler",
			@keydown.enter="enterHandler"
		)
		textarea(
			v-else,
			v-bind="$attrs",
			:id="`${uniqueGen}`",
			ref="inputViewInput",
			:value="getInputValue",
			:type="getType",
			:class="getInputClasses",
			:placeholder="options.placeholder",
			:title="options.title",
			:autocomplete="options.autocomplete",
			:style="options.style",
			:disabled="options.disabled",
			:min="options.min",
			:max="options.max",
			@input="inputHandler",
			@keydown.enter="enterHandler"
		)
		template(v-if="options.hasClearButton && options.value")
			span.clear-input.fa.fa-times(@click="clearInput")
</template>

<script>
// 2022-1
import searchIcon from "@/assets/image/icons/SearchIcon.svg";
import { debounce as hDebounce } from "@/helpers/helpers.js";
export default {
	name: "InputView",
	inheritAttrs: false,
	model: {
		prop: "modelValue",
		event: "update:modelValue",
	},
	props: {
		modelValue: {
			required: false,
		},
		options: {
			type: Object,
			default: () => {
				return {};
			},
			required: false,
		},
	},
	emits: ["update:modelValue", "input", "enter", "native-input"],
	data() {
		return {
			uniqueGen: null,
			searchIcon: searchIcon,
		};
	},
	computed: {
		getType() {
			if (this.options.type) {
				return this.options.type;
			}

			return "text";
		},
		getInputValue() {
			if (this.modelValue !== undefined) {
				return this.modelValue;
			}
			return this.options.value;
		},
		getInputClasses() {
			return [
				{
					"left-pad": this.hasLeftImg,
					"right-pad": this.options.hasClearButton,
					"no-align": this.options.noAlign,
					rounded: this.options.theme === "rounded",
					flat: this.options.theme === "flat",
				},
				this.options.theme === "flat-alt" && ["flat", "flat-alt"],
				this.options.theme === "flat-auth" && ["flat", "flat-auth"],
			];
		},
		hasLeftImg() {
			return this.options.leftIcon || this.options.svgLeftIcon;
		},
	},
	mounted() {
		if ("value" in this.$attrs) {
			console.error(
				"Don't use 'value' prop anymore, use 'modelValue' prop",
				this.options,
				this.$props,
			);
		}
		if ("onInputVal" in this.$attrs) {
			console.error(
				"Don't use 'input-val' event anymore, use 'input' event",
				this.options,
				this.$attrs,
			);
		}

		this.genRandomUniqueID();
		this.checkAutofocusEl();
	},
	activated() {
		this.checkAutofocusEl();
	},
	methods: {
		genRandomUniqueID(len = 12) {
			function dec2hex(dec) {
				return ("0" + dec.toString(16)).slice(-2);
			}
			const arr = new Uint8Array((len || 40) / 2);
			window.crypto.getRandomValues(arr);
			this.uniqueGen = "ci-" + Array.from(arr, dec2hex).join("");
		},
		inputHandler(evt) {
			const val = evt.target.value;

			if (this.modelValue === undefined) {
				this.options.value = val;
			}

			// Mostly used for debounce
			this.$emit("native-input", val, evt);
			if (this.options.debounce) {
				// value has to be explicitly sent, because event changes when debounced
				this.callInputDebounce(evt, val);
			} else {
				this.$emit("update:modelValue", val);
				this.$emit("input", val, evt);
			}
		},
		enterHandler() {
			this.$emit("enter", this.options.value);
		},
		clearInput() {
			if (this.modelValue === undefined) {
				this.options.value = "";
			}
			this.$emit("update:modelValue", "");
			this.$emit("input", "", null);
		},
		callInputDebounce: hDebounce(function (evt, value) {
			this.$emit("update:modelValue", value);
			this.$emit("input", value, evt);
		}, 500),
		checkAutofocusEl() {
			if (this.options.autofocus) {
				// Focus el
				// this.$refs.inputViewInput.focus();
				const el = this.$el.querySelector(
					"input:not(:disabled), textarea:not(:disabled)",
				);
				el?.focus();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.input-view {
	.input-container {
		position: relative;

		&.no-carrots {
			input[type="number"]::-webkit-inner-spin-button,
			input[type="number"]::-webkit-outer-spin-button {
				appearance: none;
				margin: 0;
			}

			input[type="number"] {
				appearance: textfield;
			}
		}

		&.disabled {
			.left-icon {
				opacity: 0.5;
			}
		}

		.left-icon {
			position: absolute;
			left: 8px;
			width: 24px;
			height: 100%;
			// padding: 2px 0 0 0;
			color: $dark-silver;
			display: flex;
			align-items: center;

			svg {
				height: 12px;
			}
		}

		input {
			color: $dark-silver;

			&.left-pad {
				padding-left: 28px;
			}
			&.right-pad {
				padding-right: 20px;
			}
			&[type="number"]:not(.no-align) {
				text-align: right;
			}

			// border-bottom: unset;
		}

		// input:focus + .line {
		//   .line-in {
		//     width: 100%;
		//   }
		// }

		.clear-input {
			position: absolute;
			right: 10px;
			height: 100%;
			color: $dark-silver;
			display: inline-flex;
			align-items: center;

			&:hover {
				color: $main-accent-color;
			}
		}
	}
}
.new-site-modal input {
	width: 100%;
}
</style>
