<template>
	<div class="wrapper-for-table">
		<!-- <span class="section-subheader" v-if="hasData">{{database}}</span> -->
		<table-view v-if="hasData" :data="tableData" :elStyle="tableStyle" />
	</div>
</template>

<script>
import missingImage from "@/assets/image/icons/missing.svg";
import differentImage from "@/assets/image/icons/different.svg";
import extraImage from "@/assets/image/icons/extra.svg";
export default {
	name: "TableWrapper",
	props: ["data"],
	data() {
		return {
			tableData: {
				columns: [
					{
						label: "status",
						slot: true,
						tooltip: true,
						name: "status",
						style: {
							color: "#9ea9c1",
							width: "70px",
						},
					},
					{
						label: "type",
						name: "type",
						style: {
							color: "#9ea9c1",
						},
					},
					{
						label: "name",
						name: "name",
						style: {
							color: "#9ea9c1",
						},
					},
					{
						label: "table",
						name: "table",
						style: {
							color: "#9ea9c1",
						},
					},
					{
						name: "dropdown",
						visibility: false,
					},
				],
				rows: [],
			},
			tableStyle: {
				color: "$light-gray",
				marginTop: "0px",
				overflow: "auto",
				height: "100%",
			},
		};
	},
	computed: {
		hasData() {
			return this.tableData.rows.length > 0;
		},
	},
	watch: {
		data: function () {
			this.tableData.rows = [];
			this.setUpTable();
		},
	},
	mounted() {
		console.log(this.data);
		this.setUpTable();
	},
	methods: {
		setUpTable() {
			for (let i = 0; i < this.data.length; i++) {
				const difference = this.data[i];
				// if diff has status then create table diff obj
				if (difference.type === "table" || difference.type === "view") {
					if (difference.details.hasOwnProperty("status")) {
						this.createTableDiff(difference);
					}
					if (difference.details.hasOwnProperty("subDifferences")) {
						// eslint-disable-next-line no-var
						for (var j = 0; j < difference.details.subDifferences.length; j++) {
							this.createDiff(
								difference.details.subDifferences[j],
								difference.name,
							);
						}
					}
				} else if (difference.type === "routine") {
					if (difference.details.hasOwnProperty("status")) {
						this.createRoutineDiff(difference);
					}
					if (difference.details.hasOwnProperty("subDifferences")) {
						for (j = 0; j < difference.details.subDifferences.length; j++) {
							this.createParamDiff(
								difference.details.subDifferences[j],
								difference.name,
							);
						}
					}
				}
			}
		},
		createRoutineDiff(difference) {
			const emptyRow = this.createEmptyRow();
			emptyRow.values.status.value = this.getStatus(difference.details.status);
			emptyRow.values.status.tooltip = difference.details.status;
			emptyRow.values.type.value = difference.type;
			emptyRow.values.name.value = difference.name;
			emptyRow.values.table.value = difference.name;
			this.tableData.rows.push(emptyRow);
		},
		createTableDiff(difference) {
			const emptyRow = this.createEmptyRow();
			emptyRow.values.status.value = this.getStatus(difference.details.status);
			emptyRow.values.status.tooltip = difference.details.status;
			emptyRow.values.type.value = difference.type;
			emptyRow.values.name.value = difference.name;
			emptyRow.values.table.value = difference.name;
			// const details = this.generateDropdownJson(difference.details)
			const details = {};
			if (details && Object.keys(details).length > 0) {
				emptyRow.dropdown = details;
			}
			this.tableData.rows.push(emptyRow);
		},
		createDiff(difference, table) {
			const emptyRow = this.createEmptyRow();
			emptyRow.values.status.value = this.getStatus(difference.status);
			emptyRow.values.status.tooltip = difference.status;
			emptyRow.values.type.value = this.getType(difference.type);
			emptyRow.values.name.value = difference.name;
			emptyRow.values.table.value = table;
			this.tableData.rows.push(emptyRow);
			if (
				difference.hasOwnProperty("differences") &&
				difference.differences !== null
			) {
				emptyRow.dropdown = difference.differences[0];
			}
		},
		createParamDiff(difference, table) {
			const emptyRow = this.createEmptyRow();
			emptyRow.values.status.value = this.getStatus(difference.status);
			emptyRow.values.status.tooltip = difference.status;
			emptyRow.values.type.value = this.getType(difference.type);
			emptyRow.values.name.value = difference.name;
			emptyRow.values.table.value = table;
			this.tableData.rows.push(emptyRow);
		},
		generateDropdownJson(data) {
			const res = {};
			if (data.hasOwnProperty("expected") && data.hasOwnProperty("result")) {
				for (const variable in data.expected) {
					if (data.expected.hasOwnProperty(variable)) {
						console.log(data.result[variable]);
						const expected = "expected: " + data.expected[variable];
						const found =
							"found: " + data.result === null ? "null" : data.result[variable];
						res[variable] = expected + "\n" + found;
					}
				}
			} else {
				console.log("nema details");
			}
			return res;
		},
		getType(str) {
			switch (str) {
				case "columns": {
					return "column";
				}
				case "triggers": {
					return "trigger";
				}
				case "parameters": {
					return "parameter";
				}
				case "tableConstraints": {
					return "table constraint";
				}
				case "columnConstraints": {
					return "column constraint";
				}
				case "referentialConstraints": {
					return "referential constraint";
				}
				default: {
					return "";
				}
			}
		},
		getStatus(str) {
			switch (str) {
				case "missing": {
					const img = document.createElement("img");
					img.style.height = "25px";
					img.style.margin = "8px 0px";
					img.src = missingImage;
					return img.outerHTML;
				}
				case "different": {
					const img = document.createElement("img");
					img.style.height = "25px";
					img.style.margin = "8px 0px";
					img.src = differentImage;
					return img.outerHTML;
				}
				case "extra": {
					const img = document.createElement("img");
					img.style.height = "25px";
					img.style.margin = "8px 0px";
					img.src = extraImage;
					return img.outerHTML;
				}
				// No default
			}
		},
		createEmptyRow() {
			return {
				values: {
					status: {
						name: "status",
						value: null,
					},
					type: {
						name: "type",
						value: null,
					},
					table: {
						name: "table",
						value: null,
					},
					name: {
						name: "name",
						value: null,
					},
					details: {
						name: "details",
						value: null,
					},
				},
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.wrapper-for-table {
	padding-top: 10px;
	height: 100%;

	.table-outer-warp {
		height: 100%;
		display: flex;
		justify-content: center;
	}

	.section-subheader {
		padding-left: 10px;
		font-size: 20px;
		color: #9ea9c1;
	}
}
</style>
