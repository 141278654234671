class SimpleParser {
	/*
        data: {
            oldSchema,
            newSchema
        }

        return {
            oldProcessableSchema,
            newProcessableSchema
        }
     */
	parse(data) {
		let oldProcessableSchema = parseSchema(data.oldSchema);
		let newProcessableSchema = parseSchema(data.newSchema);

		// Set reference between schemas
		setReferenceBetweenTables(oldProcessableSchema, newProcessableSchema);

		return {
			oldProcessableSchema,
			newProcessableSchema,
		};
	}
}

export default SimpleParser;

function parseSchema(schema) {
	let DBObject = {
		tables: [],
		functions: [],
		procedures: [],
		views: [],
		events: [],
		schemaCollation: null,
	};

	if (schema === null) return DBObject;

	if (schema.schemaCollation) DBObject.schemaCollation = schema.schemaCollation;

	// Init tables
	for (const key of Object.keys(schema.tables)) {
		DBObject.tables.push(initTable(schema.tables[key]));
	}

	// Set reference between columns
	for (const table of DBObject.tables) {
		for (const foreignKey of table.foreignKeys) {
			// Set foreign key referenced table
			setForeignKeyReferencedTable(foreignKey, DBObject.tables);

			for (let i = 0; i < foreignKey.columns.length; i++) {
				let column = foreignKey.columns[i];
				let refColumn = foreignKey.referencedColumns[i];

				// Set reffering data to column
				for (const tableColumn of table.columns) {
					if (tableColumn.name === column) {
						setReferingDataToColumn(tableColumn, foreignKey, refColumn);
					}
				}

				// Set referenced data to column
				let referenced = {
					table: table,
					column: "Not supported yet!",
				};
				if (foreignKey.referencedTable.columns) {
					for (const refTabCol of foreignKey.referencedTable.columns) {
						if (refTabCol.name === refColumn) {
							for (let k = 0; k < table.columns.length; k++) {
								let tc = table.columns[k];
								if (tc.name === column) {
									referenced.column = tc;
									refTabCol.referenced.push(referenced);
									break;
								}
							}
						}
					}
				}
			}
		}
	}

	return DBObject;
}

function initTable(tableData) {
	let table = {
		name: tableData.name,
		oldEntity: tableData.oldName,
		newEntity: null,
		collation: tableData.collation,
		comment: tableData.comment ? tableData.comment : null,
		columns: [],
		indexes: [],
		foreignKeys: [],
		triggers: {},
	};

	// Init Columns
	let colOrderNameMap = {};
	let i = 0;
	if (tableData.columns) {
		for (const columnData of tableData.columns) {
			colOrderNameMap[i] = columnData.name;
			let column = {
				name: columnData.name,
				oldEntity: columnData.oldName,
				newEntity: null,
				type: {
					name: columnData.type.type,
					length: columnData.type.length,
					decimal: columnData.type.decimal,
				},
				options: columnData.options,
				default: columnData.default ? columnData.default : null,
				comment: columnData.comment ? columnData.comment : null,
				collation: columnData.collation ? columnData.collation : null,
				indexes: [],
				refering: {},
				referenced: [],
				after: "Not supported yet",
				order: i,
			};
			table.columns.push(column);
			i++;
		}
	}

	// Set after
	for (const column of table.columns) {
		let order = column.order;
		let after = null;
		if (order > 0) after = colOrderNameMap[order - 1];
		if (!after) after = null;
		column.after = after;
	}

	// Init indexes
	if (tableData.indexes) {
		for (const indexData of tableData.indexes) {
			let index = {
				name: indexData.name,
				type: indexData.type,
				columns: [],
				comment: indexData.comment,
			};
			connectIndexexAndColumns(indexData, table, index);
			table.indexes.push(index);
		}
	}

	// Init foreign keys
	if (tableData.foreignKeys) {
		for (const foreignKeyData of tableData.foreignKeys) {
			let foreignKey = {
				constraintName: foreignKeyData.constraintName,
				referencedTable: foreignKeyData.refTable,
				columns: foreignKeyData.columns,
				referencedColumns: foreignKeyData.refColumns,
				onDelete: foreignKeyData.delete,
				onUpdate: foreignKeyData.update,
				index: "Not supported yet!",
			};

			pickIndexForForeignKey(foreignKey, table.indexes, table.name);

			table.foreignKeys.push(foreignKey);
		}
	}

	// init triggers
	table.triggers = initTriggers(tableData.triggers);

	return table;
}

function pickIndexForForeignKey(foreignKey, indexes, tableName) {
	let matchIndexes = [];

	// Poklapanje kolona: Pronalazenje svih indeksa koji odgovaraju po kolonama
	for (const index of indexes) {
		if (foreignKeyColumnsMatchIndexeColumns(foreignKey.columns, index.columns))
			matchIndexes.push(index);
	}
	if (matchIndexes.length === 0) return; // Nema indeksa koju odgovaraju
	if (matchIndexes.length === 1) {
		foreignKey.index = matchIndexes[0]; // Jedini indeks koji moze da odgovara stranom kljucu
		return;
	}

	// Poklapanje tipova: Odstranjivanje svih indeksa koji se ne poklapaju po tipovima
	for (let i = 0; i < matchIndexes.length;) {
		let index = matchIndexes[i];
		if (index.type !== "PRIMARY" && index.type !== "INDEX") {
			matchIndexes.splice(i, 1);
		} else {
			i++;
		}
	}
	if (matchIndexes.length === 0) return; // Nema indeksa koju odgovaraju
	if (matchIndexes.length === 1) {
		foreignKey.index = matchIndexes[0]; // Jedini indeks koji moze da odgovara stranom kljucu
		return;
	}

	// Poklapanje imena: Odstranjivanje svih indeksa koji nemaju odgovarajuce ime
	for (let i = 0; i < matchIndexes.length;) {
		let index = matchIndexes[i];
		let regex = new RegExp("^fk_" + tableName + "_idx_\\d+$");

		if (index.name.match(regex)) {
			i++;
		} else {
			matchIndexes.splice(i, 1);
		}
	}
	if (matchIndexes.length === 1) {
		foreignKey.index = matchIndexes[0]; // Jedini indeks koji moze da odgovara stranom kljucu
		return;
	}

	// Nije pronadjen indeks
}

function setForeignKeyReferencedTable(foreignKey, tables) {
	for (let table of tables) {
		if (table.name == foreignKey.referencedTable) {
			foreignKey.referencedTable = table;
			break;
		}
	}
}

function setReferingDataToColumn(tableColumn, foreignKey, refColumnName) {
	let refering = {
		table: foreignKey.referencedTable,
		column: "Not supported yet!",
		foreignKeyReference: foreignKey,
	};

	if (refering.table.columns) {
		for (let j = 0; j < refering.table.columns.length; j++) {
			let refColumn = refering.table.columns[j];
			if (refColumn.name == refColumnName) {
				refering.column = refColumn;
				break;
			}
		}
	}

	tableColumn.refering = refering;
}

function connectIndexexAndColumns(indexData, table, index) {
	for (const indexColumn of indexData.columns) {
		for (const tableColumn of table.columns) {
			if (tableColumn.name === indexColumn) {
				tableColumn.indexes.push({
					name: indexData.name,
					type: indexData.type,
					comment: indexData.coment,
				});
				index.columns.push(tableColumn);
			}
		}
	}
}

function initTriggers(triggers) {
	if (triggers && triggers.length) return triggers;
	return [];
}

function concatObject(base, obj) {
	for (let i in obj) {
		base[i] = obj[i];
	}
}

function foreignKeyColumnsMatchIndexeColumns(arr1, arr2) {
	if (arr1.length !== arr2.length) return false;
	for (const [i, element] of arr1.entries()) {
		if (element !== arr2[i].name) return false;
	}
	return true;
}

function setReferenceBetweenTables(oldSchema, newSchema) {
	for (const table of newSchema.tables) {
		let tableName = table.name;
		let oldTableName = table.oldEntity;

		let oldEntity;
		if (!oldTableName || oldTableName === tableName)
			// Table is not renamed
			oldEntity = getTableByName(oldSchema.tables, tableName);
		// Table is renamed
		else oldEntity = getTableByName(oldSchema.tables, oldTableName);

		table.oldEntity = oldEntity;

		if (oldEntity) {
			oldEntity.newEntity = table;
			setReferenceBetweenColumns(table.columns, oldEntity.columns);
		} else {
			setColumnsOldEntitesToNull(table.columns);
		}
	}
}

function setColumnsOldEntitesToNull(columns) {
	for (const column of columns) {
		column.oldEntity = null;
	}
}

function setReferenceBetweenColumns(newColumns, oldColumns) {
	for (const column of newColumns) {
		let columnName = column.name;
		let oldColumnName = column.oldEntity;

		let oldEntity;
		if (!oldColumnName || oldColumnName === columnName)
			// Column is not renamed
			oldEntity = getColumnByName(oldColumns, columnName);
		// Column is renamed
		else oldEntity = getColumnByName(oldColumns, oldColumnName);

		column.oldEntity = oldEntity;
		if (oldEntity) oldEntity.newEntity = column;
	}
}

function getTableByName(tables, name) {
	let f = tables.find((table) => table.name === name);
	return f === undefined ? null : f;
}

function getColumnByName(columns, name) {
	let f = columns.find((column) => column.name === name);
	return f === undefined ? null : f;
}
