<template lang="pug">
.inner
	.content
		.text(v-if="parseText") {{ parseText }}
		.text(v-if="parseHtml && !parseText", v-html="parseHtml")
		input(
			type="text",
			:placeholder="popupData.placeholder",
			:value="inputData",
			@input="setInputData",
			@keyup.esc="cancelPopup",
			@keyup.enter="okPopup"
		)
		.error-wrap
			.error(v-if="popupData.hasError") {{ errorMsg }}
	.button-group
		button.btn-cancel.inv(@click="cancelPopup") {{ parseLeftBtn }}
		button.btn-ok.acc(:disabled="popupData.hasError", @click="okPopup") {{ parseRightBtn }}
</template>

<script>
import popupMixin from "./popupMixin.js";
import { mapActions } from "vuex";
export default {
	mixins: [popupMixin],
	props: {
		popupData: Object,
		popupEvents: Object,
	},
	emits: ["close-popup"],
	data() {
		return {
			inputData: "",
		};
	},
	mounted() {
		this.inputData = this.popupData.value;
	},
	methods: {
		...mapActions({
			firePromptPopupEvent: "modulePopup/firePromptPopupEvent",
			firePromptPopupChange: "modulePopup/firePromptPopupChange",
		}),
		cancelPopup() {
			this.$emit("close-popup");
		},
		okPopup() {
			if (this.popupData.hasError) {
				return;
			}
			this.firePromptPopupEvent(this.inputData);
			this.$emit("close-popup");
		},
		setInputData(evt) {
			this.inputData = evt.target.value;
			this.firePromptPopupChange(this.inputData);
		},
	},
};
</script>
