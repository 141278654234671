// import AuthPage from './view/AuthPage.vue';
import ForgotPass from "./components/ForgotPass.vue";
import LoginComp from "./components/LoginComp.vue";
import RegisterComp from "./components/RegisterComp.vue";
import ResetPass from "./components/ResetPass.vue";
import AuthPage from "./view/AuthPage.vue";
import FinishRegister from "./view/FinishRegister.vue";
import ActivateAccount from "./view/ActivateAccount.vue";
import PrivacyPolicy from "./view/PrivacyPolicy.vue";
import TermsService from "./view/TermsService.vue";

const routes = {
	namespace: "auth",
	routes: [
		{
			path: "",
			name: "AuthPage",
			redirect: "auth/login",
			// redirect: 'auth/register',
			component: AuthPage,
			props: true,
			meta: {
				isAuthPage: true, // not used
			},
			children: [
				{
					path: "register",
					name: "RegisterComp",
					component: RegisterComp,
				},
				{
					path: "login",
					name: "LoginComp",
					component: LoginComp,
				},
				{
					path: "forgot",
					name: "ForgotPass",
					component: ForgotPass,
				},
				{
					path: "reset/:email/:token",
					name: "ResetPass",
					component: ResetPass,
				},
				{
					path: "finish-register",
					name: "FinishRegister",
					component: FinishRegister,
				},
				{
					// No namespace
					path: "/activate",
					name: "ActivateAccount",
					component: ActivateAccount,
				},
				// Maybe move to global routes if it needs to be whole pages
				{
					path: "privacypolicy",
					name: "PrivacyPolicy",
					component: PrivacyPolicy,
				},
				{
					path: "terms",
					name: "TermsService",
					component: TermsService,
				},
			],
		},
	],
};

export default routes;
