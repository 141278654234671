import Command from "../AbstractCommand";

class DropTriggerCommand extends Command {
	constructor(trigger) {
		super("DropTrigger");
		this.triggerName = trigger.name;
	}

	execute() {
		return "DROP TRIGGER " + this.triggerName;
	}
}

export default DropTriggerCommand;
