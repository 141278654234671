import api from "@/api";
import dh from "./databaseHelper.js";

const actions = {
	getSiteTables(store, data) {
		return api.getSiteTables(data);
	},
	getTableData(store, data) {
		return api.getTableData(data);
	},
	setSiteTables({ commit }, payload) {
		commit("SET_SITE_TABLES", payload);
	},
	changeTab({ commit }, payload) {
		commit("SET_ACTIVE_TAB", payload);
	},
	addTab({ commit }, payload) {
		commit("ADD_TAB", payload);
	},
	addSelectRowsTab({ commit }, payload) {
		commit("ADD_SELECT_ROWS_TAB", payload);
	},
	removeTab({ commit }, payload) {
		commit("REMOVE_TAB", payload);
	},
	resetDatabase({ commit }) {
		commit("RESET_DATABASE");
	},
	changeCreateToEdit({ commit }) {
		commit("CHANGE_CREATE_TO_EDIT");
	},
	resetRevert({ commit }) {
		commit("RESET_REVERT");
	},
};

const mutations = {
	SET_SITE_TABLES(store, data) {
		store.tables = data;
	},
	SET_ACTIVE_TAB(store, data) {
		store.activeTabId = data;
	},
	ADD_TAB(store, data) {
		if (data) {
			data.id = ++store.autoIncrementId;
			store.tabs.push(data);
			store.activeTabId = store.autoIncrementId;
		} else {
			const tab = dh.generateNewTab(state);
			const newTab = {
				type: tab.type,
				path: tab.path,
				id: ++store.autoIncrementId,
				name: tab.path,
				tabData: {
					sql: "",
					offset: 0,
					tableData: [],
					err: null,
				},
			};
			store.tabs.push(newTab);
			store.activeTabId = store.autoIncrementId;
		}
	},
	ADD_SELECT_ROWS_TAB(store, data) {
		console.log("ovde");
		const tab = dh.generateNewTab(state);
		const newTab = {
			type: data.tabType,
			version: data.type,
			path: tab.path,
			id: ++store.autoIncrementId,
			name: tab.path,
			tabData: {
				sql: data.sql,
				offset: 0,
				tableData: [],
				err: null,
			},
		};
		if (data.params) {
			newTab.tabData.params = data.params;
		}
		let params = {};
		if (data.type === "Function") {
			newTab.name = "function";
		}
		if (data.type === "Procedure") {
			newTab.name = "procedure";
		}
		if (data.type === "Function" && !newTab.tabData.hasOwnProperty("params")) {
			params = {
				type: { label: "Function", value: "function" },
				name: "",
				parameters: [
					{
						dir: "IN",
						id: Math.random().toString(36).slice(2, 7),
						name: "",
						type: { label: "INT", value: "int" },
					},
				],
				returns: { label: "INT", value: "int" },
				characteristics: [
					{
						id: Math.random().toString(36).slice(2, 7),
						type: { label: "DETERMINISTIC", value: "DETERMINISTIC" },
					},
				],
			};
		} else if (
			data.type === "Procedure" &&
			!newTab.tabData.hasOwnProperty("params")
		) {
			params = {
				type: { label: "Stored Procedure", value: "procedure" },
				name: "",
				parameters: [
					{
						dir: "IN",
						id: Math.random().toString(36).slice(2, 7),
						name: "",
						type: { label: "INT", value: "int" },
					},
				],
				returns: { label: "INT", value: "int" },
				characteristics: [
					{
						id: Math.random().toString(36).slice(2, 7),
						type: { label: "DETERMINISTIC", value: "DETERMINISTIC" },
					},
				],
			};
		} else if (newTab.tabData.hasOwnProperty("params")) {
			params = newTab.tabData.params;
		}
		newTab.tabData.params = params;
		store.tabs.push(newTab);
		store.activeTabId = store.autoIncrementId;
	},
	REMOVE_TAB(store, data) {
		if (store.activeTabId === data) {
			const nextId = dh.findNextActivTab(store);
			store.activeTabId = nextId;
		}
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === data) {
				store.tabs.splice(i, 1);
			}
		}
	},
	REVERT(store) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				store.tabs[i].tabData = JSON.parse(
					JSON.stringify(store.tabs[i].oldTabData),
				);
				store.tabs[i].name = store.tabs[i].tabData.name;
				store.tabs[i].path = store.tabs[i].tabData.name;
				store.tabs[i].tableName = store.tabs[i].tabData.name;
			}
		}
	},
	// FOR TABLE INFO UPDATE:
	UPDATE_TABLE_INFO(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				if (data.key === "tableName") {
					store.tabs[i].name = data.value;
					store.tabs[i].path = data.value;
					store.tabs[i].tableName = data.value;
					store.tabs[i].tabData.name = data.value;
				} else {
					store.tabs[i].tabData[data.key] = data.value;
				}
			}
		}
	},
	// FOR COLUMN DETAILS:
	ADD_EMPTY_COL(store, data) {
		console.log(data);
		let tab = null;
		let tabIndex = 0;
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				tab = store.tabs[i];
				tabIndex = i;
			}
		}
		const col = {
			name: tab.tableName + "_newcol",
			type: {
				type: "INT",
				length: null,
				label: "",
			},
			default: "",
			options: {
				primaryKey: false,
				unique: false,
				notNull: false,
				unsigned: false,
				autoIncrement: false,
				zerofill: false,
				generated: false,
				binary: false,
			},
			comment: "",
			collation: {
				name: null,
			},
			id: Math.random().toString(36).slice(2, 7),
			order: data + 1,
			storedName: "",
		};
		// this.newState.tabData.columns.splice(++index, 0, col)
		store.tabs[tabIndex].tabData.columns.splice(data, 0, col);

		const columns = store.tabs[tabIndex].tabData.columns;
		for (const [i, column] of columns.entries()) {
			column.order = i + 1;
		}
	},
	REMOVE_COL(store, data) {
		let columns = null;
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				store.tabs[i].tabData.columns.splice(data - 1, 1);
				columns = store.tabs[i].tabData.columns;
			}
		}
		for (const [i, column] of columns.entries()) {
			column.order = i + 1;
		}
	},
	REORDER_COLUMNS(store, data) {
		// let columnsCopy = null
		// let columns = null
		// let col = null
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				store.tabs[i].tabData.columns = data;
				// columnsCopy = JSON.parse(JSON.stringify(store.tabs[i].tabData.columns))
				// columns = store.tabs[i].tabData.columns
				// col = columnsCopy.splice(data.oldIndex, 1)
				// columnsCopy.splice(data.newIndex, 0, col[0])
			}
		}
		// for (i = 0; i < columnsCopy.length; i++) {
		//   columnsCopy[i].order = i + 1
		// }
		// console.log(columnsCopy, columns);
		// for (i = 0; i < columns.length; i++) {
		//   for (var j = 0; j < columnsCopy.length; j++) {
		//     if (columns[i].id === columnsCopy[j].id) {
		//       columns[i].order = columnsCopy[j].order
		//     }
		//   }
		// }
	},
	UPDATE_COL_CHECKBOX(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				store.tabs[i].tabData.columns[data.index].options[data.key] =
					data.value;
			}
		}
	},
	UPDATE_COL_INPUT(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				switch (data.key) {
					case "type": {
						store.tabs[i].tabData.columns[data.index].type[data.key] =
							data.value;

						break;
					}
					case "length": {
						console.log(data);
						store.tabs[i].tabData.columns[data.index].type[data.key] =
							data.value || 0;

						break;
					}
					case "collation": {
						store.tabs[i].tabData.columns[data.index][data.key].name =
							data.value;

						break;
					}
					default: {
						store.tabs[i].tabData.columns[data.index][data.key] = data.value;
					}
				}
			}
		}
	},
	// FOR INDEXES:
	ADD_INDEX_ROW(store, data) {
		let tabIndex = 0;
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				tabIndex = i;
			}
		}
		const col = {
			non_unique: 1,
			coment: "",
			type: "",
			columns: [],
			name: "index_newcol",
		};
		if (data === 0) {
			store.tabs[tabIndex].tabData.indexes.push(col);
		} else {
			store.tabs[tabIndex].tabData.indexes.splice(++data, 0, col);
		}
	},
	REMOVE_INDEX_ROW(store, data) {
		console.log(data);
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				store.tabs[i].tabData.indexes.splice(data, 1);
			}
		}
	},
	UPDATE_INDEX_CHECKBOX(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				if (
					store.tabs[i].tabData.indexes[data.index].columns.includes(
						data.colName,
					)
				) {
					const ar = store.tabs[i].tabData.indexes[data.index].columns;
					for (const [j, element] of ar.entries()) {
						if (element === data.colName) {
							store.tabs[i].tabData.indexes[data.index].columns.splice(j, 1);
						}
					}
				} else {
					store.tabs[i].tabData.indexes[data.index].columns.push(data.colName);
				}
			}
		}
	},
	UPDATE_INDEX_INPUT(store, data) {
		// console.log(data);
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				store.tabs[i].tabData.indexes[data.index][data.key] = data.val;
			}
		}
	},
	// FOR FOREIGN KEYS:
	UPDATE_FOREIGN_KEYS_INPUTS(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				store.tabs[i].tabData.foreignKeys[data.index][data.key] = data.value;
			}
		}
	},
	UPDATE_FOREIGN_KEYS_SELECT(store, data) {
		// console.log(data);
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				console.log(data);
				if (data.key === "refColumns") {
					store.tabs[i].tabData.foreignKeys[data.index][data.key][
						data.position
					] = data.value;
				} else if (data.key === "refTable") {
					store.tabs[i].tabData.foreignKeys[data.index][data.key].name =
						data.value;
				} else {
					store.tabs[i].tabData.foreignKeys[data.index][data.key] = data.value;
				}
			}
		}
	},
	UPDATE_FOREIGN_KEYS_CHACKBOX(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				console.log(data);
				if (
					store.tabs[i].tabData.foreignKeys[data.index].columns.includes(
						data.colName,
					)
				) {
					const ar = store.tabs[i].tabData.foreignKeys[data.index].columns;
					for (const [j, element] of ar.entries()) {
						if (element === data.colName) {
							store.tabs[i].tabData.foreignKeys[data.index].columns.splice(
								j,
								1,
							);
						}
					}
				} else {
					let columns = JSON.parse(
						JSON.stringify(
							store.tabs[i].tabData.foreignKeys[data.index].columns,
						),
					);
					columns[data.position] = data.colName;
					store.tabs[i].tabData.foreignKeys[data.index].columns = columns;
				}
			}
		}
	},
	ADD_FOREIGN_KEY_ROW(store, data) {
		let tabIndex = 0;
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				tabIndex = i;
			}
		}
		const col = {
			table: store.tabs[tabIndex].name,
			refTable: {
				name: "",
			},
			columns: [],
			refColumns: [],
			constraintName: "fk_newcol",
			position: 1,
			update: "RESTRICT",
			delete: "RESTRICT",
		};
		if (data === 0) {
			store.tabs[tabIndex].tabData.foreignKeys.push(col);
		} else {
			store.tabs[tabIndex].tabData.foreignKeys.splice(++data, 0, col);
		}
	},
	REMOVE_FOREIGN_KEY_ROW(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				store.tabs[i].tabData.foreignKeys.splice(data, 1);
			}
		}
	},
	// FOR TRIGGERS:
	ADD_NEW_TRIGGER(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				let triggerName = store.tabs[i].name + "_" + data.replace(" ", "_");
				const sameEventTriggers = [];
				for (let j = 0; j < store.tabs[i].tabData.triggers.length; j++) {
					if (store.tabs[i].tabData.triggers[j].event === data) {
						sameEventTriggers.push(store.tabs[i].tabData.triggers[j]);
					}
				}
				if (sameEventTriggers.length > 0) {
					triggerName += "_";
					triggerName += sameEventTriggers.length;
				}
				store.tabs[i].tabData.triggers.push({
					name: triggerName,
					event: data,
					isDefined: false,
					action: "",
					id: Math.random().toString(36).slice(2, 7),
				});
			}
		}
	},
	REMOVE_TRIGGER(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				for (let j = 0; j < store.tabs[i].tabData.triggers.length; j++) {
					if (store.tabs[i].tabData.triggers[j].id === data) {
						store.tabs[i].tabData.triggers.splice(j, 1);
					}
				}
			}
		}
	},
	SET_TRIGGER_VALUE(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				for (let j = 0; j < store.tabs[i].tabData.triggers.length; j++) {
					if (store.tabs[i].tabData.triggers[j].id === data.id) {
						store.tabs[i].tabData.triggers[j].action = data.value;
					}
				}
			}
		}
	},
	SET_TRIGGER_NAME(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				for (let j = 0; j < store.tabs[i].tabData.triggers.length; j++) {
					if (store.tabs[i].tabData.triggers[j].id === data.id) {
						store.tabs[i].tabData.triggers[j].name = data.value;
					}
				}
			}
		}
	},
	// QUERY PAGE:
	ADD_QUERY_PAGE_SQL(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				store.tabs[i].tabData.sql = data;
			}
		}
	},
	ADD_QUERY_PAGE_OFFSET(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				store.tabs[i].tabData.offset = data;
			}
		}
	},
	SET_QUERY_RESULT(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				// const formatData = []
				for (const datum of data) {
					// const dataset = {
					//   id:
					//   values: data[i]
					// }
					// formatData.push(dataset)
					datum.marsId = Math.random().toString(36).slice(2, 7);
				}
				// console.log(formatData);
				store.tabs[i].tabData.tableData = data;
				store.tabs[i].tabData.err = null;
			}
		}
	},
	SET_ERR_MSG(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				store.tabs[i].tabData.err = data;
			}
		}
	},
	// FUNCTIONS AND PROCEDURES PAGE:
	CHANGE_PARAM(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				if (data.param === "type") {
					store.tabs[i].tabData.params[data.param] = data.val;
				}
				store.tabs[i].tabData.params[data.param] = data.val;
			}
		}
	},
	CHANGE_PARAMETER(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				store.tabs[i].tabData.params.parameters[data.index][data.param] =
					data.val;
			}
		}
	},
	CHANGE_CHARACTERISTIC(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				store.tabs[i].tabData.params.characteristics[data.index][data.param] =
					data.val;
			}
		}
	},
	ADD_REMOVE_PARAMETER(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				if (data.type === "add") {
					const newParam = {
						dir: "IN",
						id: Math.random().toString(36).slice(2, 7),
						name: "",
						type: { label: "INT", value: "int" },
					};
					store.tabs[i].tabData.params.parameters.push(newParam);
				} else {
					for (
						let j = 0;
						j < store.tabs[i].tabData.params.parameters.length;
						j++
					) {
						if (store.tabs[i].tabData.params.parameters[j].id === data.id) {
							store.tabs[i].tabData.params.parameters.splice(j, 1);
						}
					}
				}
			}
		}
	},
	ADD_REMOVE_CHARACTERISTIC(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				if (data.type === "add") {
					const newCharacteristic = {
						id: Math.random().toString(36).slice(2, 7),
						type: { label: "DETERMINISTIC", value: "DETERMINISTIC" },
					};
					store.tabs[i].tabData.params.characteristics.push(newCharacteristic);
				} else {
					for (
						let j = 0;
						j < store.tabs[i].tabData.params.characteristics.length;
						j++
					) {
						if (
							store.tabs[i].tabData.params.characteristics[j].id === data.id
						) {
							store.tabs[i].tabData.params.characteristics.splice(j, 1);
						}
					}
				}
			}
		}
	},
	// root database page:
	CHANGE_CREATE_TO_EDIT(store) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (
				store.tabs[i].id === store.activeTabId &&
				store.tabs[i].hasOwnProperty("create")
			) {
				delete store.tabs[i].create;
				store.tabs[i].oldTabData = JSON.parse(
					JSON.stringify(store.tabs[i].tabData),
				);
			}
		}
	},
	RESET_REVERT(store) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (store.tabs[i].id === store.activeTabId) {
				store.tabs[i].oldTabData = JSON.parse(
					JSON.stringify(store.tabs[i].tabData),
				);
			}
		}
	},
	REMOVE_TAB_IF_EXISTS(store, data) {
		for (let i = 0; i < store.tabs.length; i++) {
			if (
				store.tabs[i].name === data &&
				!store.tabs[i].hasOwnProperty("create")
			) {
				if (store.tabs[i].id === store.activeTabId) {
					const nextId = dh.findNextActivTab(store);
					store.activeTabId = nextId;
				}
				store.tabs.splice(i, 1);
			}
		}
	},
	RESET_DATABASE(store) {
		store.tables = [];
		store.tabs = [
			{
				type: "query",
				path: "Query 1",
				name: "Query 1",
				id: 1,
				tabData: {
					sql: "",
					offset: 0,
					tableData: [],
					err: null,
				},
			},
		];
		store.activeTabId = 1;
		store.autoIncrementId = 1;
		store.queryPagesCounter = 1;
	},
};

const state = {
	tables: [],
	views: [],
	procedures: [],
	functions: [],
	tabs: [
		{
			type: "query",
			path: "Query 1",
			name: "Query 1",
			id: 1,
			tabData: {
				sql: "",
				offset: 0,
				tableData: [],
				err: null,
			},
		},
	],
	activeTabId: 1,
	autoIncrementId: 1,
	queryPagesCounter: 1,
};
export default { namespaced: true, actions, mutations, state };
