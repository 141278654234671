<template>
	<div ref="dropdown" class="select-wrapper">
		<input
			v-if="searchable"
			type="text"
			:class="{ disabled: disabled }"
			:value="!searched ? (selected ? selected.label : '') : search"
			:placeholder="placeholder"
			@input="setSearch"
			@keyup.up="navigate($event, 'up')"
			@keyup.down="navigate($event, 'down')"
			@keyup.enter="selectValue"
			@focus="dropdownOpen = true"
			@blur="dropdownOpen = false"
		/>

		<span
			v-else-if="selected && selected.slot"
			class="selected focusable slot"
			tabindex="0"
			:class="{ 'hide-arrow': hideArrow }"
			@mousedown="slotClicked"
			@focus="dropdownOpen = true"
			@blur="dropdownOpen = false"
			@keyup.up="navigate($event, 'up')"
			@keyup.down="navigate($event, 'down')"
			@keyup.enter="selectValue"
			v-html="
				selected ? (selected.slot ? selected.slot : placeholder) : placeholder
			"
		>
		</span>

		<span
			v-else
			class="selected focusable"
			tabindex="0"
			:class="{ 'hide-arrow': hideArrow }"
			@focus="dropdownOpen = true"
			@blur="dropdownOpen = false"
			@keyup.up="navigate($event, 'up')"
			@keyup.down="navigate($event, 'down')"
			@keyup.enter="selectValue"
		>
			{{
				selected
					? selected.label == ""
						? placeholder
						: selected.label
					: placeholder
			}}
			<i
				v-if="selected && selected.label && selected.label != '' && removable"
				class="fas fa-times clear"
				@click="clear"
			></i>
		</span>

		<template v-if="!hideArrow">
			<i
				class="fa fa-chevron-down custom-caret"
				:class="{ rotate: dropdownOpen }"
				@click="toggleDropdown"
			></i>
		</template>
		<div
			v-show="dropdownOpen"
			ref="dropdownList"
			:class="{ slot: selected && selected.slot, dropdown: true }"
		>
			<span
				v-for="(option, index) in filteredOption"
				v-show="options && options.length > 0"
				:id="'optionIndex' + index"
				:key="index"
				@mousedown="test(option)"
				@mouseover="addActiveClass"
				@mouseleave="removeActiveClass"
				>{{ option.slot ? "" : option.label }}
				<div v-if="option.slot" class="slot-wrapper">
					<div class="slot" v-html="option.slot"></div>
					<div v-if="option.label" class="span-label">
						{{ option.label }}
					</div>
				</div>
			</span>
			<span
				v-show="!options || options.length === 0 || filteredOption.length === 0"
				class="no-data"
				>No data...</span
			>
		</div>
	</div>
</template>

<!-- HOW TO USE:
- props: 'options' => array of objects like {label: test, value: test}
-        'selected': object => same as object from array {label: test, value: test}
-        'searchable': boolean
-        'disabled': boolean
-        'placeholder': string
-        'removable': boolean -> adds x to delete selected value
- emits 'change' => returns string or object from options
- emits 'input' => returns string from input field on input
- emits 'slotClicked' => when selected slot is clicked
 -->

<script>
export default {
	name: "SelectComp",
	props: {
		options: {
			type: Array,
			required: false,
			default: () => [],
		},
		selected: {
			type: Object,
			required: false,
			default: () => ({}),
		},
		searchable: {
			type: Boolean,
			required: false,
			default: true,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		placeholder: {
			type: String,
			required: false,
			default: "select...",
		},
		hideArrow: {
			type: Boolean,
			required: false,
			default: false,
		},
		removable: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	emits: ["change", "input", "slotClicked"],
	data() {
		return {
			dropdownOpen: false,
			search: "",
			searched: false,
			interval: null,
		};
	},
	computed: {
		filteredOption() {
			if (this.searched) {
				if (!this.options) {
					return [];
				}
				const filteredOptions = this.options.filter((option) => {
					return option.label
						?.toLowerCase()
						.includes(this.search?.toLowerCase());
				});
				return filteredOptions;
			} else {
				return this.options;
			}
		},
	},
	watch: {
		dropdownOpen() {
			if (this.dropdownOpen) {
				this.findActiveAndScroll();
				this.changePosition();
				const top = Math.floor(this.$refs.dropdown.getBoundingClientRect().top);
				this.interval = setInterval(() => {
					if (
						top !== Math.floor(this.$refs.dropdown.getBoundingClientRect().top)
					) {
						this.dropdownOpen = false;
					}
				}, 100);
			} else {
				clearInterval(this.interval);
			}
		},
		// '$refs.dropdownList.display': () => {
		//   console.log(this.$refs.dropdown.getBoundingClientRect().top);
		// }
	},
	created() {
		document.addEventListener("click", this.documentClick);
	},
	unmounted() {
		clearInterval(this.interval);
		document.removeEventListener("click", this.documentClick);
	},
	methods: {
		clear() {
			const res = {
				value: "",
				label: "",
			};
			this.$emit("change", res);
			this.dropdownOpen = false;
			this.searched = false;
		},
		toggleDropdown() {
			if (!this.disabled) {
				this.dropdownOpen = !this.dropdownOpen;
			}
		},
		test(opt) {
			this.$emit("change", opt);
			this.dropdownOpen = false;
			this.searched = false;
		},
		setSearch(e) {
			this.dropdownOpen = true;
			if (e.keyCode !== 40 && e.keyCode !== 38 && e.keyCode !== 13) {
				if (!this.searched) {
					this.searched = true;
				}
				this.search = e.target.value;
				this.findActiveAndScroll();
				this.$emit("input", e.target.value);
			}
		},
		slotClicked() {
			if (this.dropdownOpen === true) {
				this.$emit("slotClicked");
			}
		},
		addActiveClass(e) {
			const elems = document.querySelectorAll(".dropdown span");
			for (const elem of elems) {
				if (elem.classList.contains("active")) {
					elem.classList.toggle("active");
				}
			}
			if (!e.target.classList.contains("active")) {
				e.target.classList.toggle("active");
			}
		},
		removeActiveClass(e) {
			if (e.target.classList.contains("active")) {
				e.target.classList.toggle("active");
			}
		},
		navigate(e, direction) {
			const parent = this.$refs.dropdown;
			let activEl = parent.querySelector(".dropdown .active");
			// const selectedEl = parent.querySelector('.dropdown .set')
			if (activEl) {
				if (direction === "up") {
					if (activEl.previousSibling) {
						activEl.classList.toggle("active");
						activEl.previousSibling.classList.toggle("active");
					} else {
						activEl.classList.toggle("active");
						const el = parent.querySelector(
							".dropdown span:last-child",
						).previousSibling;
						el.classList.toggle("active");
					}
				} else {
					if (
						activEl.nextSibling &&
						!activEl.nextSibling.classList.contains("no-data")
					) {
						activEl.classList.toggle("active");
						activEl.nextSibling.classList.toggle("active");
					} else if (activEl.nextSibling.classList.contains("no-data")) {
						activEl.classList.toggle("active");
						const el = parent.querySelector(".dropdown span:first-child");
						el.classList.toggle("active");
					}
				}
			} else {
				const el = parent.querySelector(".dropdown span:first-child");
				el.classList.toggle("active");
			}
			activEl = parent.querySelector(".dropdown .active");
			activEl.scrollIntoView({ behavior: "smooth", block: "center" });
			// FIX SCROLL
			// document.querySelector('.select-wrapper .dropdown').scrollTop -= 15
		},
		findActiveAndScroll() {
			setTimeout(() => {
				const parent = this.$refs.dropdown;
				const array = this.filteredOption;
				let counter = 0;

				for (const [i, element] of array.entries()) {
					const cssSelector = ".dropdown span:nth-child(" + (i + 1) + ")";
					const el = parent.querySelector(cssSelector);
					// console.log(array[i].value, this.selected.value);
					if (
						element.value &&
						this.selected.value &&
						element.value === this.selected.value &&
						counter === 0
					) {
						if (!el.classList.contains("set")) {
							el.classList.add("set");
						}
						// el.classList.toggle('active')
						el.scrollIntoView({ behavior: "smooth", block: "center" });
						counter = counter + 1;
						// FIX SCROLL
						// parent.querySelector('.dropdown').scrollTop -= 15
					} else {
						if (el.classList.contains("set")) {
							el.classList.remove("set");
						}
					}
					if (counter !== 0) {
						return;
					}
					if (
						element.name &&
						this.selected.name &&
						element.name === this.selected.name &&
						counter === 0
					) {
						if (!el.classList.contains("set")) {
							el.classList.add("set");
						}
						// el.classList.toggle('active')
						el.scrollIntoView({ behavior: "smooth", block: "center" });
						counter = counter + 1;
						// FIX SCROLL
						// parent.querySelector('.dropdown').scrollTop -= 15
					} else {
						if (el.classList.contains("set")) {
							el.classList.remove("set");
						}
					}
				}
			}, 10);
		},
		selectValue(e) {
			const activEl = document.querySelector(
				".select-wrapper .dropdown .active",
			);
			const index = activEl.id.slice(11);
			console.log(activEl, index);
			const activeOption = this.filteredOption[index];
			if (activEl) {
				this.searched = false;
				this.search = activEl.innerText;
				// let res = {}
				// for (var i = 0; i < this.options.length; i++) {
				//   if ( this.options[i].label === activEl.innerText ) {
				//     res = this.options[i]
				//   }
				// }
				this.$emit("change", activeOption);
				e.target.blur();
				// this.dropdownOpen = false;
			}
		},
		documentClick(e) {
			const el = this.$refs.dropdown;
			const target = e.target;
			if (el !== target && !el.contains(target)) {
				if (this.searched) {
					// this.$emit('change', this.search)
				}
				this.dropdownOpen = false;
				this.searched = false;
			}
		},
		changePosition() {
			const el = this.$refs.dropdown;
			const viewportOffset = el.getBoundingClientRect();
			const top = viewportOffset.top + el.offsetHeight;
			const left = viewportOffset.left;
			const dropdown = this.$refs.dropdownList;
			dropdown.style.position = "fixed";
			dropdown.style.top = top + "px";
			dropdown.style.left = left + "px";
			dropdown.style.zIndex = "200";
		},
	},
};
</script>

<style lang="scss" scoped>
tbody .selected {
	border-bottom: none !important;
}

.clear {
	margin-right: 15px;
	font-size: 12px;
	margin-top: 6px;
}

.disabled {
	pointer-events: none;
}

.select-wrapper {
	position: relative;
	width: 100%;

	input {
		width: calc(100%);
		padding-right: 20px;
		text-overflow: ellipsis;
	}

	.selected {
		width: 100%;
		cursor: pointer;
		display: block;
		min-height: 30px;
		line-height: 29px;
		min-width: 150px;
		border-bottom: 1px solid $light-border;
		padding-right: 30px;
		padding-left: 2px;

		&.hide-arrow {
			padding-right: 0;
		}
	}

	.selected.slot {
		margin: 0;
		width: 100%;
		padding-right: 20px;
		cursor: pointer;
		display: block;
		min-height: 30px;
		line-height: 29px;
	}

	i {
		position: absolute;
		top: 8px;
		right: 5px;
		transition: transform 0.2s ease;
	}

	i.rotate {
		transform: rotate(180deg);
	}

	.dropdown {
		position: absolute;
		top: 100%;
		// min-width: 100%;
		// border-radius: 7px;
		left: 0;
		padding: 15px 0;
		background-color: $alt-background;
		color: white;
		z-index: 10;
		display: flex;
		flex-direction: column;
		box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.81);
		max-height: 300px;
		overflow: auto;

		span {
			cursor: pointer;
			color: $dark-silver;
			// margin-bottom: 5px;
			padding: 0 10px;
			user-select: none;
		}

		span.set {
			background-color: lighten($color: $darker-gray, $amount: 5);
			// filter: brightness(1.2);
		}

		span.active {
			background-color: lighten($color: $darker-gray, $amount: 20);
			// filter: brightness(1);
		}
	}
}

.slot-wrapper {
	pointer-events: none;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	// justify-content: center;
	align-items: center;
}

.span-label {
	margin-left: 10px;
}
</style>
