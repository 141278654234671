import tutorialThumb from "@/assets/image/profile/tutorial/apiTutorial.png";

export default {
	config: {
		dependsOn: [
			{
				group: "project",
				subGroup: "first-api",
			},
		],
		tutorialThumb,
	},
	steps: [
		{
			stepName: "addNewFileNavigation",
			target: "#app .app-menu-bar-list",
			header: {
				title: "Hello World - Intro",
			},
			content: 'Click on the "File" in the navigation bar',
			params: {
				enableScrolling: false,
			},
			buttons: {
				previous: false,
				next: false,
			},
		},
		{
			stepName: "addNewFile",
			target:
				"#app .app-menu-bar-list > div:nth-child(1) > div.drop-menu-first-level > div:nth-child(1) > .drop-menu-item",
			header: {
				title: "Hello World - Intro",
			},
			content: 'Click on the "Add new File" in the menu bar',
			params: {
				enableScrolling: false,
				placement: "right",
			},
			buttons: {
				previous: false,
				next: false,
			},
		},
		{
			stepName: "modalCreateFile",
			target: "#app .manage-file input",
			header: {
				title: "Hello World - Intro",
			},
			content: 'Type "Hello World" and click "Create"',
			params: {
				enableScrolling: false,
				placement: "top",
				modifiers: [
					{
						name: "offset",
						options: {
							offset: ({ placement, reference, popper }) => {
								return [0, 0];
							},
						},
					},
				],
			},
			buttons: {
				previous: false,
				// next: false,
			},
		},
		{
			stepName: "modalCreateFileConfirm",
			target: "#app .manage-file .btn-ok",
			header: {
				title: "Hello World - Intro",
			},
			content: 'Click "Create"',
			params: {
				enableScrolling: false,
				placement: "top",
				modifiers: [
					{
						name: "offset",
						options: {
							offset: ({ placement, reference, popper }) => {
								return [0, 0];
							},
						},
					},
				],
			},
			buttons: {
				// previous: false,
				next: false,
			},
		},
		{
			stepName: "editorFirstSaveApi",
			target: ".editor-view .ed-view",
			header: {
				title: "Hello World - Intro",
			},
			content:
				"Type \"write('Hello', 'World')\" in the editor, save the file and right-click Run button",
			params: {
				enableScrolling: false,
				placement: "top",
				modifiers: [
					{
						name: "offset",
						options: {
							offset: ({ placement, reference, popper }) => {
								return [0, -300];
							},
						},
					},
				],
			},
			buttons: {
				previous: false,
				next: false,
			},
		},
	],
};
