import AlterTableCommand from "./initial-commands/AlterTableCommand";
import CreateForeignKeyCommand from "./initial-commands/CreateForeignKeyCommand";
import CreateIndexCommand from "./initial-commands/CreateIndexCommand";
import CreateTableCommand from "./initial-commands/CreateTableCommand";
import CreateTriggerCommand from "./initial-commands/CreateTriggerCommand";
import DropForeignKeyCommand from "./initial-commands/DropForeignKeyCommand";
import DropIndexCommand from "./initial-commands/DropIndexCommand";
import DropTableCommand from "./initial-commands/DropTableCommand";
import DropTriggerCommand from "./initial-commands/DropTriggerCommand";
import RenameTableCommand from "./initial-commands/RenameTableCommand";

class MariaDBCommandsFactory {
	AlterTableCommand(parameters) {
		return new AlterTableCommand(parameters);
	}

	CreateForeignKeyCommand(foreignKey, table) {
		return new CreateForeignKeyCommand(foreignKey, table);
	}

	CreateIndexCommand(table, index) {
		return new CreateIndexCommand(table, index);
	}

	CreateTableCommand(table) {
		return new CreateTableCommand(table);
	}

	CreateTriggerCommand(trigger, table) {
		return new CreateTriggerCommand(trigger, table);
	}

	DropForeignKeyCommand(foreignKey, table) {
		return new DropForeignKeyCommand(foreignKey, table);
	}

	DropIndexCommand(table, index) {
		return new DropIndexCommand(table, index);
	}

	DropTableCommand(table) {
		return new DropTableCommand(table);
	}

	DropTriggerCommand(trigger) {
		return new DropTriggerCommand(trigger);
	}

	RenameTableCommand(oldTable, newTable) {
		return new RenameTableCommand(oldTable, newTable);
	}
}

export default MariaDBCommandsFactory;
