<template>
	<div class="modal-wrapper">
		<div class="section">
			<span class="title">Default/Expression:</span>
			<input
				type="text"
				:value="options.row.default"
				@input="emitInput($event, 'default')"
			/>
		</div>
		<div class="section">
			<span class="title">Collation:</span>
			<select-view
				:selected="{
					label: options.row.collation.name,
					value: options.row.collation.name,
				}"
				:options="getCollation"
				:searchable="false"
				:removable="true"
				@change="emitSelect($event, 'collation')"
			/>
		</div>
		<div class="section">
			<span class="title">Comment:</span>
			<textarea
				class="textarea"
				type="text"
				:value="options.row.comment"
				@input="emitInput($event, 'comment')"
			/>
		</div>
		<div class="section actions">
			<button class="btn close inv" @click="closeModernModalCompat()">
				close
			</button>
			<!-- <button @click="save()" class="btn create">
        save
      </button> -->
		</div>
	</div>
</template>

<script>
import eventBus from "@/mixins/eventBus.js";
import api from "@/api";
export default {
	name: "ColumnSettingsModal",
	props: ["options"],
	data() {
		return {
			collation: [],
		};
	},
	computed: {
		getCollation() {
			const arr = [];
			for (let i = 0; i < this.collation.length; i++) {
				const obj = {
					value: this.collation[i].characterSet,
					label: this.collation[i].characterSet,
				};
				arr.push(obj);
			}
			return arr;
		},
	},
	mounted() {
		// console.log(this.options);
		api.getCollation().then((res) => {
			this.collation = res.data.collation;
		});
	},
	methods: {
		save() {
			// console.log(this.options);
		},
		emitInput(e, key) {
			const params = {
				value: e.target.value,
				key: key,
				index: this.options.index,
			};
			eventBus.$emit("setColumnDefault", params);
		},
		emitSelect(e, key) {
			const params = {
				value: e.value,
				key: key,
				index: this.options.index,
			};
			// this.$emit('updateColumnInput', params)
			eventBus.$emit("setColumnCollation", params);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/adminModals";

.modal-wrapper {
	@include admin-modals-styles;
}

.textarea {
	height: 80px;
	resize: vertical;
}
</style>
