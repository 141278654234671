import api from "@/api";

const actions = {
	getSiteErrors(store, data) {
		return api.getSiteErrors(data);
	},
	getErrors(store, data) {
		return api.getErrors(data);
	},
	setSiteErrors({ commit }, payload) {
		commit("SET_SITE_ERRORS", payload);
	},
	setErrors({ commit }, payload) {
		for (const element of payload) {
			let msg = null;
			try {
				msg = JSON.parse(element.err_message);
				element.dropdown = msg;
			} catch {
				msg = null;
			}
			if (msg == null) {
				element.Link = false;
			} else {
				element.err_message = msg.message;
				element.Link =
					msg["file id"] && msg["file path"] && msg["line number"]
						? true
						: false;
			}

			element.err_date = element.err_time.split(" ")[0];
			element.err_time = element.err_time.split(" ")[1];
		}
		commit("SET_ERRORS", payload);
	},
	addErrors({ commit }, payload) {
		for (const element of payload) {
			let msg = null;
			try {
				msg = JSON.parse(element.err_message);
				element.dropdown = msg;
			} catch {
				msg = null;
			}
			if (msg == null) {
				element.Link = false;
			} else {
				element.err_message = msg.message;
				element.Link =
					msg["file id"] && msg["file path"] && msg["line number"]
						? true
						: false;
			}

			element.err_date = element.err_time.split(" ")[0];
			element.err_time = element.err_time.split(" ")[1];
		}
		commit("ADD_ERRORS", payload);
	},
};

const mutations = {
	SET_SITE_ERRORS(store, data) {
		store.errors = data;
	},
	SET_ERRORS(store, data) {
		store.tableContent = data;
	},
	ADD_ERRORS(store, data) {
		Array.prototype.push.apply(store.tableContent, data);
	},
	SET_ERROR(store, data) {
		store.selectedError = data;
	},
};

const state = {
	errors: [],
	searchStr: "",
	tableContent: null,
	selectedError: "All errors",
};
export default { namespaced: true, actions, mutations, state };
