import tutorialThumb from "@/assets/image/profile/tutorial/editorTutorial.png";

export const settingsTransfer = {
	config: {
		tutorialThumb,
	},
	steps: [
		{
			target: "[data-name='all-proj-checkbox']",
			header: {
				title: "Settings transfer",
			},
			content:
				"If you want to see all available sites, check this checkbox to display them.",
			params: {
				enableScrolling: true,
				placement: "auto",
				highlight: true,
			},
		},
	],
};
